/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: { input: any; output: any };
};

export type AzureLoginMutation = {
  __typename?: "AzureLoginMutation";
  accessToken?: Maybe<Scalars["String"]["output"]>;
};

export type Call = {
  __typename?: "Call";
  agentInformed?: Maybe<Scalars["Boolean"]["output"]>;
  callId?: Maybe<Scalars["String"]["output"]>;
  callStartDate?: Maybe<Scalars["DateTime"]["output"]>;
  callerPhoneNumber?: Maybe<Scalars["String"]["output"]>;
  callerPin?: Maybe<Scalars["String"]["output"]>;
  conversation?: Maybe<Conversation>;
  customerName?: Maybe<Scalars["String"]["output"]>;
  dnis?: Maybe<Scalars["String"]["output"]>;
  gate?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  recognized?: Maybe<Scalars["Boolean"]["output"]>;
  servicePhoneNumber?: Maybe<Scalars["String"]["output"]>;
  transcripts?: Maybe<Array<Maybe<Transcript>>>;
  user?: Maybe<User>;
};

export type Category = {
  __typename?: "Category";
  enabled?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  serviceDesk?: Maybe<Scalars["String"]["output"]>;
  template?: Maybe<Scalars["String"]["output"]>;
  templateSysId?: Maybe<Scalars["String"]["output"]>;
};

export type Client = {
  __typename?: "Client";
  clientSettings?: Maybe<Array<Maybe<ClientSetting>>>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type ClientSetting = {
  __typename?: "ClientSetting";
  clientId?: Maybe<Client>;
  id?: Maybe<Scalars["String"]["output"]>;
  key?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type Conversation = {
  __typename?: "Conversation";
  call?: Maybe<Call>;
  client?: Maybe<Client>;
  conversationMemory?: Maybe<Scalars["String"]["output"]>;
  evaluation?: Maybe<Scalars["Int"]["output"]>;
  exchanges?: Maybe<Array<Maybe<Exchange>>>;
  feDeleted?: Maybe<Scalars["Boolean"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  messages?: Maybe<Array<Maybe<Message>>>;
  name?: Maybe<Scalars["String"]["output"]>;
  started?: Maybe<Scalars["DateTime"]["output"]>;
  user?: Maybe<User>;
};

export type ConversationUserArgs = {
  client?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Scalars["String"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateConversation = {
  __typename?: "CreateConversation";
  conversation?: Maybe<Conversation>;
};

export type CreateKbArticle = {
  __typename?: "CreateKBArticle";
  kbarticle?: Maybe<KbArticle>;
};

export type CreateKbArticleInstance = {
  __typename?: "CreateKBArticleInstance";
  kbarticleinstance?: Maybe<KbArticleInstance>;
};

export type Exchange = {
  __typename?: "Exchange";
  conversation?: Maybe<Conversation>;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  evaluation?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  messages?: Maybe<Array<Maybe<Message>>>;
  user?: Maybe<User>;
};

export type KbArticle = {
  __typename?: "KBArticle";
  client?: Maybe<Client>;
  forDeletion?: Maybe<Scalars["Boolean"]["output"]>;
  forReview?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  instances?: Maybe<Array<Maybe<KbArticleInstance>>>;
  number?: Maybe<Scalars["String"]["output"]>;
};

export type KbArticleInstance = {
  __typename?: "KBArticleInstance";
  id?: Maybe<Scalars["String"]["output"]>;
  invalidSource?: Maybe<Scalars["Boolean"]["output"]>;
  kbarticle?: Maybe<KbArticle>;
  manuallyAdded?: Maybe<Scalars["Boolean"]["output"]>;
  message?: Maybe<Message>;
  source?: Maybe<Scalars["Boolean"]["output"]>;
  validSource?: Maybe<Scalars["Boolean"]["output"]>;
};

export type LoginMutation = {
  __typename?: "LoginMutation";
  accessToken?: Maybe<Scalars["String"]["output"]>;
  refreshToken?: Maybe<Scalars["String"]["output"]>;
};

export type Message = {
  __typename?: "Message";
  botMessage?: Maybe<Scalars["Boolean"]["output"]>;
  conversation?: Maybe<Conversation>;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  exchange?: Maybe<Exchange>;
  id?: Maybe<Scalars["String"]["output"]>;
  kbArticleInstances?: Maybe<Array<Maybe<KbArticleInstance>>>;
  received?: Maybe<Scalars["Boolean"]["output"]>;
  ticket?: Maybe<ServiceNowTicket>;
  type: MessageType;
  user?: Maybe<User>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type MessageConversationArgs = {
  call?: InputMaybe<Scalars["Boolean"]["input"]>;
  client?: InputMaybe<Scalars["Boolean"]["input"]>;
  conversationMemory?: InputMaybe<Scalars["String"]["input"]>;
  evaluation?: InputMaybe<Scalars["Int"]["input"]>;
  exchanges?: InputMaybe<Scalars["Boolean"]["input"]>;
  feDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  messages?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Scalars["String"]["input"]>;
  started?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MessageUserArgs = {
  client?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Scalars["String"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export enum MessageType {
  Action = "ACTION",
  ActionInput = "ACTION_INPUT",
  AdditionalAssistance = "ADDITIONAL_ASSISTANCE",
  AiAssist = "AI_ASSIST",
  CardAssist = "CARD_ASSIST",
  CardContact = "CARD_CONTACT",
  CreateTicket = "CREATE_TICKET",
  Debug = "DEBUG",
  End = "END",
  Error = "ERROR",
  Finish = "FINISH",
  IntentData = "INTENT_DATA",
  Message = "MESSAGE",
  Observation = "OBSERVATION",
  Ping = "PING",
  Pong = "PONG",
  Receipt = "RECEIPT",
  Response = "RESPONSE",
  SearchResult = "SEARCH_RESULT",
  Start = "START",
  Thought = "THOUGHT",
  UpdateTicket = "UPDATE_TICKET",
  Validation = "VALIDATION",
}

export type Mutation = {
  __typename?: "Mutation";
  aiAssist?: Maybe<Transcript>;
  aiReply?: Maybe<Message>;
  azureLogin?: Maybe<AzureLoginMutation>;
  createAiWorkNoteText?: Maybe<Scalars["String"]["output"]>;
  createConversation?: Maybe<CreateConversation>;
  createKbArticle?: Maybe<CreateKbArticle>;
  createKbArticleInstance?: Maybe<CreateKbArticleInstance>;
  createTicket?: Maybe<Message>;
  login?: Maybe<LoginMutation>;
  refreshToken?: Maybe<RefreshTokenMutation>;
  updateConversation?: Maybe<UpdateConversation>;
  updateExchange?: Maybe<UpdateExchange>;
  updateKbArticle?: Maybe<UpdateKbArticle>;
  updateKbArticleInstance?: Maybe<UpdateKbArticleInstance>;
  updateTicket?: Maybe<Message>;
};

export type MutationAiAssistArgs = {
  conversationId: Scalars["String"]["input"];
  templatesLimit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationAiReplyArgs = {
  conversationId: Scalars["String"]["input"];
  message: Scalars["String"]["input"];
};

export type MutationAzureLoginArgs = {
  credential?: InputMaybe<Scalars["String"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateAiWorkNoteTextArgs = {
  transcriptId: Scalars["String"]["input"];
};

export type MutationCreateConversationArgs = {
  client?: InputMaybe<Scalars["String"]["input"]>;
  user?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateKbArticleArgs = {
  client?: InputMaybe<Scalars["String"]["input"]>;
  number?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateKbArticleInstanceArgs = {
  kbarticle?: InputMaybe<Scalars["String"]["input"]>;
  manuallyAdded?: InputMaybe<Scalars["Boolean"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  source?: InputMaybe<Scalars["Boolean"]["input"]>;
  validSource?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateTicketArgs = {
  callerId: Scalars["String"]["input"];
  conversationId: Scalars["String"]["input"];
  template: Scalars["String"]["input"];
  transcriptId: Scalars["String"]["input"];
  workNote: Scalars["String"]["input"];
};

export type MutationLoginArgs = {
  password?: InputMaybe<Scalars["String"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationRefreshTokenArgs = {
  refreshToken?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateConversationArgs = {
  evaluation?: InputMaybe<Scalars["Int"]["input"]>;
  feDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateExchangeArgs = {
  evaluation?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["String"]["input"];
};

export type MutationUpdateKbArticleArgs = {
  forDeletion?: InputMaybe<Scalars["Boolean"]["input"]>;
  forReview?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["String"]["input"];
};

export type MutationUpdateKbArticleInstanceArgs = {
  id: Scalars["String"]["input"];
  invalidSource?: InputMaybe<Scalars["Boolean"]["input"]>;
  validSource?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationUpdateTicketArgs = {
  conversationId: Scalars["String"]["input"];
  number: Scalars["String"]["input"];
  sysId: Scalars["String"]["input"];
  workNotes: Scalars["String"]["input"];
};

export type NewCall = {
  __typename?: "NewCall";
  callId?: Maybe<Scalars["String"]["output"]>;
  callStartDate?: Maybe<Scalars["String"]["output"]>;
  callerPhoneNumber?: Maybe<Scalars["String"]["output"]>;
  callerPin?: Maybe<Scalars["String"]["output"]>;
  conversation?: Maybe<Scalars["String"]["output"]>;
  customerName?: Maybe<Scalars["String"]["output"]>;
  eventType?: Maybe<Scalars["String"]["output"]>;
  gate?: Maybe<Scalars["String"]["output"]>;
  servicePhoneNumber?: Maybe<Scalars["String"]["output"]>;
  user?: Maybe<Scalars["String"]["output"]>;
};

export type Query = {
  __typename?: "Query";
  categories?: Maybe<Array<Maybe<Category>>>;
  category?: Maybe<Category>;
  conversation?: Maybe<Conversation>;
  conversations?: Maybe<Array<Maybe<Conversation>>>;
  identifyCaller?: Maybe<ServiceNowUser>;
  latestTickets?: Maybe<Array<Maybe<ServiceNowTicket>>>;
  message?: Maybe<Message>;
  messages?: Maybe<Array<Maybe<Message>>>;
  transcript?: Maybe<Transcript>;
  user?: Maybe<User>;
};

export type QueryCategoriesArgs = {
  client: Scalars["String"]["input"];
};

export type QueryCategoryArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryConversationArgs = {
  id: Scalars["String"]["input"];
};

export type QueryConversationsArgs = {
  call?: InputMaybe<Scalars["Boolean"]["input"]>;
  call_AgentInformed?: InputMaybe<Scalars["Boolean"]["input"]>;
  call_CallId?: InputMaybe<Scalars["String"]["input"]>;
  call_CallStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  call_CallerPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  call_CallerPin?: InputMaybe<Scalars["String"]["input"]>;
  call_Conversation?: InputMaybe<Scalars["Boolean"]["input"]>;
  call_CustomerName?: InputMaybe<Scalars["String"]["input"]>;
  call_Dnis?: InputMaybe<Scalars["String"]["input"]>;
  call_Gate?: InputMaybe<Scalars["String"]["input"]>;
  call_Id?: InputMaybe<Scalars["String"]["input"]>;
  call_Recognized?: InputMaybe<Scalars["Boolean"]["input"]>;
  call_ServicePhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  call_Transcripts?: InputMaybe<Scalars["Boolean"]["input"]>;
  call_User?: InputMaybe<Scalars["Boolean"]["input"]>;
  client?: InputMaybe<Scalars["Boolean"]["input"]>;
  client_ClientSettings?: InputMaybe<Scalars["Boolean"]["input"]>;
  client_Id?: InputMaybe<Scalars["String"]["input"]>;
  client_Name?: InputMaybe<Scalars["String"]["input"]>;
  conversationMemory?: InputMaybe<Scalars["String"]["input"]>;
  evaluation?: InputMaybe<Scalars["Int"]["input"]>;
  exchanges?: InputMaybe<Scalars["Boolean"]["input"]>;
  feDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  messages?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Scalars["String"]["input"]>;
  started?: InputMaybe<Scalars["DateTime"]["input"]>;
  user?: InputMaybe<Scalars["Boolean"]["input"]>;
  userId: Scalars["String"]["input"];
  user_Client?: InputMaybe<Scalars["Boolean"]["input"]>;
  user_Id?: InputMaybe<Scalars["String"]["input"]>;
  user_Username?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryIdentifyCallerArgs = {
  callId: Scalars["String"]["input"];
};

export type QueryLatestTicketsArgs = {
  callId: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
};

export type QueryMessageArgs = {
  id: Scalars["String"]["input"];
};

export type QueryMessagesArgs = {
  botMessage?: InputMaybe<Scalars["Boolean"]["input"]>;
  conversation?: InputMaybe<Scalars["Boolean"]["input"]>;
  conversationId: Scalars["String"]["input"];
  conversationUserId: Scalars["String"]["input"];
  conversation_Call?: InputMaybe<Scalars["Boolean"]["input"]>;
  conversation_Call_AgentInformed?: InputMaybe<Scalars["Boolean"]["input"]>;
  conversation_Call_CallId?: InputMaybe<Scalars["String"]["input"]>;
  conversation_Call_CallStartDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  conversation_Call_CallerPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  conversation_Call_CallerPin?: InputMaybe<Scalars["String"]["input"]>;
  conversation_Call_Conversation?: InputMaybe<Scalars["Boolean"]["input"]>;
  conversation_Call_CustomerName?: InputMaybe<Scalars["String"]["input"]>;
  conversation_Call_Dnis?: InputMaybe<Scalars["String"]["input"]>;
  conversation_Call_Gate?: InputMaybe<Scalars["String"]["input"]>;
  conversation_Call_Id?: InputMaybe<Scalars["String"]["input"]>;
  conversation_Call_Recognized?: InputMaybe<Scalars["Boolean"]["input"]>;
  conversation_Call_ServicePhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  conversation_Call_Transcripts?: InputMaybe<Scalars["Boolean"]["input"]>;
  conversation_Call_User?: InputMaybe<Scalars["Boolean"]["input"]>;
  conversation_Client?: InputMaybe<Scalars["Boolean"]["input"]>;
  conversation_Client_ClientSettings?: InputMaybe<Scalars["Boolean"]["input"]>;
  conversation_Client_Id?: InputMaybe<Scalars["String"]["input"]>;
  conversation_Client_Name?: InputMaybe<Scalars["String"]["input"]>;
  conversation_ConversationMemory?: InputMaybe<Scalars["String"]["input"]>;
  conversation_Evaluation?: InputMaybe<Scalars["Int"]["input"]>;
  conversation_Exchanges?: InputMaybe<Scalars["Boolean"]["input"]>;
  conversation_FeDeleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  conversation_Id?: InputMaybe<Scalars["String"]["input"]>;
  conversation_Messages?: InputMaybe<Scalars["Boolean"]["input"]>;
  conversation_Name?: InputMaybe<Scalars["String"]["input"]>;
  conversation_Started?: InputMaybe<Scalars["DateTime"]["input"]>;
  conversation_User?: InputMaybe<Scalars["Boolean"]["input"]>;
  conversation_User_Client?: InputMaybe<Scalars["Boolean"]["input"]>;
  conversation_User_Id?: InputMaybe<Scalars["String"]["input"]>;
  conversation_User_Username?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  exchange?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  kbArticleInstances?: InputMaybe<Scalars["Boolean"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  received?: InputMaybe<Scalars["Boolean"]["input"]>;
  sort?: InputMaybe<Scalars["String"]["input"]>;
  ticket?: InputMaybe<Scalars["Boolean"]["input"]>;
  type?: InputMaybe<Scalars["Boolean"]["input"]>;
  user?: InputMaybe<Scalars["Boolean"]["input"]>;
  user_Client?: InputMaybe<Scalars["Boolean"]["input"]>;
  user_Client_ClientSettings?: InputMaybe<Scalars["Boolean"]["input"]>;
  user_Client_Id?: InputMaybe<Scalars["String"]["input"]>;
  user_Client_Name?: InputMaybe<Scalars["String"]["input"]>;
  user_Id?: InputMaybe<Scalars["String"]["input"]>;
  user_Username?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryTranscriptArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryUserArgs = {
  id: Scalars["String"]["input"];
};

export type RefreshTokenMutation = {
  __typename?: "RefreshTokenMutation";
  accessToken?: Maybe<Scalars["String"]["output"]>;
  refreshToken?: Maybe<Scalars["String"]["output"]>;
};

export type ServiceNowAttribute = {
  __typename?: "ServiceNowAttribute";
  key: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type ServiceNowTicket = {
  __typename?: "ServiceNowTicket";
  assignmentGroup?: Maybe<Scalars["String"]["output"]>;
  commentsAndWorkNotes?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  number?: Maybe<Scalars["String"]["output"]>;
  openTicketUrl?: Maybe<Scalars["String"]["output"]>;
  shortDescription?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  sysClassName?: Maybe<Scalars["String"]["output"]>;
  sysCreatedOn?: Maybe<Scalars["String"]["output"]>;
  sysId?: Maybe<Scalars["String"]["output"]>;
  sysUpdatedOn?: Maybe<Scalars["String"]["output"]>;
};

export type ServiceNowUser = {
  __typename?: "ServiceNowUser";
  attributes?: Maybe<Array<Maybe<ServiceNowAttribute>>>;
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  sysId?: Maybe<Scalars["String"]["output"]>;
  userName?: Maybe<Scalars["String"]["output"]>;
};

export type Subscription = {
  __typename?: "Subscription";
  newCall?: Maybe<NewCall>;
  newMessage?: Maybe<Message>;
  newToken?: Maybe<Token>;
  timeOfDay?: Maybe<Scalars["String"]["output"]>;
};

export type SubscriptionNewMessageArgs = {
  conversationId: Scalars["String"]["input"];
};

export type SubscriptionNewTokenArgs = {
  conversationId: Scalars["String"]["input"];
};

export type Token = {
  __typename?: "Token";
  conversationId?: Maybe<Scalars["String"]["output"]>;
  token?: Maybe<Scalars["String"]["output"]>;
};

export type Transcript = {
  __typename?: "Transcript";
  certainty?: Maybe<Scalars["Float"]["output"]>;
  conversationPrompt?: Maybe<Scalars["String"]["output"]>;
  conversationTranscript?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  earlyIntent?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  intent?: Maybe<Scalars["String"]["output"]>;
  intentok?: Maybe<Scalars["Boolean"]["output"]>;
  predictedTemplates?: Maybe<Scalars["String"]["output"]>;
  predictionValues?: Maybe<Scalars["String"]["output"]>;
  resolved?: Maybe<Scalars["String"]["output"]>;
  summary?: Maybe<Scalars["String"]["output"]>;
  summaryok?: Maybe<Scalars["Boolean"]["output"]>;
  template?: Maybe<Category>;
  templateok?: Maybe<Scalars["Boolean"]["output"]>;
  ticketid?: Maybe<Scalars["String"]["output"]>;
  user?: Maybe<User>;
};

export type UpdateConversation = {
  __typename?: "UpdateConversation";
  conversation?: Maybe<Conversation>;
};

export type UpdateExchange = {
  __typename?: "UpdateExchange";
  exchange?: Maybe<Exchange>;
};

export type UpdateKbArticle = {
  __typename?: "UpdateKBArticle";
  kbarticle?: Maybe<KbArticle>;
};

export type UpdateKbArticleInstance = {
  __typename?: "UpdateKBArticleInstance";
  kbarticleinstance?: Maybe<KbArticleInstance>;
};

export type User = {
  __typename?: "User";
  client?: Maybe<Client>;
  id?: Maybe<Scalars["String"]["output"]>;
  username?: Maybe<Scalars["String"]["output"]>;
  conversations?: Maybe<Array<Maybe<Conversation>>>;
};
