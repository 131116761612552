import { decodeToken, isExpired } from "react-jwt";

export function getToken() {
  const token: any = localStorage.getItem("token");
  return token;
}

export function getTokenRole() {
  const token: any = localStorage.getItem("token");
  if (!token) {
    return null;
  }
  const token_decoded: any = decodeToken(token);
  return token_decoded?.user?.role;
}

export function getTokenUserId(tk?: any) {
  let token: any = null;
  if (tk) {
    token = tk;
  } else {
    token = localStorage.getItem("token");
  }
  if (!token) {
    return null;
  }
  const token_decoded: any = decodeToken(token);
  return token_decoded?.user?.id;
}

export function getTokenClient() {
  const token: any = localStorage.getItem("token");
  if (!token) {
    return null;
  }
  const tokenDecoded: any = decodeToken(token);
  return tokenDecoded?.user?.client;
}

export function getIsTokenExpired(token?: any) {
  if (!token) {
    const token: any = localStorage.getItem("token");
    if (token) {
      const expired: boolean = isExpired(token);
      return expired;
    } else {
      return true;
    }
  } else if (token) {
    const expired: boolean = isExpired(token);
    return expired;
  }
}

export function isInTeams() {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return userAgent.includes("teamsmobile") || window.parent !== window;
}

export function getTokenExpiryDate(token: any) {
  const decoded: any = decodeToken(token);
  return decoded?.exp;
}

export function getTicketSysClassName(ticket: string) {
  if (ticket.slice(0, 3) === "INC") {
    return "incident";
  }
}
