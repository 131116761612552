import { Stack } from "@fluentui/react";
import { Button, Text } from "@fluentui/react-components";
import { ErrorCircleRegular } from "@fluentui/react-icons";
import React, { Component, ErrorInfo, ReactNode } from "react";
import { customTokens } from "../theme";

export function ErrorBoundaryComponent() {
  return (
    <Stack
      data-testid="error-boundary"
      verticalAlign="center"
      horizontalAlign="center"
      style={{ width: "100vw", height: "100vh" }}
    >
      <ErrorCircleRegular fontSize={60} fontWeight={700} color="#E30021" />

      <Text size={600}>Sorry, something went wrong.</Text>
      <Text size={300} style={{ marginTop: "20px" }}>
        Please try to refresh the page or Teams client and check your network
        connection.
      </Text>
      <Text size={300} style={{ marginTop: "10px" }}>
        If issue still occurs, please contact your system administrator
      </Text>
      <Stack
        horizontal
        horizontalAlign="space-between"
        style={{
          marginTop: "10vh",
        }}
      >
        <Button appearance="primary" onClick={() => window.location.reload()}>
          Refresh
        </Button>
        <a href="mailto:mstoolsdevteam.pl@capgemini.com">
          <Button
            style={{
              marginLeft: "2vw",
              border: `1px solid ${customTokens.vibrantBlue}`,
              color: customTokens.vibrantBlue,
            }}
          >
            Contact System Administrator
          </Button>
        </a>
      </Stack>
    </Stack>
  );
}

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorBoundaryComponent />;
    }

    return this.props.children;
  }
}
