import { Stack } from "@fluentui/react";
import {
  Text,
  Dropdown,
  Option,
  Textarea,
  Button,
  Tooltip,
  Label,
  tokens,
} from "@fluentui/react-components";
import { customTokens } from "../../../../theme";
import { makeStyles } from "@griffel/react";
import {
  statuses,
  type StatusOption,
  resolutionCodes,
  type ResolutionCode,
  onHoldreasons,
  type OnHoldReason,
} from "../../../helpers/constants";
import { AiStars } from "../../../../assets/aiStars";
import { ClipLoader } from "react-spinners";
import { useState } from "react";
import { DrawerStyles } from "../DrawerComponentStyles";
import { useMutation } from "@apollo/client";
import {
  CREATE_AI_WORK_NOTE_TEXT,
  GET_MESSAGES,
  UPDATE_TICKET,
} from "../../../helpers/graphql";
import { useAppClient } from "../../../services/appClient";
import {
  Message,
  MessageType,
  ServiceNowTicket,
} from "../../../../__generated__/graphql";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FC } from "react";
import { ErrorCircle12Regular } from "@fluentui/react-icons";
import { addDays } from "@fluentui/react-calendar-compat";
import { DatePicker } from "@fluentui/react-datepicker-compat";

interface ErrorTextProps {
  error: string | undefined;
  touched: boolean | undefined;
}

const useStyles = makeStyles({
  error: {
    display: "flex",
    alignItems: "end",
    // height: tokens.spacingVerticalL,
  },
  errorText: {
    color: tokens.colorPaletteRedForeground1,
    fontSize: tokens.fontSizeBase100,
    // marginBottom: "2px",
  },
  errorIcon: {
    marginBottom: "3px",
    color: tokens.colorPaletteRedForeground1,
    marginRight: tokens.spacingHorizontalSNudge,
  },
});

const ErrorText: FC<ErrorTextProps> = ({ error, touched }: ErrorTextProps) => {
  const c = useStyles();
  return (
    <span className={c.error}>
      {error && touched ? (
        <>
          <ErrorCircle12Regular className={c.errorIcon} />
          <Text className={c.errorText}>{error}</Text>
        </>
      ) : (
        <>
          <span />
        </>
      )}
    </span>
  );
};

interface IUpdateTicketForm {
  acrData: ServiceNowTicket[] | undefined;
  selectedIndex: number;
}

export function UpdateTicketForm({
  acrData,
  selectedIndex,
}: IUpdateTicketForm) {
  const styles = DrawerStyles();

  const {
    selectedConversationData,
    selectedConversationId,
    setCurrentConversationMessages,
    currentConversationMessages,
    setIsScrolling,
  } = useAppClient();

  const [selectedResolutionCode, setSelectedResolutionCode] =
    useState<string>("");
  const [selectedStatusCode, setSelectedStatusCode] = useState<string>();
  const [selectedOnHoldReason, setSelectedOnHoldReason] = useState<string>("");
  const [resolutionNotes, setResolutionNotes] = useState<string>("");
  const [stateSummary, setStateSummary] = useState<string>("");
  const [isUpdateTicketLoading, setIsUpdateTicketLoading] =
    useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | null | undefined>(
    null
  );
  const [updateNotes, { loading: updateNotesLoading }] = useMutation(
    CREATE_AI_WORK_NOTE_TEXT,
    {
      variables: {
        callEngagementId: selectedConversationData?.callEngagement?.id,
      },
      onCompleted: ({ createAiWorkNoteText }) => {
        setStateSummary(createAiWorkNoteText);
        formik.setFieldValue("workNotes", createAiWorkNoteText);
      },
      onError: (error) => {},
    }
  );

  const [updateTicket] = useMutation(UPDATE_TICKET, {
    onCompleted: (result) => {
      const newMessage: Message = {
        botMessage: true,
        created: `${new Date().toISOString().slice(0, 19).replace("T", " ")}`,
        id: result.updateTicket.id,
        received: true,
        type: MessageType.Message,
        value: result.updateTicket.value,
        exchange: result.updateTicket.exchange,
        kbArticleInstances: [],
        ...result.updateTicket,
      };

      setCurrentConversationMessages([
        ...currentConversationMessages,
        ...[newMessage],
      ]);

      setIsUpdateTicketLoading(false);
      setIsScrolling(true);
      setStateSummary("");
      setResolutionNotes("");
    },

    onError: () => {
      setIsUpdateTicketLoading(false);
      setStateSummary("");
    },
    variables: {
      conversationId: selectedConversationId,
      number: acrData && acrData[selectedIndex]?.number,
      sysId: acrData && acrData[selectedIndex]?.sysId,
      workNotes: stateSummary,
      state: String(selectedStatusCode),
      closeCode: selectedResolutionCode,
      closeNotes: resolutionNotes,
      onHoldReason: selectedOnHoldReason,
    },
  });

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      workNotes: stateSummary,
      state: String(selectedStatusCode),
      closeCode: selectedResolutionCode,
      closeNotes: resolutionNotes,
      onHoldReason: selectedOnHoldReason,
    },
    onSubmit: (values) => {
      updateTicket({
        variables: {
          ...values,
          conversationId: selectedConversationId,
          number: acrData && acrData[selectedIndex]?.number,
          sysId: acrData && acrData[selectedIndex]?.sysId,
        },
      });
    },
    validationSchema: Yup.object().shape({
      workNotes: Yup.string().required("Required"),
      state: Yup.string().optional(),
      closeCode:
        selectedStatusCode === "6" || selectedStatusCode === "7"
          ? Yup.string().required("Required")
          : Yup.string().optional(),
      closeNotes:
        selectedStatusCode === "6" || selectedStatusCode === "7"
          ? Yup.string().required("Required")
          : Yup.string().optional(),
      onHoldReason:
        selectedStatusCode === "3"
          ? Yup.string().required("Required")
          : Yup.string().optional(),
    }),
  });

  return (
    <Stack>
      <form onSubmit={formik.handleSubmit}>
        <Stack.Item>
          <Label htmlFor="state">
            <Text
              size={200}
              style={{
                textAlign: "left",
                width: "8vw",
                color: customTokens.drawerRightTextColor,
              }}
            >
              State:
            </Text>
          </Label>
          <Stack style={{ width: "80%", maxWidth: "80%", marginTop: "10px" }}>
            <Dropdown
              {...formik.getFieldProps("state")}
              onChange={formik.handleChange}
              value={selectedStatusCode}
              id="state"
              style={dropdownStyle}
              placeholder="Choose an option"
              onOptionSelect={(e: any, dt: any) => {
                setSelectedStatusCode(dt.optionValue);
                formik.values.state = dt.optionValue;
              }}
            >
              {statuses.map((status: StatusOption) => {
                return (
                  <Option
                    style={{ maxWidth: "100%" }}
                    key={status.value}
                    text={status.displayValue}
                    value={status.value}
                  >
                    {status.displayValue}
                  </Option>
                );
              })}
            </Dropdown>

            <ErrorText
              error={formik.errors.state}
              touched={formik.touched.state}
            />
          </Stack>
        </Stack.Item>

        {(selectedStatusCode === "6" || selectedStatusCode === "7") && (
          <>
            <Stack.Item style={{ marginTop: "10px" }}>
              <Label required htmlFor="closeCode">
                <Text
                  size={200}
                  style={{
                    textAlign: "left",
                    width: "8vw",
                    color: customTokens.drawerRightTextColor,
                  }}
                >
                  Resolution code:
                </Text>
              </Label>
              <Stack
                style={{ width: "80%", maxWidth: "80%", marginTop: "10px" }}
              >
                <Dropdown
                  {...formik.getFieldProps("closeCode")}
                  onChange={formik.handleChange}
                  value={selectedResolutionCode}
                  id="closeCode"
                  style={dropdownStyle}
                  placeholder="Choose an option"
                  onOptionSelect={(e, dt) => {
                    setSelectedResolutionCode(String(dt.optionValue));
                    formik.values.closeCode = String(dt.optionValue);
                  }}
                >
                  {resolutionCodes.map((code: ResolutionCode) => {
                    return (
                      <Option
                        style={{ maxWidth: "100%" }}
                        key={code}
                        text={code}
                      >
                        {code}
                      </Option>
                    );
                  })}
                </Dropdown>

                <ErrorText
                  error={formik.errors.closeCode}
                  touched={formik.touched.closeCode}
                />
              </Stack>
            </Stack.Item>
            <Stack.Item style={{ marginTop: "10px" }}>
              <Label required htmlFor="resolutionNotes">
                <Text
                  size={200}
                  style={{
                    textAlign: "left",
                    width: "8vw",
                    color: customTokens.drawerRightTextColor,
                  }}
                >
                  Resolution notes:
                </Text>
              </Label>

              <div className={styles.textAreaWrapper}>
                <ErrorText
                  error={formik.errors.closeNotes}
                  touched={formik.touched.closeNotes}
                />

                <Textarea
                  id="resolutionNotes"
                  {...formik.getFieldProps("closeNotes")}
                  onChange={formik.handleChange}
                  textarea={{
                    style: { height: "unset" },
                  }}
                  placeholder="Resolution notes"
                  value={formik.values.closeNotes}
                  // onChange={(e) => setResolutionNotes(e.target.value)}
                  className={styles.textAreaTicket}
                  // resize="vertical"
                />
              </div>
            </Stack.Item>
          </>
        )}

        {selectedStatusCode == "3" && (
          <Stack style={{ width: "80%", maxWidth: "80%", marginTop: "10px" }}>
            <Stack.Item style={{ marginTop: "10px" }}>
              <Label required htmlFor="onHoldReason">
                <Text
                  size={200}
                  style={{
                    textAlign: "left",
                    width: "8vw",
                    color: customTokens.drawerRightTextColor,
                  }}
                >
                  On Hold Reason
                </Text>
              </Label>
              <Dropdown
                {...formik.getFieldProps("onHoldReason")}
                onChange={formik.handleChange}
                value={selectedOnHoldReason}
                id="onHoldReason"
                style={dropdownStyle}
                placeholder="Choose an option"
                onOptionSelect={(e, dt) => {
                  setSelectedOnHoldReason(String(dt.optionText));
                  formik.values.onHoldReason = String(dt.optionValue);
                }}
              >
                {onHoldreasons.map((reason: OnHoldReason) => {
                  return (
                    <Option
                      style={{ maxWidth: "100%" }}
                      key={reason.value}
                      text={reason.displayValue}
                      value={reason.value}
                    >
                      {reason.displayValue}
                    </Option>
                  );
                })}
              </Dropdown>
              {/* <ErrorText
                error={formik.errors.onHoldReason}
                touched={formik.touched.onHoldReason}
              /> */}
            </Stack.Item>
            <Stack.Item style={{ marginTop: "10px" }}>
              <Label htmlFor="offHoldDate">
                <Text
                  size={200}
                  style={{
                    textAlign: "left",
                    width: "8vw",
                    color: customTokens.drawerRightTextColor,
                  }}
                >
                  Off Hold Date
                </Text>
              </Label>

              {/* <Field label="Select a date"> */}
              <DatePicker
                value={selectedDate}
                onSelectDate={setSelectedDate}
                placeholder="Select a date..."
                style={dropdownStyle}
                // className={styles.control}
              />
              {/* </Field> */}
            </Stack.Item>
          </Stack>
        )}

        <Stack.Item style={{ marginTop: "10px" }}>
          <Stack horizontalAlign="start">
            <Label htmlFor="workNotes" required>
              <Text
                size={200}
                style={{
                  textAlign: "left",
                  width: "8vw",
                  color: customTokens.drawerRightTextColor,
                }}
              >
                Notes:
              </Text>
            </Label>
            <Text
              size={100}
              style={{
                textAlign: "left",
                width: "8vw",
                color: customTokens.drawerRightTextColor,
              }}
            >
              Fill out ticket notes
            </Text>

            <div className={styles.textAreaWrapper}>
              <ErrorText
                error={formik.errors.workNotes}
                touched={formik.touched.workNotes}
              />
              <Textarea
                id="workNotes"
                {...formik.getFieldProps("workNotes")}
                textarea={{
                  style: { height: "unset" },
                }}
                placeholder="Notes"
                value={formik.values.workNotes}
                onChange={formik.handleChange}
                className={styles.textAreaTicket}
                //   resize="vertical"
              />
              <Tooltip
                withArrow
                content={
                  <>
                    {selectedConversationData?.callEngagement?.call?.id ? (
                      <>
                        Use AI summary assist to <br /> generate ticket notes
                      </>
                    ) : (
                      "No Transcript available"
                    )}
                  </>
                }
                relationship="label"
                positioning="before"
              >
                <Button
                  appearance="transparent"
                  id="updateNotesButton"
                  disabled={
                    updateNotesLoading ||
                    !selectedConversationData?.callEngagement?.call?.callId
                  }
                  className={styles.textAreaButton}
                  icon={
                    updateNotesLoading ? (
                      <ClipLoader color="#36d7b7" size={15} />
                    ) : (
                      <AiStars
                        data-testId="updateNotesButton"
                        color={customTokens.infoPaneButtonColor}
                        opacity={
                          selectedConversationData?.callEngagement?.call?.callId
                            ? 1
                            : 0.5
                        }
                      />
                    )
                  }
                  onClick={() => {
                    updateNotes();
                  }}
                />
              </Tooltip>
            </div>

            <Stack horizontal horizontalAlign="end" style={{ width: "80%" }}>
              <Button
                disabled={
                  !(formik.isValid && formik.dirty) || isUpdateTicketLoading
                }
                type="submit"
                appearance="primary"
                style={{
                  marginTop: "10px",
                }}
              >
                Update
              </Button>
            </Stack>
          </Stack>
        </Stack.Item>
      </form>
    </Stack>
  );
}

const dropdownStyle = {
  background: customTokens.drawerInsideColor,
  width: "100%",
  maxWidth: "100%",
  borderRadius: "8px",
  boxShadow: "0px 3px 15px rgba(0,0,0,0.2)",
  border: "none",
};
