import { Dispatch, useEffect, useState, SetStateAction } from "react";
import { Stack } from "@fluentui/react";

import {
  Button as DefaultButton,
  Divider as Separator,
  Input as TextField,
  Text,
  Menu,
  MenuTrigger,
  MenuList,
  MenuItemCheckbox,
  MenuPopover,
  MenuProps,
  tokens,
  Checkbox,
} from "@fluentui/react-components";
import {
  Edit20Regular,
  Checkmark20Regular,
  ThumbLike20Regular,
  ThumbDislike20Regular,
  Delete20Regular,
  MoreVertical24Regular,
  TextEditStyle20Regular,
  Call20Regular,
  Comment16Regular,
  CheckboxCheckedRegular,
} from "@fluentui/react-icons";
import { sidebarConversationItemStyles } from "./index";
import { useAppClient } from "../services/appClient";
import { useAuthenticationContext } from "../../AuthenticationService/useAuthentication";
import { useMutation, useApolloClient } from "@apollo/client";
import {
  GET_CONVERSATIONS,
  UPDATE_CONVERSATION,
  GET_MESSAGES,
  CREATE_CONVERSATION,
} from "../helpers/graphql";
import { customTokens } from "../../theme";
import { User } from "../../__generated__/graphql";
import { EConversationType } from "../services/appClient";

export function SidebarConversationItem({
  id,
  name: convName,
  voted,
  createConversation,
  vote,
  setIsDrawerOpen,
  item,
  deleteMode,
  setDeleteMode,
  toDeleteList,
  setToDeleteList,
}: {
  id: string;
  name: string;
  voted: boolean;
  createConversation: any;
  vote: number | null | undefined;
  setIsDrawerOpen: any;
  item: any;
  deleteMode: boolean;
  setDeleteMode: Dispatch<SetStateAction<boolean>>;
  toDeleteList: string[];
  setToDeleteList: Dispatch<SetStateAction<string[]>>;
  // toDele
}) {
  const {
    updateConversationList,
    selectedConversationId,
    conversationType,
    setSelectedConversationId,
    resetDrawer,
    messages,
    messagesLoading,
    setIsScrolling,
  } = useAppClient();

  const isCall = item.callEngagement?.call ? true : false;
  const [name, setName] = useState<string>(convName);

  function removeConversation(id: string | null | undefined) {
    if (id) {
      updateConversation({
        variables: {
          id: id,
          feDeleted: true,
        },
        refetchQueries: [GET_CONVERSATIONS],
      });
    }
  }

  function renameConversation(id: string, name: string) {
    updateConversation({
      variables: {
        id: id,
        name: name,
      },
    });
    updateConversationList();
  }

  const [editing, setEditing] = useState(true);
  const [inputText, setInputText] = useState(name);
  const styles = sidebarConversationItemStyles();

  enum CheckedItemsOptions {
    LIKE = "like",
    DISLIKE = "dislike",
    RENAME = "rename",
    DELETE = "delete",
    SELECT = "select",
  }

  const [checkedControls, setCheckedControls] = useState({
    edit: [],
  });

  const [voteLike, setVoteLike] = useState<string>("");

  const onControlsChange: MenuProps["onCheckedValueChange"] = (
    e,
    { name, checkedItems }
  ) => {
    if (!["rename", "delete"].includes(name))
      setCheckedControls((s: any) => {
        if (name === CheckedItemsOptions.LIKE) {
          setVoteLike(CheckedItemsOptions.LIKE);
          return s
            ? { [name]: CheckedItemsOptions.LIKE }
            : { [name]: CheckedItemsOptions.LIKE };
        }
        if (name === CheckedItemsOptions.DISLIKE) {
          setVoteLike(CheckedItemsOptions.DISLIKE);
          return s
            ? { [name]: CheckedItemsOptions.DISLIKE }
            : { [name]: CheckedItemsOptions.DISLIKE };
        }
        return s ? { ...s, [name]: checkedItems } : { [name]: checkedItems };
      });
  };

  useEffect(() => {
    const items: any[] = [
      vote == 10 ? CheckedItemsOptions.LIKE : null,
      vote == 1 ? CheckedItemsOptions.DISLIKE : null,
    ].filter((el: any) => el != null);
    for (const item of items) {
      setCheckedControls((s: any) => {
        setVoteLike(item);
        return s ? { ...s, [item]: item } : { [item]: item };
      });
    }
  }, []);

  useEffect(() => {}, []);

  const [updateConversation] = useMutation(UPDATE_CONVERSATION);

  return (
    <div data-testid={id} style={{ border: "0px" }}>
      <Stack
        className={styles.rootStack}
        key={id}
        horizontal
        style={{ border: "0px" }}
      >
        {!editing ? (
          <>
            <TextField
              disabled={editing}
              value={inputText}
              appearance="underline"
              onChange={(e, newValue: any) => {
                newValue.value.length <= 27 && setInputText(newValue.value);
              }}
            />
            {editing ? (
              <></>
            ) : (
              <>
                <Separator vertical />
                <DefaultButton
                  icon={<Checkmark20Regular />}
                  data-testid="conversation-item-editing-check-button"
                  className={styles.smallButton}
                  onClick={() => {
                    setName(inputText);
                    updateConversation({
                      variables: {
                        id: id,
                        name: inputText,
                      },
                    });
                    // Update The Local Conversation name
                    if (id) {
                      renameConversation(id, inputText);
                    }
                    setEditing(true);
                  }}
                ></DefaultButton>
              </>
            )}
          </>
        ) : (
          <Stack horizontal disableShrink styles={{ root: { width: "100%" } }}>
            <DefaultButton
              appearance={"subtle"}
              style={{
                width: "100%",
                fontWeight: voted ? "100" : "600",
                boxSizing: "border-box",
                border: "0",
                textAlign: "left",
                // fontSize: "0.85rem",
                padding: "6px",
                background:
                  selectedConversationId === id
                    ? customTokens.selectedConversationBackground
                    : "transparent",
              }}
              onClick={async () => {
                if (id && id != selectedConversationId && !deleteMode) {
                  if (
                    messages.length <= 0 &&
                    !messagesLoading &&
                    conversationType != EConversationType.CALL
                  ) {
                    updateConversation({
                      variables: {
                        feDeleted: true,
                        id: selectedConversationId,
                      },
                    });
                  }

                  resetDrawer();
                  setIsDrawerOpen(false);
                  setSelectedConversationId(id);
                  setIsScrolling(true);
                }
              }}
            >
              <Text style={{ textAlign: "left", width: "100%" }}>
                {isCall ? (
                  <Stack horizontal>
                    {deleteMode ? (
                      <Stack
                        verticalAlign="center"
                        horizontalAlign="center"
                        style={{
                          marginRight: "5px",
                          height: "20px",
                          width: "26px",
                          borderRadius: "50%",
                        }}
                      >
                        <Checkbox
                          checked={toDeleteList.includes(id)}
                          defaultChecked={false}
                          onChange={() => {
                            // setDeleteMode(true);
                          }}
                          onClick={() => {
                            console.log("Select", id);
                            if (toDeleteList.includes(id)) {
                              setToDeleteList(
                                toDeleteList.filter((item) => item !== id)
                              );
                            } else {
                              setToDeleteList([...toDeleteList, id]);
                            }
                          }}
                        />{" "}
                      </Stack>
                    ) : (
                      <Stack
                        verticalAlign="center"
                        horizontalAlign="center"
                        style={{
                          marginRight: "5px",
                          height: "26px",
                          width: "26px",
                          backgroundColor: customTokens.drawerSidebarIcons,
                          borderRadius: "50%",
                        }}
                      >
                        <Call20Regular style={{}} />
                      </Stack>
                    )}
                    <Stack style={{ marginTop: "2px" }}>
                      <Text size={200}>{name}</Text>
                    </Stack>
                  </Stack>
                ) : (
                  <Stack horizontal>
                    {deleteMode ? (
                      <Stack
                        verticalAlign="center"
                        horizontalAlign="center"
                        style={{
                          marginRight: "5px",
                          height: "20px",
                          width: "26px",
                          borderRadius: "50%",
                        }}
                      >
                        <Checkbox
                          checked={toDeleteList.includes(id)}
                          defaultChecked={false}
                          onChange={() => {
                            // setDeleteMode(true);
                          }}
                          onClick={() => {
                            console.log("Select", id);
                            if (toDeleteList.includes(id)) {
                              setToDeleteList(
                                toDeleteList.filter(
                                  (item: string) => item !== id
                                )
                              );
                            } else {
                              setToDeleteList([...toDeleteList, id]);
                            }
                          }}
                        />{" "}
                      </Stack>
                    ) : (
                      <Stack
                        verticalAlign="center"
                        horizontalAlign="center"
                        style={{
                          marginRight: "5px",
                          height: "26px",
                          width: "26px",
                          backgroundColor: customTokens.drawerSidebarIcons,
                          borderRadius: "50%",
                        }}
                      >
                        <Comment16Regular style={{}} />
                      </Stack>
                    )}

                    <Stack style={{ marginTop: "2px" }}>
                      <Text size={200}>{name}</Text>
                    </Stack>
                  </Stack>
                )}{" "}
              </Text>
              {/* Like / Dislike Display Logic */}

              {voteLike === CheckedItemsOptions.LIKE && (
                <>
                  <ThumbLike20Regular />{" "}
                </>
              )}

              {voteLike === CheckedItemsOptions.DISLIKE && (
                <>
                  <ThumbDislike20Regular />
                </>
              )}

              <Menu>
                <MenuTrigger disableButtonEnhancement>
                  <DefaultButton
                    data-testid="sidebar-conversation-item-menu-trigger"
                    icon={<MoreVertical24Regular />}
                    appearance="transparent"
                  />
                </MenuTrigger>
                <MenuPopover
                  style={{
                    background: customTokens.messageBotAndMenuBackground,
                  }}
                >
                  <MenuList
                    onCheckedValueChange={onControlsChange}
                    checkedValues={checkedControls}
                  >
                    <MenuItemCheckbox
                      style={{
                        background: customTokens.messageBotAndMenuBackground,
                      }}
                      icon={<ThumbLike20Regular />}
                      name={CheckedItemsOptions.LIKE}
                      value={CheckedItemsOptions.LIKE}
                      onClick={() => {
                        updateConversation({
                          variables: {
                            id: id,
                            evaluation: 10,
                          },
                        });
                      }}
                    >
                      Like
                    </MenuItemCheckbox>
                    <MenuItemCheckbox
                      style={{
                        background: customTokens.messageBotAndMenuBackground,
                      }}
                      icon={<ThumbDislike20Regular />}
                      name={CheckedItemsOptions.DISLIKE}
                      value={CheckedItemsOptions.DISLIKE}
                      defaultChecked={vote == 1 ? true : false}
                      onClick={() => {
                        updateConversation({
                          variables: {
                            id: id,
                            evaluation: 1,
                          },
                        });
                      }}
                    >
                      Dislike
                    </MenuItemCheckbox>
                    <MenuItemCheckbox
                      style={{
                        background: customTokens.messageBotAndMenuBackground,
                      }}
                      icon={<TextEditStyle20Regular />}
                      name={CheckedItemsOptions.RENAME}
                      value={CheckedItemsOptions.RENAME}
                      defaultChecked={false}
                      onClick={() => setEditing(!editing)}
                    >
                      Rename
                    </MenuItemCheckbox>
                    <MenuItemCheckbox
                      style={{
                        background: customTokens.messageBotAndMenuBackground,
                      }}
                      icon={<Delete20Regular />}
                      name={CheckedItemsOptions.DELETE}
                      value={CheckedItemsOptions.DELETE}
                      defaultChecked={false}
                      onClick={() => {
                        removeConversation(id);
                      }}
                    >
                      Delete
                    </MenuItemCheckbox>
                    <MenuItemCheckbox
                      style={{
                        background: customTokens.messageBotAndMenuBackground,
                      }}
                      icon={<CheckboxCheckedRegular />}
                      name={CheckedItemsOptions.SELECT}
                      value={CheckedItemsOptions.SELECT}
                      defaultChecked={false}
                      checkmark={<></>}
                      // disabled={deleteMode}
                      onClick={() => {
                        setDeleteMode(!deleteMode);
                        if (toDeleteList.includes(id)) {
                          setToDeleteList(
                            toDeleteList.filter((item) => item !== id)
                          );
                        } else {
                          setToDeleteList([...toDeleteList, id]);
                        }
                        if (deleteMode) {
                          setToDeleteList([]);
                        }
                      }}
                    >
                      {!deleteMode ? "Select mode" : "Exit select mode"}
                    </MenuItemCheckbox>
                  </MenuList>
                </MenuPopover>
              </Menu>
            </DefaultButton>
          </Stack>
        )}
      </Stack>
    </div>
  );
}

export default SidebarConversationItem;
