import { useState, useEffect, useMemo } from "react";
import {
  AppClientContext,
  IAppClientContext,
  ELoginState,
  EConversationType,
  ERightDrawerState,
} from "./AppClientContext";
import { type Message } from "../../../__generated__/graphql";
import {
  AI_ASSIST,
  CREATE_CONVERSATION,
  GET_CONVERSATIONS,
  GET_MESSAGES,
  IDENTIFY_CALLER,
  LATEST_TICKETS,
  GET_CONVERSATION,
  UPDATE_CONVERSATION,
} from "../../helpers/graphql";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import { MessageTypes } from "../../ChatView";
import { useAuthenticationContext } from "../../../AuthenticationService/useAuthentication";
import { aiAssistPerConversation } from "../../..";

export const AppClientProvider = ({ children }: { children: any }) => {
  const { refreshToken, user, userLoading } = useAuthenticationContext();
  const [selectedConversationId, setSelectedConversationId] =
    useState<string>("");
  const localFontSizeValue: any = localStorage.getItem("font-size-value");
  const initialFontSizeValueParsed = JSON.parse(localFontSizeValue);
  const [fontSizeValue, setFontSizeValue] = useState<
    100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900
  >(initialFontSizeValueParsed ? initialFontSizeValueParsed : 200); // consider that with local storage somehow
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(false); // Consider that with local storage
  const [createConversation, { data, loading, error }] =
    useMutation(CREATE_CONVERSATION);
  const [loginState, setLoginState] = useState<ELoginState>(
    ELoginState.LOGGING_IN
  );
  const [conversationsProccessed, setConversationsProccessed] = useState<
    string[]
  >([]);
  const [debugMode, setDebugMode] = useState<boolean>(false);
  const [validationMode, setValidationMode] = useState<boolean>(false);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const [conversationType, setConversationType] =
    useState<EConversationType | null>(null);
  const [rightDrawerState, setRightDrawerState] = useState<ERightDrawerState>(
    ERightDrawerState.CLOSED
  );
  const [conversationTokens, setConversationTokens] = useState<string>("");
  const [currentConversationMessages, setCurrentConversationMessages] =
    useState<Message[]>([]);

  const [chatLoading, setChatLoading] = useState<boolean>(false);

  const [callIntentLoading, setCallIntentLoading] = useState(false);

  const [loadingBubbleDisplayMessage, setLoadingBubbleDisplayMessage] =
    useState<string | null>(null);

  const [cacheAiAssistData, setCacheAiAssistData] = useState<any>(null);

  function displayLoadingBubbleMessage(type: MessageTypes) {
    // For now the newMessage subscription just sends out 3 Message types during a output.
    switch (type) {
      case MessageTypes.MESSAGE:
        setLoadingBubbleDisplayMessage("Hold on, I'm searching ...");
        break;
      case MessageTypes.ACTION:
        setLoadingBubbleDisplayMessage("Checking the knowledge base");
        break;
      case MessageTypes.OBSERVATION:
        setLoadingBubbleDisplayMessage("Validating the responses");
        break;
      case MessageTypes.START_CALL_INTENT:
        setLoadingBubbleDisplayMessage("Listening ...");
        break;
      case MessageTypes.RESPONSE:
        setLoadingBubbleDisplayMessage(null);
        break;
      default:
        setLoadingBubbleDisplayMessage(
          "Just a moment. The call intent information is on the way."
        );
        break;
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                                  MUTATIONS                                 */
  /* -------------------------------------------------------------------------- */
  const [
    updateAiAssistData,
    {
      data: aiAssistData,
      loading: aiAssistLoading,
      error: aiAssistError,
      reset: resetAiAssistData,
    },
  ] = useMutation(AI_ASSIST, {
    variables: {
      conversationId: selectedConversationId,
    },
    onCompleted: ({ aiAssist }) => {
      console.log("aiAssist on compleated was called");
      setCacheAiAssistData(aiAssist);
    },
  });

  /* -------------------------------------------------------------------------- */
  /*                                LAZY QUERIES                                */
  /* -------------------------------------------------------------------------- */

  const {
    data: selectedConversationData,
    loading: selectedConversationLoading,
    error: selectedConversationError,
    refetch: refetchSelectedConversation,
  } = useQuery(GET_CONVERSATION, {
    variables: {
      id: selectedConversationId,
    },
    skip: !user || userLoading || !selectedConversationId,
  });

  const {
    data: conversationList,
    loading: conversationListLoading,
    error: conversationListError,
    refetch: updateConversationList,
  } = useQuery(GET_CONVERSATIONS, {
    variables: {
      feDeleted: false,
    },
    fetchPolicy: "cache-and-network",
    skip: !user || userLoading,
  });

  const {
    data: messages,
    loading: messagesLoading,
    error: messagesError,
    refetch: updateMessages,
  } = useQuery(GET_MESSAGES, {
    variables: {
      conversationId: selectedConversationId,
    },
    onCompleted: (data) => {
      let msgs = data.user?.conversations?.[0]?.messages;
      let sorted: any = null;
      if (msgs) {
        try {
          sorted = msgs.slice().sort((a: any, b: any) => {
            let ac: any = Date.parse(a.created);
            let bc: any = Date.parse(b.created);
            return ac - bc;
          });
        } catch (e) {
          console.log("error sorting messages", e);
        }
        setCurrentConversationMessages(sorted ? sorted : msgs);
      } else {
        setCurrentConversationMessages(msgs);
      }

      console.log("Messages", sorted);
    },
    skip: !user || userLoading || !selectedConversationId,
  });

  const [
    identifyCaller,
    {
      data: callerData,
      refetch: refetchIdentifyCaller,
      called: calledIdentifyCaller,
    },
  ] = useLazyQuery(IDENTIFY_CALLER);

  const [
    getLatestTickets,
    { data: ticketData, loading: ticketLoading, error: ticketError },
  ] = useLazyQuery(LATEST_TICKETS);

  const [updateConversation] = useMutation(UPDATE_CONVERSATION, {
    refetchQueries: [LATEST_TICKETS],
  });

  /* -------------------------------------------------------------------------- */
  /*                                 USE EFFECTS                                */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    setTimeout(() => {
      setIsScrolling(true);
    }, 150);
  }, [messages]);

  useEffect(() => {
    if (selectedConversationId) {
      resetDrawer();
      setConversationType(null);
      const conversation = aiAssistPerConversation().filter(
        (c: any) => c.conversationId == selectedConversationId
      );

      if (conversation.length) {
        console.log(conversation[0]);
        setCacheAiAssistData(conversation[0]);
      } else {
        setCacheAiAssistData(null);
      }

      // resetAiAssistData();
      // updateAiAssistData();
    }
  }, [selectedConversationId]);

  useEffect(() => {
    if (selectedConversationData) {
      setConversationType(
        selectedConversationData.user.conversations[0]?.callEngagement?.call
          ?.callId
          ? EConversationType.CALL
          : EConversationType.CHAT
      );

      if (
        selectedConversationData.user.conversations[0]?.callEngagement?.call
          ?.callId
      ) {
        identifyCaller({
          variables: {
            callId:
              selectedConversationData.user.conversations[0]?.callEngagement
                ?.call?.callId,
          },
          onCompleted: ({ identifyCaller }: { identifyCaller: any }) => {
            getLatestTickets({
              variables: {
                userId: identifyCaller.sysId,
                callId:
                  selectedConversationData.user.conversations[0]?.callEngagement
                    ?.call?.callId,
              },
              fetchPolicy: "cache-and-network",
            });
          },
        });
      } else {
        resetDrawer();
      }
    }
  }, [selectedConversationData]);

  useEffect(() => {
    switch (rightDrawerState) {
      case ERightDrawerState.CLOSED:
        break;
      case ERightDrawerState.ASSIST:
        // if (!aiAssistData && !aiAssistLoading) {
        //   updateAiAssistData();
        // }
        break;
      case ERightDrawerState.CALLER:
        break;
      case ERightDrawerState.TICKETS:
        if (!ticketData && !ticketLoading) {
          getLatestTickets();
        }
        break;
    }
  }, [rightDrawerState]);

  useEffect(() => {
    if (
      conversationList &&
      conversationList?.user?.conversations &&
      conversationList?.user?.conversations?.length <= 0 &&
      user
    ) {
      createConversation({
        variables: {
          client: user?.client?.id,
          user: user?.id,
        },
        onCompleted: (r) => {
          setSelectedConversationId(r.createConversation?.conversation?.id);
        },
      });
    }
    if (
      conversationList &&
      conversationList?.user?.conversations &&
      selectedConversationId === "" &&
      conversationList?.user?.conversations?.length > 0
    ) {
      setSelectedConversationId(
        conversationList?.user?.conversations[
          conversationList.user.conversations.length - 1
        ].id
      );
    }
  }, [conversationList]);

  const resetDrawer = () => {
    setRightDrawerState(ERightDrawerState.CLOSED);
  };
  const renderDrawer = (type: ERightDrawerState) => {
    setRightDrawerState(type);
  };
  const addConversation = () => 0;

  const getSelectedConversationsTranscriptId = () => {
    if (
      selectedConversationData &&
      selectedConversationData.user.conversations[0].callEngagement?.call
    ) {
      return selectedConversationData.user.conversations[0].callEngagement?.call
        .transcripts[0].id;
    } else {
      return null;
    }
  };

  const value: IAppClientContext = useMemo(
    () => ({
      displayLoadingBubbleMessage,
      loadingBubbleDisplayMessage,
      callIntentLoading,
      setCallIntentLoading,
      loginState,
      setLoginState,
      fontSizeValue,
      setFontSizeValue,
      isSidebarCollapsed,
      setIsSidebarCollapsed,
      conversationList: conversationList?.user?.conversations,
      addConversation,
      updateConversationList,
      conversationListLoading,
      conversationListError,
      selectedConversationId,
      setSelectedConversationId,
      selectedConversationData: selectedConversationData?.user.conversations[0],
      selectedConversationLoading,
      selectedConversationError,
      messages: currentConversationMessages,
      refetchSelectedConversation,
      updateMessages,
      messagesLoading,
      messagesError,
      conversationsProccessed,
      setConversationsProccessed,
      debugMode,
      setDebugMode,
      validationMode,
      setValidationMode,
      isScrolling,
      setIsScrolling,
      conversationType,
      setConversationType,
      conversationTokens,
      setConversationTokens,
      renderDrawer,
      resetDrawer,
      rightDrawerState,
      setRightDrawerState,
      callerData: callerData,
      identifyCaller,
      refetchIdentifyCaller,
      ticketData: ticketData?.latestTickets,
      ticketLoading,
      ticketError,
      aiAssistData: aiAssistData?.aiAssist, //memoryFormData
      updateAiAssistData,
      aiAssistLoading,
      aiAssistError,
      currentConversationMessages,
      setCurrentConversationMessages,
      chatLoading,
      setChatLoading,
      getSelectedConversationsTranscriptId,
      getLatestTickets,
      cacheAiAssistData,
      setCacheAiAssistData,
    }),
    [
      displayLoadingBubbleMessage,
      loadingBubbleDisplayMessage,
      fontSizeValue,
      isSidebarCollapsed,
      refreshToken,
      loginState,
      conversationList,
      conversationListLoading,
      conversationListError,
      selectedConversationId,
      selectedConversationData,
      selectedConversationLoading,
      selectedConversationError,
      messages,
      messagesLoading,
      messagesError,
      conversationsProccessed,
      debugMode,
      validationMode,
      callIntentLoading,
      isScrolling,
      conversationType,
      conversationTokens,
      rightDrawerState,
      callerData,
      ticketData,
      ticketLoading,
      ticketError,
      aiAssistData,
      aiAssistLoading,
      aiAssistError,
      currentConversationMessages,
      chatLoading,
      getSelectedConversationsTranscriptId,
      cacheAiAssistData,
      setCacheAiAssistData,
    ]
  );

  return (
    <AppClientContext.Provider value={value}>
      {children}
    </AppClientContext.Provider>
  );
};
