import { Navigate, Route, Routes, HashRouter } from "react-router-dom";
import Client from "./AgentChatModule/Client";
import { AppClientProvider } from "./AgentChatModule/services/appClient";
import { useEffect } from "react";
import Subscriptions from "./AgentChatModule/Subscriptions";
import KbaManagement from "./KbaManagementModule/KbaManagement";
import { AuthenticationWrapper } from "./AuthenticationService/AuthenticationWrapper";
import { AuthenticationProvider } from "./AuthenticationService/AuthenticationProvider";
import { MsalProvider } from "@azure/msal-react";
import { NetworkNotifier } from "./AgentChatModule/HelperComponents/NetworkNotifier";

export default function App({ instance }: { instance?: any }) {
  useEffect(() => {
    Notification.requestPermission();
  }, []);

  return (
    <MsalProvider instance={instance}>
      <HashRouter>
        <AuthenticationProvider>
          <AuthenticationWrapper>
            <NetworkNotifier>
              <Routes>
                <Route
                  path="/tab"
                  element={
                    <AppClientProvider>
                      <Subscriptions>
                        <Client />
                      </Subscriptions>
                    </AppClientProvider>
                  }
                  errorElement={<div>Error</div>}
                />

                <Route
                  path="/kbaManagement"
                  element={<KbaManagement />}
                  errorElement={<div>Error</div>}
                />

                <Route path="*" element={<Navigate to={"/tab"} />}></Route>
              </Routes>
            </NetworkNotifier>
          </AuthenticationWrapper>
        </AuthenticationProvider>
      </HashRouter>
    </MsalProvider>
  );
}
