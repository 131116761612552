import * as React from "react";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  Text,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
} from "@fluentui/react-components";
import { ArrowUpload20Regular } from "@fluentui/react-icons";
import { useState } from "react";
import { Stack } from "@fluentui/react";

interface IUploadModal {
  title?: string;
}

export function UploadModal({ title }: IUploadModal) {
  const [files, setFiles] = useState<File[]>([]);

  const handleFiles = (selectedFiles: FileList | null) => {
    if (selectedFiles) {
      const filesArray = Array.from(selectedFiles);
      setFiles((prevFiles) => [...prevFiles, ...filesArray]);
    }
  };
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    handleFiles(e.dataTransfer.files);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) =>
    e.preventDefault();

  const handleFilePicker = () => {
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    fileInput?.click();
  };

  return (
    <Dialog>
      <DialogTrigger disableButtonEnhancement>
        <Button appearance="transparent">
          <ArrowUpload20Regular />
        </Button>
      </DialogTrigger>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>Upload Files</DialogTitle>

          <DialogContent>
            <div style={styles.container}>
              <Stack verticalAlign="center" horizontalAlign="center">
                <Stack.Item>
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    multiple
                    onChange={(e) => handleFiles(e.target.files)}
                  />

                  {/* Drag and Drop Area */}
                  <div
                    style={styles.dropArea}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                  >
                    <Stack verticalAlign="center" horizontalAlign="center">
                      <Stack.Item>
                        <p>Drag & drop files</p>
                      </Stack.Item>
                      <Stack.Item>
                        <button
                          type="button"
                          onClick={handleFilePicker}
                          style={styles.button}
                        >
                          Choose Files
                        </button>
                      </Stack.Item>
                    </Stack>
                  </div>
                </Stack.Item>
                <Stack.Item>
                  {/* Show selected files */}
                  <br />
                  {files.length > 0 && (
                    <div style={styles.fileList}>
                      <h3>Selected Files:</h3>
                      <ul>
                        {files.map((file, index) => (
                          <li key={index}>{file.name}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </Stack.Item>
              </Stack>
            </div>
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button
                appearance="secondary"
                onClick={() => {
                  setFiles([]);
                }}
              >
                Close
              </Button>
            </DialogTrigger>
            <Button appearance="primary">Upload</Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: "flex",
    alignItems: "center",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    marginLeft: "auto",
    marginRight: "auto",
  },
  dropArea: {
    width: "500px",
    height: "200px",
    border: "2px dashed #0F5F86",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    // backgroundColor: "#f9f9f9",
    color: "#0F5F86",
    marginBottom: "20px",
  },
  button: {
    marginTop: "10px",
    padding: "10px 20px",
    backgroundColor: "#0F5F86",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  fileList: {
    width: "400px",
  },
};
