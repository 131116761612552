import { Stack, Persona, PersonaSize } from "@fluentui/react";
import { Text } from "@fluentui/react-components";
import ChipIcon from "../../../assets/chipIcon.svg";
import { BeatLoader } from "react-spinners";
import { useAppClient } from "../../services/appClient";
import { loadingMessageStyles } from "./TemplateStyles";
import { animated, useSpring } from "@react-spring/web";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

export function ChatMessageLoading() {
  const { conversationTokens, loadingBubbleDisplayMessage } = useAppClient();
  const styles = loadingMessageStyles();

  const springs = useSpring({
    from: { x: -100 },
    to: { x: 0 },
  });

  return (
    <animated.div style={{ ...springs }}>
      <Stack
        horizontal
        tokens={{ childrenGap: "3" }}
        className={styles.rootStack}
      >
        <Persona size={PersonaSize.size32} imageUrl={ChipIcon} />
        <Stack.Item className={styles.rootStackItem}>
          <Stack
            horizontalAlign="space-between"
            tokens={{ childrenGap: "1" }}
            className={styles.rootStackItemStack}
          ></Stack>
          <Stack className={styles.bubbleStack}>
            <Text className={styles.bubbleText} size={200}>
              {!conversationTokens && (
                <>
                  <BeatLoader
                    data-testId="chat-message-loading-loader"
                    size={10}
                    color="#777"
                  />

                  {loadingBubbleDisplayMessage && (
                    <Text size={200}>{loadingBubbleDisplayMessage}</Text>
                  )}
                </>
              )}

              {conversationTokens && (
                <div
                  style={{
                    // whiteSpace: "pre-wrap",
                    fontFamily: "Segoe UI",
                    marginTop: "-10",
                  }}
                >
                  <Markdown remarkPlugins={[remarkGfm]}>
                    {conversationTokens
                      .replace("```markdown\n", "")
                      .replace("\n```", "")}
                  </Markdown>
                  <span className="cursorBlink">|</span>
                </div>
              )}
            </Text>
          </Stack>
        </Stack.Item>
      </Stack>
    </animated.div>
  );
}
