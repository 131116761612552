import {
  Dialog,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
  Text,
} from "@fluentui/react-components";
import { Stack } from "@fluentui/react";
import { useState, useEffect } from "react";
import { useAuthenticationContext } from "../../AuthenticationService/useAuthentication";
import { FaWifi } from "react-icons/fa";

interface INetworkNotifier {
  children: any;
}

export function NetworkNotifier({ children }: INetworkNotifier) {
  const { connectionAuthenticated } = useAuthenticationContext();

  const [isConnected, setIsConnected] = useState(false);

  const handleSocketConnected = () => {
    setOpen(false);
    setIsConnected(true);
  };

  const handleSocketError = () => {
    setOpen(true);
    setIsConnected(false);
  };

  useEffect(() => {
    if (connectionAuthenticated) {
      setOpen(false);
    }
  }, [connectionAuthenticated]);

  useEffect(() => {
    window.addEventListener("socket-connected", handleSocketConnected);
    window.addEventListener("socket-event-error", handleSocketError);
  }, []);

  const [open, setOpen] = useState(false);
  return (
    <>
      <Dialog
        open={open}
        onOpenChange={(event, data) => setOpen(data.open)}
        modalType="alert"
      >
        <DialogSurface>
          <DialogBody>
            <Stack
              horizontal
              horizontalAlign="space-between"
              style={{ width: "190%" }}
            >
              <Stack.Item>
                <DialogTitle>Network Error</DialogTitle>
              </Stack.Item>
              <Stack.Item>
                <FaWifi
                  size={20}
                  style={{
                    color: isConnected ? "green" : "red",
                  }}
                />
              </Stack.Item>
            </Stack>
            <DialogContent>
              Seems like the network connection to our services has been lost.
              Please check your network connection and try again.
              <br />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => window.location.reload()}
                appearance="primary"
              >
                Reload Page
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
      {children}
    </>
  );
}
