import { Stack, PersonaSize, Persona } from "@fluentui/react";
import {
  Button,
  Text,
  MenuTrigger,
  MenuPopover,
  MenuList,
  MenuItem,
  Menu as FMenu,
} from "@fluentui/react-components";
import { LineHorizontal320Regular } from "@fluentui/react-icons";
import ChipIcon from "../../assets/chipIcon.svg";
import { useNavigate } from "react-router-dom";
import { useAuthenticationContext } from "../../AuthenticationService/useAuthentication";

export function Menu() {
  const navigate = useNavigate();
  const { logOut } = useAuthenticationContext();
  return (
    <Stack horizontal horizontalAlign="center" verticalAlign="center">
      <Stack.Item style={{ marginRight: "-18px" }}>
        <Persona size={PersonaSize.size40} imageUrl={ChipIcon} />
      </Stack.Item>
      <Stack.Item style={{ marginTop: "0vh" }}>
        <Text size={300} weight="bold">
          BuddyBot
        </Text>
      </Stack.Item>
      <Stack.Item
        style={{ marginRight: "2px", marginLeft: "10px", cursor: "pointer" }}
      >
        <FMenu>
          <MenuTrigger disableButtonEnhancement>
            <Button
              appearance="transparent"
              icon={<LineHorizontal320Regular />}
            />
          </MenuTrigger>

          <MenuPopover>
            <MenuList>
              <MenuItem onClick={() => navigate("/tab")}>Chat View</MenuItem>
              <MenuItem onClick={logOut}>Log off</MenuItem>
            </MenuList>
          </MenuPopover>
        </FMenu>
      </Stack.Item>
    </Stack>
  );
}
