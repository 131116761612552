import type { BrandVariants, Theme } from "@fluentui/react-components";
import {
  createDarkTheme,
  createLightTheme,
  themeToTokensObject,
} from "@fluentui/react-components";

export const mainTheme: BrandVariants = {
  10: "#020305",
  20: "#0F1920",
  30: "#142937",
  40: "#16364A",
  50: "#16435D",
  60: "#145172",
  70: "#0F5F86",
  80: "#036D9C",
  90: "#2D7AA7",
  100: "#4A88B1",
  110: "#6295BA",
  120: "#79A3C3",
  130: "#8EB1CC",
  140: "#A3BFD6",
  150: "#B8CDDF",
  160: "#CCDBE8",
};

export const lightTheme: Theme = { ...createLightTheme(mainTheme) };

export const darkTheme: Theme = { ...createDarkTheme(mainTheme) };

export const customLightTheme: Theme & {
  mainAppBackground: string;
  messageUserBackgroundColor: string;
  messageBotAndMenuBackground: string;
  chatFooterTextBackground: string;
  selectedConversationBackground: string;
  infoPaneButtonColor: string;
  stackWrapperbackgroundColor: string;
  drawerSidebarIcons: string;
  drawerLeftTextColor: string;
  drawerRightTextColor: string;
  drawerBackground: string;
  drawerInsideColor: string;
  vibrantBlue: string;
} = {
  ...lightTheme,
  mainAppBackground: "#E6F1F7",
  messageUserBackgroundColor: "#B3D4E6",
  messageBotAndMenuBackground: "#FFFFFF",
  chatFooterTextBackground: "#FFFFFF",
  selectedConversationBackground: "#B3D4E6",
  infoPaneButtonColor: "#0070AD",
  stackWrapperbackgroundColor:
    "linear-gradient(180deg, rgba(239, 239, 239, 0.6) 0%, rgba(239, 239, 239, 0.08) 100%),linear-gradient(180deg, rgba(239, 239, 239, 0.6) 0%, rgba(239, 239, 239, 0.08) 100%)",
  drawerSidebarIcons: "#FFFFFF",
  drawerLeftTextColor: "#4D4D4D",
  drawerRightTextColor: "#333333",
  drawerBackground:
    "linear-gradient(180deg, rgba(239, 239, 239, 0.60) 0%, rgba(239, 239, 239, 0.08) 100%))",
  drawerInsideColor: "#FFFFFF",
  vibrantBlue: "#0070AD;",
};

export const customDarkTheme: Theme & {
  mainAppBackground: string;
  messageUserBackgroundColor: string;
  messageBotAndMenuBackground: string;
  chatFooterTextBackground: string;
  selectedConversationBackground: string;
  infoPaneButtonColor: string;
  stackWrapperbackgroundColor: string;
  drawerSidebarIcons: string;
  drawerLeftTextColor: string;
  drawerRightTextColor: string;
  drawerBackground: string;
  drawerInsideColor: string;
  vibrantBlue: string;
} = {
  ...darkTheme,
  mainAppBackground:
    "linear-gradient(282.45deg, #272936 0%, #3D3E4A 72.36%, #0C2736 98.53%)",
  messageUserBackgroundColor: "#00649B",
  messageBotAndMenuBackground: "#272936",
  chatFooterTextBackground: "#3D3E4A",
  selectedConversationBackground: "#272936",
  infoPaneButtonColor: "#FFFFFF",
  stackWrapperbackgroundColor:
    "linear-gradient(180deg, rgba(104, 105, 114, 0.26) 0%, #272936 100%),linear-gradient(180deg, rgba(104, 105, 114, 0.6) 0%, rgba(39, 41, 54, 0.08) 100%)",
  drawerSidebarIcons: "#686972",
  drawerLeftTextColor: "#FFFFFF",
  drawerRightTextColor: "#FFFFFF",
  drawerBackground:
    "linear-gradient(180deg, rgba(104, 105, 114, 0.26) 0%, #272936 100%))",
  drawerInsideColor: "#272936",
  vibrantBlue: "#12ABDB",
};

const mergedThemes = { ...customDarkTheme, ...customLightTheme };

darkTheme.colorBrandForeground1 = mainTheme[110];
darkTheme.colorBrandForeground2 = mainTheme[120];

export const customTokens = themeToTokensObject(mergedThemes);
