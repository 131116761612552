import * as React from "react";
import { Stack, Persona, PersonaSize } from "@fluentui/react";
import { Text, Button } from "@fluentui/react-components";
import { customAvatarStyles } from "./index";
import ChipIcon from "../../assets/chipIcon.svg";
import { LineHorizontal320Regular } from "@fluentui/react-icons";
import { useAppClient } from "../services/appClient";

export const CustomAvatar: React.FC = () => {
  const styles = customAvatarStyles();
  const { isSidebarCollapsed, setIsSidebarCollapsed } = useAppClient();

  return (
    <Stack
      horizontal
      horizontalAlign="center"
      verticalAlign="center"
      tokens={{ childrenGap: 0 }}
      className={styles.rootStack}
    >
      <Stack.Item style={{ marginRight: "2px", cursor: "pointer" }}>
        <Button
          className="ms-hiddenLgDown"
          appearance="transparent"
          icon={<LineHorizontal320Regular />}
          onClick={() => {
            setIsSidebarCollapsed(!isSidebarCollapsed);
          }}
        />
        <Button
          className="ms-hiddenXlUp"
          appearance="transparent"
          icon={<LineHorizontal320Regular />}
        />
      </Stack.Item>
      <Stack.Item style={{ marginRight: "-18px" }}>
        <Persona size={PersonaSize.size40} imageUrl={ChipIcon} />
      </Stack.Item>
      <Stack.Item style={{ marginTop: "0vh" }}>
        <Text size={300} weight="bold">
          BuddyBot
        </Text>
      </Stack.Item>
    </Stack>
  );
};
