import { Button, Text } from "@fluentui/react-components";
import { ChatMessageBot } from "./MessageBot";
import { ChatMessage } from "./interfaces";

function CallMonitorMessageButtons() {
  const item: ChatMessage = {
    botMessage: true,
    created: `${new Date().toISOString().slice(0, 19).replace("T", " ")}`,
    id: "1",
    received: true,
    type: "CALL_INTENT_BUTTONS",
    value: "Would you like me to help you with that?",
  };

  return <ChatMessageBot index={1} item={item} />;
}

export { CallMonitorMessageButtons };
