import { useEffect, useState } from "react";
import { Stack, Image, Persona, PersonaSize } from "@fluentui/react";
import ChipIcon from "../../../assets/chipIcon.svg";
import { I } from "./interfaces";
import { Button, Text } from "@fluentui/react-components";
import {
  ThumbLike20Regular,
  ThumbDislike20Regular,
} from "@fluentui/react-icons";
import { messageBotStyles } from "./TemplateStyles";
import { UPDATE_EXCHANGE, AI_REPLY, GET_MESSAGES } from "../../helpers/graphql";
import { useMutation } from "@apollo/client";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useAppClient } from "../../services/appClient";
import { Message } from "../../../__generated__/graphql";
import { ulid } from "ulid";
import { MessageType } from "../../../__generated__/graphql";
import { useAuthenticationContext } from "../../../AuthenticationService/useAuthentication";
import { getTicketSysClassName } from "../../helpers/tokenFunctions";
import { tick } from "@apollo/client/testing";
import { customTokens } from "../../../theme";

export function ChatMessageBot({ index, item }: I) {
  const [updateExchange] = useMutation(UPDATE_EXCHANGE);

  const { user } = useAuthenticationContext();
  const {
    selectedConversationId,
    setConversationsProccessed,
    conversationsProccessed,
    currentConversationMessages,
    setCurrentConversationMessages,
    setChatLoading,
    fontSizeValue,
    updateConversationList,
    setConversationTokens,
    setIsScrolling,
    displayLoadingBubbleMessage,
  } = useAppClient();

  const botStyles = messageBotStyles();
  const [voted, setVoted] = useState(
    item?.exchange?.evaluation !== 0 ? true : false
  );

  function handleSendMessage(vote: number) {
    updateExchange({
      variables: {
        evaluation: vote,
        id: item?.exchange?.id,
      },
    });
    setVoted(true);
  }

  let stringValueO: string = item.value
    .replace("```markdown\n", "")
    .replace("\n```", "")
    .trim();

  const [stringValue, setStringValue] = useState(stringValueO);
  const [ticketNumber, setTicketNumber] = useState<string | null>(null);

  const snowUrl = user.client.clientSettings.openTicketUrl;
  useEffect(() => {
    const ticketN = stringValue.match(/(INC\d{7})/g);
    if (ticketN) {
      setTicketNumber(ticketN[0]);
      const ticketSysClassName = getTicketSysClassName(ticketN[0]);
      setStringValue(stringValue.replace(ticketN[0], ``));
      // setStringValue(
      //   stringValue.replace(
      //     ticketN[0],
      //     `[${ticketN[0]}](${snowUrl
      //       .replace("{ticket_table}", ticketSysClassName)
      //       .replace("{ticket_number}", ticketN[0])}})`
      //   )
      // );

      // setTicketUrl(
      //   `${snowUrl
      //     .replace("{ticket_table}", ticketSysClassName)
      //     .replace("{ticket_number}", ticketN[0])}`
      // );
    }
  }, []);

  const timestampSize: any = fontSizeValue > 200 ? fontSizeValue - 100 : 100;

  const [aiReply] = useMutation(AI_REPLY, {
    onError: (error) => {
      setConversationsProccessed([]);
      setConversationTokens("");
    },
  });

  function handleSend(message: string, noString: boolean = false) {
    if (!message) {
      return;
    }
    const newMessage: Message = {
      botMessage: false,
      created: `${new Date().toISOString().slice(0, 19).replace("T", " ")}`,
      id: ulid(),
      received: false,
      type: MessageType.Message,
      value: message,
      kbArticleInstances: [],
      exchange: null,
      conversation: {
        id: selectedConversationId,
      },
    };

    setCurrentConversationMessages([
      ...currentConversationMessages,
      ...[newMessage],
    ]);

    if (noString) {
      return;
    }

    setChatLoading(true);
    setIsScrolling(true);

    aiReply({
      variables: {
        conversationId: selectedConversationId,
        message: message,
      },
      onCompleted: ({ aiReply }) => {
        let processed = conversationsProccessed.filter(
          (s: string) => s !== aiReply.conversation.id
        );
        displayLoadingBubbleMessage(aiReply.type);

        setChatLoading(false);
        setCurrentConversationMessages([
          ...currentConversationMessages,
          ...[newMessage],
          ...[aiReply],
        ]);

        setConversationsProccessed(processed);
        setIsScrolling(true);
        setConversationTokens("");
        updateConversationList({
          variables: { userId: user.id, feDeleted: false },
          fetchPolicy: "cache-and-network",
        });
      },
      // update: (cache, { data }) => {
      //   const aiReplyData: any = cache.readQuery({
      //     query: GET_MESSAGES,
      //     variables: {
      //       conversationId: selectedConversationId,
      //       // conversationUserId: user.id,
      //     },
      //   });

      //   if (aiReplyData) {
      //     cache.writeQuery({
      //       query: GET_MESSAGES,
      //       variables: {
      //         conversationId: selectedConversationId,
      //       },
      //       data: {
      //         user: {
      //           conversations: [
      //             {
      //               id: selectedConversationId,
      //               messages: [
      //                 ...aiReplyData.user.conversations[0].messages,
      //                 ...[newMessage],
      //                 ...[data.aiReply],
      //               ],
      //             },
      //           ],
      //         },
      //       },
      //     });
      //   }
      // },
    });
    setConversationsProccessed([
      ...conversationsProccessed,
      selectedConversationId,
    ]);
    // setMessage("");
  }

  return (
    <Stack
      horizontal
      tokens={{ childrenGap: "3" }}
      // key={item.id}
      className={botStyles.root}
      // style={{ fontSize: "0.2rem" }}
    >
      <Persona size={PersonaSize.size32} imageUrl={ChipIcon} />
      <Stack.Item className={botStyles.rootStackItem}>
        <Stack
          horizontalAlign="space-between"
          tokens={{ childrenGap: "1" }}
          className={botStyles.stackItemStack}
        >
          <Stack horizontal horizontalAlign="end">
            {item.created && (
              <Text size={timestampSize}>
                {!item.created.includes(".")
                  ? item.created.replace("T", " ")
                  : item.created
                      .replace("T", " ")
                      .substring(0, item.created.indexOf("."))}
              </Text>
            )}
          </Stack>
        </Stack>
        {item.value && (
          <Stack className={botStyles.textStack} horizontal>
            <Text
              size={fontSizeValue}
              className={botStyles.text}
              style={{ display: "inline" }}
            >
              {/* <span style={{ display: "flex" }}> */}
              {!ticketNumber && (
                <Markdown remarkPlugins={[remarkGfm]}>{stringValue}</Markdown>
              )}
              {ticketNumber && (
                <p>
                  {stringValue}
                  <a
                    style={{ color: customTokens.drawerRightTextColor }}
                    href={snowUrl
                      .replace(
                        "{ticket_table}",
                        getTicketSysClassName(ticketNumber)
                      )
                      .replace("{ticket_number}", ticketNumber)}
                    target="_blank"
                  >
                    {ticketNumber}
                  </a>
                </p>
              )}
              {/* </span> */}
            </Text>
          </Stack>
        )}
        {item.type === "RESPONSE" && (
          <div>
            {!voted ? (
              <Stack
                horizontal
                horizontalAlign="end"
                tokens={{ childrenGap: "1" }}
                className={botStyles.scoreStackRoot}
              >
                <Text size={200}>Does that answer your question?</Text>
                <div
                  style={{ marginLeft: ".5rem" }}
                  onClick={() => handleSendMessage(10)}
                  className={"thumb_button"}
                  data-testId="bot-message-thumb-up"
                >
                  <ThumbLike20Regular className={botStyles.thumb} />
                </div>
                <div
                  style={{ marginLeft: ".5rem" }}
                  onClick={() => handleSendMessage(1)}
                  className={"thumb_button"}
                  data-testId="bot-message-thumb-down"
                >
                  <ThumbDislike20Regular className={botStyles.thumb} />
                </div>
              </Stack>
            ) : (
              <Stack horizontalAlign="end" style={{ margin: ".5rem" }}>
                <Text>Thank you for your vote!</Text>
              </Stack>
            )}
          </div>
        )}

        {item.type === "CALL_INTENT_BUTTONS" && (
          <Stack horizontal horizontalAlign="end">
            <Button
              size="small"
              appearance="outline"
              style={{ marginBottom: 5, marginLeft: 10, marginRight: 3 }}
              onClick={() => handleSend("Yes, I need assistance")}
            >
              Yes
            </Button>
            {/* <Button
              size="small"
              appearance="outline"
              style={{ marginBottom: 5, marginLeft: 3, marginRight: 10 }}
              onClick={() => handleSend("No, I don't need assistance", true)}
            >
              No
            </Button> */}
          </Stack>
        )}
      </Stack.Item>
    </Stack>
  );
}
