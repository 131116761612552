import { Stack } from "@fluentui/react";
import { Text, Persona } from "@fluentui/react-components";
import { I } from "./interfaces";
import KBAChip from "./KBAChip";
import { tokens } from "@fluentui/react-theme";

export function ChatMessageValidationForm({ index, item, conversation_id }: I) {
  const kbaArticles = item.kbArticleInstances?.map((kba: any) => {
    return (
      <KBAChip
        kb={kba.kbarticle?.number}
        kbArticleInstanceId={kba.id}
        kbArticleId={kba.kbarticle?.id}
      />
    );
  });

  return (
    <>
      {item.kbArticleInstances?.length && (
        <Stack
          horizontal
          tokens={{ childrenGap: 10 }}
          styles={{
            root: {
              width: "100%",
              marginBottom: "5px",
              maxWidth: "80vw",
              minWidth: "100px",
            },
          }}
        >
          <Persona />
          <div
            style={{
              backgroundColor: tokens.colorNeutralBackground3Selected,
              minWidth: "100px",
              maxWidth: "60vw",
              marginBottom: "1px",
              padding: "3px",
              borderRadius: "10px",
              marginLeft: "3px",
              lineHeight: "2",
              letterSpacing: "tighter",
            }}
          >
            <Stack
              horizontal
              tokens={{ childrenGap: "1" }}
              styles={{
                root: {
                  margin: "4px",
                  marginRight: "10px",
                  marginLeft: "10px",
                  lineHeight: "2",
                },
              }}
            >
              <Stack styles={{ root: { width: "100%" } }}></Stack>
            </Stack>
            {item.value ? <Text size={300}>{item.value}</Text> : null}
            <Stack horizontal wrap>
              {kbaArticles}
            </Stack>
          </div>
        </Stack>
      )}
    </>
  );
}
