import { Stack } from "@fluentui/react";
import { Text } from "@fluentui/react-components";
import { statisticsStyles } from "./index";
import { GET_AGENT_STATISTICS } from "../helpers/graphql";
import { useQuery } from "@apollo/client";

function StatStackItem({
  description,
  value,
  backgroundColor,
}: {
  description: string;
  value: string | number;
  backgroundColor?: string;
}) {
  const styles = statisticsStyles();
  return (
    <Stack.Item
      style={{ backgroundColor: backgroundColor }}
      className={styles.statStackItem}
    >
      <Stack verticalAlign="start" horizontalAlign="center">
        <Text size={300} className={styles.statStackItemDescription}>
          {description}
        </Text>
        <Text size={700} className={styles.statStatItemValue}>
          {value}
        </Text>
      </Stack>
    </Stack.Item>
  );
}

interface I {
  callsHandled?: number;
  callsOffered?: number;
  GOS?: number;
  GOSTotal?: number;
  callsHandledAll?: number;
  callsOfferedAll?: number;
}

export const SideBarStats: React.FC<I> = ({
  callsHandled = 12,
  callsHandledAll = 167,
  callsOffered = 14,
  callsOfferedAll = 143,
  GOS = 89.1,
  GOSTotal = 98.4,
}: I) => {
  const styles = statisticsStyles();

  const { data, loading, error } = useQuery(GET_AGENT_STATISTICS, {
    fetchPolicy: "network-only",
  });

  return (
    <Stack verticalAlign="center" className={styles.statsStack}>
      <Stack
        horizontal
        horizontalAlign="center"
        verticalAlign="center"
        className={styles.statGroupItem}
      >
        <StatStackItem
          description="Calls handled"
          value={data ? data?.agentStatistics?.numberOfCalls : "-"}
          backgroundColor="#2ea657"
        />
        <StatStackItem
          description="AHT"
          value={data ? data?.agentStatistics?.aht : "-"}
          backgroundColor="#ff8e12"
        />
      </Stack>
      <Stack
        horizontal
        horizontalAlign="center"
        verticalAlign="center"
        className={styles.statGroupItem}
      >
        <StatStackItem
          description="User ratio"
          value={data ? data?.agentStatistics?.recognizedUserRatio : "-"}
          backgroundColor="#ff304d"
        />
        <StatStackItem
          description="Tickets created"
          value={data ? data?.agentStatistics?.ticketsCreatedByAgent : "-"}
          backgroundColor="#2ea657"
        />
      </Stack>
      <Stack
        horizontal
        horizontalAlign="center"
        verticalAlign="center"
        className={styles.statGroupItem}
      >
        <StatStackItem
          description="Tickets updated"
          value={data ? data?.agentStatistics?.ticketsUpdatedByAgent : "-"}
          backgroundColor="#ff8e12"
        />
        {/* <StatStackItem
          description="Calls offered all"
          value={callsOfferedAll}
          backgroundColor="#2ea657"
        /> */}
      </Stack>
    </Stack>
  );
};
