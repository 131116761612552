import { tokens } from "@fluentui/react-components";
import { makeStyles, shorthands, mergeClasses } from "@griffel/react";
import { customTokens } from "../../theme";

export const chatViewStyles = makeStyles({
  root: {
    height: "100%",
  },
  rootStack: {
    width: "100%",
    height: "auto",
    maxHeight: "88vh",
    overflowY: "scroll",
    overflowX: "hidden",
    ...shorthands.padding("10px"),
    display: "flex",
    flexDirection: "column",
    marginBottom: "10px",
  },
});

export const chatFooterStyles = makeStyles({
  noDisplay: {
    display: "none",
  },
  rootStack: {
    paddingLeft: "8",
    paddingRight: "8",
    width: "100%",
  },
  justifyStack: {
    justifyContent: "flex-end",
  },

  textFieldStack: {
    alignSelf: "flex-end",
    marginRight: "10",
    // ...shorthands.borderRadius("5px"),
    paddingRight: "28",
    width: "70vw",
    maxWidth: "650px",
  },

  textField: {
    alignSelf: "flex-end",
    marginRight: "10",
    ...shorthands.borderRadius("5px"),
    paddingRight: "28",
    width: "70vw",
    maxWidth: "650px",
    backgroundColor: customTokens.chatFooterTextBackground,
  },
});

export const chatMessagesStyles = makeStyles({
  messageRootStack: {
    textAlign: "center",
    alignContent: "space-around",
  },
  chatIcon: {
    marginLeft: "auto",
    marginRight: "auto",
    // marginTop: "5px",
  },
  buddyBotMainText: {
    paddingTop: "5px",
    fontWeight: "700",
    lineHeight: "20px",
  },
  buddyBotSecondaryText: {
    paddingTop: "5px",
    fontWeight: "300",
  },
});
