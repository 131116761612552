import { useState } from "react";
import { Stack } from "@fluentui/react";
import { Text, Persona } from "@fluentui/react-components";
import { tokens } from "@fluentui/react-theme";
import ChipIcon from "../../../assets/chipIcon.svg";

import { I } from "./interfaces";

export function ChatMessageDebug({ index, item, conversation_id, type }: I) {
  const [scoreSent, setScoreSent] = useState(false);

  return (
    <Stack
      horizontal
      tokens={{ childrenGap: "3" }}
      key={index}
      styles={{
        root: {
          width: "100%",
          marginBottom: "5px",
          maxWidth: "80vw",
          minWidth: "100px",
        },
      }}
    >
      <Persona size="medium" avatar={ChipIcon} />
      <Stack.Item
        styles={{
          root: {
            backgroundColor: tokens.colorNeutralBackground3Selected,
            // color: "black",
            minWidth: "100px",
            maxWidth: "60vw",
            marginY: "1",
            padding: "3",
            borderRadius: "10px",
            marginLeft: "3",
            lineHeight: "1.5",
            letterSpacing: "tighter",
          },
        }}
      >
        <Stack
          horizontal
          tokens={{ childrenGap: "1" }}
          styles={{
            root: {
              margin: "4px",
              marginRight: "10px",
              marginLeft: "10px",
              lineHeight: "2",
              width: "auto",
            },
          }}
        >
          <Stack styles={{ root: { width: "100%" } }}>
            <Stack horizontal horizontalAlign="start">
              <Text size={200}>con: {conversation_id}</Text>
            </Stack>
            <Stack horizontal horizontalAlign="end">
              {item.created ? <Text size={200}>{item.created}</Text> : null}
            </Stack>
          </Stack>
        </Stack>
        {item.value && (
          <Stack styles={{ root: { textAlign: "left" } }}>
            <Text
              //   as="samp"
              style={{
                lineHeight: "2",
                margin: "0",
                marginRight: ".5rem",
                marginLeft: ".5rem",
              }}
            >
              {item.value}
            </Text>
          </Stack>
        )}
      </Stack.Item>
    </Stack>
  );
}
