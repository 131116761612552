import { useState } from "react";
import { Stack } from "@fluentui/react";
import { SideBarMenu } from "./SidebarMenu";
import { RightPanelDisplay } from "./RightPanelDisplay";
import { StackWrapper } from "../../../GeneralComponents/StackWrapper";

interface Message {
  id: string;
  value: string;
}

export interface IVotedResponse {
  botAction: string;
  created: string;
  disabled: boolean;
  response: Message;
  upvoted: boolean;
  id: string;
  tags: string[];
}

export function VotedResponsesDisplay({
  votedResponses,
}: {
  votedResponses: IVotedResponse[];
}) {
  const [activeVotedResponse, setActiveVotedResponse] =
    useState<IVotedResponse>(votedResponses[0]);

  return (
    <Stack horizontal horizontalAlign="stretch">
      <Stack.Item style={{ width: "auto", maxWidth: "30vw" }} grow={0}>
        <StackWrapper opacity={0}>
          <SideBarMenu
            votedResponses={votedResponses}
            setActiveVotedResponse={setActiveVotedResponse}
            activeVotedResponse={activeVotedResponse}
          />
        </StackWrapper>
      </Stack.Item>
      <Stack.Item grow={2} style={{ width: "auto" }}>
        {votedResponses.length !== 0 ? (
          <RightPanelDisplay
            votedResponse={activeVotedResponse}
            setActiveVotedResponse={setActiveVotedResponse}
          />
        ) : (
          <Stack
            horizontal
            horizontalAlign="center"
            style={{ marginTop: "30px" }}
          >
            <h3>There are no voted responses</h3>
          </Stack>
        )}
      </Stack.Item>
    </Stack>
  );
}
