import React, { FormEvent, useState, useEffect } from "react";
import { Stack, mergeStyles } from "@fluentui/react";
import {
  CardHeader,
  Card,
  Input as TextField,
  Label,
  Button as PrimaryButton,
  useId,
} from "@fluentui/react-components";

import {
  Toaster,
  useToastController,
  Toast,
  ToastTitle,
  ToastBody,
} from "@fluentui/react-toast";
import { ClipLoader } from "react-spinners";
import { useMutation } from "@apollo/client";
import { LOGIN_DATABASE, AZURE_LOGIN } from "./helpers/graphql";
import { customTokens } from "../theme";
import { isInTeams } from "./helpers/tokenFunctions";
import { app, authentication } from "@microsoft/teams-js";
import { LoadingUser } from "../GeneralComponents/LoadingUserComponent";
import { useAuthenticationContext } from "../AuthenticationService/useAuthentication";
import { useMsal, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { BsMicrosoft } from "react-icons/bs";

const DatabaseLogin: React.FC = () => {
  const { setConnectionAuthenticated } = useAuthenticationContext();

  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const handleRedirect = () => {
    instance.loginRedirect({
      ...loginRequest,
      prompt: "create",
    });
  };

  // handleRedirect();
  useEffect(() => {
    try {
      (async () => {
        if (activeAccount) {
          setLoggingIn(true);
          const { data: tokenData } = await azureLoginMutation({
            variables: {
              credential: activeAccount.idToken,
              username: activeAccount.username,
            },
          });
          if (tokenData) {
            localStorage.setItem("token", tokenData.azureLogin.accessToken);
            localStorage.setItem(
              "refreshToken",
              tokenData.azureLogin.refreshToken
            );
            setConnectionAuthenticated(true);
            setLoggingIn(false);
          } else {
            setLoggingIn(false);
            notifyError();
          }
          const error = () => {
            setLoggingIn(false);
            notifyError();
          };
        } else {
          setLoggingIn(false);
        }
      })();
    } catch {
      setLoggingIn(false);
      notifyError();
    }
  }, [activeAccount]);

  const inTeams = isInTeams();
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loggingIn, setLoggingIn] = useState<boolean>(false);

  const [login] = useMutation(LOGIN_DATABASE, {
    variables: { username, password },
    onCompleted: ({ login }) => {
      localStorage.setItem("token", login.accessToken);
      localStorage.setItem("refreshToken", login.refreshToken);
      setConnectionAuthenticated(true);
    },
    onError: (error) => {
      setLoggingIn(false);
      notifyError();
    },
  });

  const toasterId = useId("toaster-login");
  const { dispatchToast } = useToastController(toasterId);
  const notifyError = () =>
    dispatchToast(
      <Toast>
        <ToastTitle>Login Failed</ToastTitle>
        <ToastBody>Username or password incorrect</ToastBody>
      </Toast>,
      { intent: "error" }
    );

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoggingIn(true);
    login();
  };

  const [azureLoginMutation] = useMutation(AZURE_LOGIN, {
    onError: (error) => {
      console.log(
        "Azure Authentication Failed ( DatabaseLoginComponent )",
        error
      );
    },
  });

  useEffect(() => {
    try {
      (async () => {
        if (inTeams) {
          setLoggingIn(true);
          app.initialize();
          const successCallback = async (token: any) => {
            const userPrincipalName = (await app.getContext()).user
              ?.userPrincipalName;
            const { data: tokenData } = await azureLoginMutation({
              variables: { credential: token, username: userPrincipalName },
            });
            if (tokenData) {
              localStorage.setItem("token", tokenData.azureLogin.accessToken);
              localStorage.setItem(
                "refreshToken",
                tokenData.azureLogin.refreshToken
              );
              setConnectionAuthenticated(true);
              setLoggingIn(false);
            } else {
              setLoggingIn(false);
              notifyError();
            }
          };
          const error = () => {
            setLoggingIn(false);
            notifyError();
          };
          authentication.getAuthToken({
            successCallback: successCallback,
            failureCallback: error,
          });
        } else {
          setLoggingIn(false);
        }
      })();
    } catch {
      setLoggingIn(false);
      notifyError();
    }
  }, []);

  const container = mergeStyles({
    display: "flex",
    width: "100vw",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    background: customTokens.mainAppBackground,
  });

  const loginBox = mergeStyles({
    background: "#fff",
    borderRadius: "0.5rem",
    boxShadow: "0 0 8px",
    maxWidth: "400px",
    width: "100%",
  });

  const heading = mergeStyles({
    fontSize: "1.5rem",
    fontWeight: 600,
    color: customTokens.vibrantBlue,
    marginBottom: "1rem",
  });

  if (loggingIn) return <LoadingUser />;

  return (
    <Stack
      className={container}
      verticalAlign="center"
      horizontalAlign="center"
    >
      <Card
        className={`${loginBox} drawerStyles`}
        style={{ background: customTokens.drawerBackground }}
      >
        <form onSubmit={handleSubmit}>
          <Stack tokens={{ childrenGap: 10 }} style={{ padding: 20 }}>
            <CardHeader header={<h1 className={heading}>BuddyBot</h1>} />
            <Label htmlFor="username">Username:</Label>
            <TextField
              id="username"
              disabled={loggingIn}
              value={username}
              style={{ backgroundColor: customTokens.chatFooterTextBackground }}
              onChange={(_, newValue) => setUsername(newValue.value || "")}
            />
            <Label htmlFor="password">Password:</Label>
            <TextField
              id="password"
              disabled={loggingIn}
              type="password"
              value={password}
              style={{ backgroundColor: customTokens.chatFooterTextBackground }}
              onChange={(_, newValue) => setPassword(newValue.value || "")}
            />
            <PrimaryButton
              data-testid="login-submit"
              appearance="primary"
              style={{
                marginTop: "20px",
                background: customTokens.vibrantBlue,
              }}
              type="submit"
            >
              {loggingIn ? <ClipLoader color="white" size={19} /> : "Login"}
            </PrimaryButton>
          </Stack>
        </form>

        {!inTeams && (
          <PrimaryButton
            data-testid="login-azure-submit"
            appearance="primary"
            style={{
              width: "90%",
              marginLeft: "5%",
              background: customTokens.vibrantBlue,
            }}
            onClick={handleRedirect}
          >
            <BsMicrosoft size={17} style={{ marginRight: "20px" }} /> Login with
            Microsoft
          </PrimaryButton>
        )}
      </Card>
      <Toaster toasterId={toasterId} limit={10} />
    </Stack>
  );
};

export default DatabaseLogin;
