import { useQuery } from "@apollo/client";
import { GET_CLIENT_KBA_AND_VOTED_RESPONSES } from "./network/graphql";
import { LoadingUser } from "../GeneralComponents/LoadingUserComponent";
import { Stack } from "@fluentui/react";
import {
  TabList,
  Tab,
  TabValue,
  SelectTabEvent,
  SelectTabData,
} from "@fluentui/react-components";
import { Menu, VotedResponsesDisplay, KbaDisplay } from "./components";
import { StackWrapper } from "../GeneralComponents/StackWrapper";
import { useState } from "react";
import { useAuthenticationContext } from "../AuthenticationService/useAuthentication";
import { customTokens } from "../theme";
import { FileUpload } from "./components/FileUpload/FileUpload";
import { kbaFiles, tickets, insights } from "./components/constants";
import { KbaFileDisplay } from "./components/KbaFiles/KbaFileDisplay";

enum TabOptionValues {
  KBA = "kbarticles",
  KBA_FILES = "kbfiles",
  VOTED_RESPONSES = "votedresponses",
  TICKETS = "tickets",
  INSIGHTS = "insights",
  FILE_UPLOAD = "fileupload",
}

export default function KbaManagement() {
  const { user, userLoading, connectionAuthenticated } =
    useAuthenticationContext();
  const { loading, error, data } = useQuery(
    GET_CLIENT_KBA_AND_VOTED_RESPONSES,
    {
      skip: !user || userLoading || !connectionAuthenticated,
    }
  );
  const [selectedValue, setSelectedValue] = useState<TabValue>(
    TabOptionValues.KBA
  );

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
  };
  if (loading || !user || userLoading || !connectionAuthenticated)
    return <LoadingUser />;
  if (error) return <p>Error :</p>;

  return (
    <Stack
      style={{
        minHeight: "100vh",
        minWidth: "100vw",
        background: customTokens.mainAppBackground,
        overflowX: "hidden",
      }}
    >
      <Stack.Item>
        <StackWrapper opacity={0}>
          <Stack horizontal horizontalAlign="space-between">
            <Stack.Item>
              <Menu />
            </Stack.Item>
            <Stack.Item>
              <TabList selectedValue={selectedValue} onTabSelect={onTabSelect}>
                <Tab
                  value={TabOptionValues.KBA_FILES}
                  id={TabOptionValues.KBA_FILES}
                >
                  Knowledge Base Files
                </Tab>
                <Tab value={TabOptionValues.KBA} id={TabOptionValues.KBA}>
                  Knowledge Base Articles
                </Tab>
                <Tab
                  value={TabOptionValues.VOTED_RESPONSES}
                  id={TabOptionValues.VOTED_RESPONSES}
                >
                  Voted Responses
                </Tab>
                <Tab
                  value={TabOptionValues.TICKETS}
                  id={TabOptionValues.TICKETS}
                >
                  Tickets
                </Tab>
                <Tab
                  value={TabOptionValues.INSIGHTS}
                  id={TabOptionValues.INSIGHTS}
                >
                  Insights
                </Tab>
                {/* <Tab
                  value={TabOptionValues.FILE_UPLOAD}
                  id={TabOptionValues.FILE_UPLOAD}
                >
                  File Upload
                </Tab> */}
              </TabList>
            </Stack.Item>
            <Stack.Item>
              <></>
            </Stack.Item>
          </Stack>
        </StackWrapper>
      </Stack.Item>
      <Stack.Item style={{ height: "50vh" }}>
        {selectedValue === TabOptionValues.KBA_FILES && (
          <KbaFileDisplay kbaFiles={kbaFiles} />
        )}
        {selectedValue === TabOptionValues.KBA && (
          <KbaDisplay kbaArticles={data.user.client.kbArticles} />
        )}
        {selectedValue === TabOptionValues.VOTED_RESPONSES && (
          <VotedResponsesDisplay
            votedResponses={data.user.client.votedResponses}
          />
        )}
        {selectedValue === TabOptionValues.FILE_UPLOAD && <FileUpload />}
        {selectedValue === TabOptionValues.TICKETS && (
          <KbaFileDisplay kbaFiles={tickets} />
        )}
        {selectedValue === TabOptionValues.INSIGHTS && (
          <KbaFileDisplay kbaFiles={insights} />
        )}
      </Stack.Item>
    </Stack>
  );
}
