import { useAuthenticationContext } from "./useAuthentication";
import { LoadingUser } from "../GeneralComponents/LoadingUserComponent";
import { ErrorBoundaryComponent } from "../GeneralComponents/ErrorBoundary";
import DatabaseLogin from "../AgentChatModule/DatabaseLogin";

export function AuthenticationWrapper({ children }: { children: any }) {
  const { token, connectionAuthenticated, userError, refreshToken } =
    useAuthenticationContext();

  if (userError) return token ? <LoadingUser /> : <ErrorBoundaryComponent />;
  if (!connectionAuthenticated && !refreshToken) return <DatabaseLogin />;

  return <>{children}</>;
}
