import React, { useEffect, useRef, useState } from "react";
import { Stack, Persona, PersonaSize } from "@fluentui/react";
import ChipIcon from "../../assets/chipIcon.svg";
import { Text } from "@fluentui/react-components";
import {
  ChatMessageBot,
  ChatMessageUser,
  ChatMessageLoading,
  ChatMessageDebug,
  ChatMessageValidationForm,
  ChatMessageIntent,
  chatMessagesStyles,
} from "./index";
import { useAppClient } from "../services/appClient";
import { CallMonitorMessageButtons } from "./MessageTemplates";

interface M {
  height?: string;
}
export enum MessageTypes {
  MESSAGE = "MESSAGE",
  RESPONSE = "RESPONSE",
  VALIDATION = "VALIDATION",
  START = "START",
  THOUGHT = "THOUGHT",
  FINISH = "FINISH",
  INTENT = "INTENT",
  AI_CALL_INTENT = "AI_CALL_INTENT",
  DEBUG = "DEBUG",
  ERROR = "ERROR",
  INTENT_DATA = "INTENT_DATA",
  OBSERVATION = "OBSERVATION",
  SEARCH_RESULT = "SEARCH_RESULT",
  ACTION = "ACTION",
  START_CALL_INTENT = "START_CALL_INTENT",
  CALL_END = "CALL_END",
}

export const Messages = ({ height }: M) => {
  const AlwaysScrollToBottom = () => {
    const elementRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      if (isScrolling) {
        elementRef?.current?.scrollIntoView();
        setTimeout(() => {
          setIsScrolling(false);
        }, 100);
      }
    }, []);
    return <div ref={elementRef} />;
  };

  const styles = chatMessagesStyles();

  const {
    selectedConversationId,
    conversationsProccessed,
    debugMode,
    validationMode,
    messages,
    setIsScrolling,
    isScrolling,
    callIntentLoading,
    chatLoading: messagesLoading,
  } = useAppClient();

  useEffect(() => {
    setTimeout(() => {
      setIsScrolling(true);
    }, 100);
  }, []);

  if (messages || messagesLoading) {
    console.log("messages", messages);
    return (
      <Stack
        className={styles.messageRootStack}
        style={{
          height: height,
          // bottom: "120",
        }}
      >
        {messages.map((item: any, index: any) => {
          switch (item.type) {
            case MessageTypes.MESSAGE:
              if (!item.botMessage) {
                return (
                  <ChatMessageUser
                    key={item.id}
                    index={index}
                    item={item}
                    conversation_id={item.conversationId}
                  />
                );
              }
              if (item.botMessage) {
                return (
                  <ChatMessageBot
                    key={item.id}
                    index={index}
                    item={item}
                    conversation_id={item.conversationId}
                  />
                );
              } else {
                return null;
              }
            case MessageTypes.RESPONSE:
              if (validationMode) {
                return (
                  <>
                    <ChatMessageBot
                      key={item.id}
                      index={index}
                      item={item}
                      conversation_id={item.conversationId}
                    />

                    <ChatMessageValidationForm index={index} item={item} />
                  </>
                );
              } else {
                return (
                  <ChatMessageBot
                    key={item.id}
                    index={index}
                    item={item}
                    conversation_id={item.conversationId}
                  />
                );
              }
            case MessageTypes.CALL_END:
              return (
                <ChatMessageBot
                  key={item.id}
                  index={index}
                  item={item}
                  conversation_id={item.conversationId}
                />
              );

            case MessageTypes.INTENT:
              return (
                <ChatMessageIntent
                  key={item.id}
                  index={index}
                  item={item}
                  conversationId={item.conversationId}
                />
              );

            case MessageTypes.AI_CALL_INTENT:
              return (
                <React.Fragment>
                  <ChatMessageBot
                    key={item.id}
                    index={index}
                    item={item}
                    conversation_id={item.conversationId}
                  />
                  {/* Should appear only of this message is the last one in chain */}
                  {messages[messages.length - 1].id == item.id && (
                    <CallMonitorMessageButtons />
                  )}
                </React.Fragment>
              );

            case debugMode && MessageTypes.VALIDATION:
              return (
                <ChatMessageDebug
                  key={item.id}
                  index={index}
                  item={item}
                  conversation_id={item.conversationId}
                />
              );
            case debugMode && MessageTypes.START:
              return (
                <ChatMessageDebug
                  key={item.id}
                  index={index}
                  item={item}
                  conversation_id={item.conversationId}
                />
              );
            case debugMode && MessageTypes.THOUGHT:
              return (
                <ChatMessageDebug
                  key={item.id}
                  index={index}
                  item={item}
                  conversation_id={item.conversationId}
                />
              );
            case debugMode && MessageTypes.FINISH:
              return (
                <ChatMessageDebug
                  key={item.id}
                  index={index}
                  item={item}
                  conversation_id={item.conversationId}
                />
              );

            case debugMode && MessageTypes.DEBUG:
              return (
                <ChatMessageDebug
                  key={item.id}
                  index={index}
                  item={item}
                  conversation_id={item.conversationId}
                />
              );
            case debugMode && MessageTypes.ERROR:
              return (
                <ChatMessageDebug
                  key={item.id}
                  index={index}
                  item={item}
                  conversation_id={item.conversationId}
                />
              );

            case debugMode && MessageTypes.INTENT_DATA:
              return (
                <ChatMessageDebug
                  key={item.id}
                  index={index}
                  item={item}
                  conversation_id={item.conversationId}
                />
              );

            case debugMode && MessageTypes.OBSERVATION:
              return (
                <ChatMessageDebug
                  key={item.id}
                  index={index}
                  item={item}
                  conversation_id={item.conversationId}
                />
              );
            case debugMode && MessageTypes.SEARCH_RESULT:
              return (
                <ChatMessageDebug
                  key={item.id}
                  index={index}
                  item={item}
                  conversation_id={item.conversationId}
                />
              );
          }
        })}

        {messagesLoading &&
          !callIntentLoading &&
          conversationsProccessed.includes(selectedConversationId) && (
            <>
              <ChatMessageLoading />
            </>
          )}

        {callIntentLoading &&
          !messagesLoading &&
          conversationsProccessed.includes(selectedConversationId) && (
            <ChatMessageLoading />
          )}

        <AlwaysScrollToBottom />

        {!messages?.length &&
          !conversationsProccessed.includes(selectedConversationId) &&
          !callIntentLoading && <WelcomeMessage height={height} />}
      </Stack>
    );
  } else {
    return <WelcomeMessage height={height} />;
  }
};

function WelcomeMessage(height?: any) {
  const styles = chatMessagesStyles();
  return (
    <>
      <Stack
        verticalAlign="center"
        tokens={{ childrenGap: 10, padding: 10 }}
        className={styles.messageRootStack}
        style={{
          maxHeight: height,
        }}
      >
        <div className={styles.chatIcon}>
          <Persona size={PersonaSize.size48} imageUrl={ChipIcon} />
        </div>
        <Text
          size={500}
          weight="bold"
          align="center"
          // className={styles.buddyBotMainText}
          // block
        >
          Welcome to BuddyBot
        </Text>
        <Text
          align="center"
          size={400}

          // className={styles.buddyBotSecondaryText}
          // block
        >
          Your AI-powered ServiceDesk companion
        </Text>
      </Stack>
    </>
  );
}

export default Messages;
