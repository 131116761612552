import { useSubscription, useMutation } from "@apollo/client";
import {
  NEW_TOKEN,
  NEW_MESSAGE,
  NEW_CALL,
  SEND_TEAMS_ACTIVITY_NOTIFICATION,
  NEW_RINGING,
} from "./helpers/graphql";
import { useAppClient } from "./services/appClient";
import { MessageTypes } from "./ChatView";
import { app } from "@microsoft/teams-js";
import chipIcon from "../assets/chipIcon.svg";
import { isInTeams } from "./helpers/tokenFunctions";
import { useAuthenticationContext } from "../AuthenticationService/useAuthentication";

export default function Subscriptions({ children }: { children: any }) {
  const [sendTeamsNotification] = useMutation(SEND_TEAMS_ACTIVITY_NOTIFICATION);

  const inTeams = isInTeams();

  const {
    user,
    userLoading,
    connectionAuthenticated,
    refreshTokenMutationIsLoading,
  } = useAuthenticationContext();

  const {
    selectedConversationId,
    setConversationTokens,
    setIsScrolling,
    displayLoadingBubbleMessage,
    messages,
    setCurrentConversationMessages,
    currentConversationMessages,
    setChatLoading,
    setCallIntentLoading,
    setConversationsProccessed,
    conversationsProccessed,
    identifyCaller,
    updateConversationList,
    setSelectedConversationId,
    resetDrawer,
    getLatestTickets,
  } = useAppClient();

  useSubscription(NEW_TOKEN, {
    onData: ({ data }) => {
      if (data.data.newToken.conversationId === selectedConversationId) {
        setConversationTokens(
          (conversationTokens: string) =>
            conversationTokens + data.data.newToken.token
        );
        setIsScrolling(true);
      }
    },
    shouldResubscribe: true,
    skip:
      !selectedConversationId ||
      !user ||
      userLoading ||
      !connectionAuthenticated ||
      refreshTokenMutationIsLoading,
  });

  useSubscription(NEW_MESSAGE, {
    onData: async ({ data }) => {
      if (data.data.newMessage.conversation.id === selectedConversationId) {
        displayLoadingBubbleMessage(data.data.newMessage.type);
        if (
          !messages.length &&
          [MessageTypes.AI_CALL_INTENT].includes(data.data.newMessage.type)
        ) {
          setCurrentConversationMessages([
            ...currentConversationMessages,
            ...[data.data.newMessage],
          ]);
        } else if (
          messages.length &&
          messages[messages.length - 1].value?.trim() !==
            data.data.newMessage.value.trim()
        ) {
          setCurrentConversationMessages([
            ...currentConversationMessages,
            ...[data.data.newMessage],
          ]);
          setIsScrolling(true);
          if ([MessageTypes.RESPONSE].includes(data.data.newMessage.type)) {
            setChatLoading(false);
          }
        }
        if ([MessageTypes.AI_CALL_INTENT].includes(data.data.newMessage.type)) {
          setCallIntentLoading(false);
          let processed = conversationsProccessed.filter(
            (s: string) => s !== data.data.newMessage.conversation.id
          );
          setConversationsProccessed(processed);
        }
      }
    },
    shouldResubscribe: true,
    skip:
      !selectedConversationId ||
      !user ||
      userLoading ||
      !connectionAuthenticated ||
      refreshTokenMutationIsLoading,
  });

  useSubscription(NEW_CALL, {
    onData: ({ data }) => {
      if (data.data.newCall.callId !== "x") {
        identifyCaller({
          variables: {
            callId: data.data.newCall.callId,
          },
          onCompleted: async (callerData: any) => {
            await updateConversationList({
              variables: { feDeleted: false },
            });
            setSelectedConversationId(data.data.newCall.conversation);
            resetDrawer();
            displayLoadingBubbleMessage(MessageTypes.START_CALL_INTENT);
            setTimeout(() => {
              setCallIntentLoading(true);
            }, 100);
            setConversationsProccessed([
              ...conversationsProccessed,
              data.data.newCall.conversation,
            ]);

            getLatestTickets({
              variables: {
                userId: callerData.identifyCaller.sysId,
                callId: data.data.newCall.callId,
              },
            });
          },
          onError: () => {
            try {
              updateConversationList({
                variables: { userId: user.id, feDeleted: false },
                fetchPolicy: "cache-and-network",
              });
              setSelectedConversationId(data.data.newCall.conversation);
              resetDrawer();
              displayLoadingBubbleMessage(MessageTypes.START_CALL_INTENT);
              setTimeout(() => {
                setCallIntentLoading(true);
              }, 100);
            } catch {
              console.log("failed");
            }
          },
        });
      }
    },
    shouldResubscribe: true,
    skip:
      !user ||
      userLoading ||
      !connectionAuthenticated ||
      refreshTokenMutationIsLoading,
  });

  async function notifyUser(message: string) {
    if (inTeams) {
      app.initialize();
      const userId = (await app.getContext()).user?.id;
      sendTeamsNotification({
        variables: {
          message: message,
          userId: userId,
        },
      });
    }
    if (!inTeams) {
      let notification = new Notification("New Call", {
        icon: chipIcon,
        body: message,
      });
      notification.onclick = () => {
        window.focus();
        notification.close();
      };
    }
  }

  useSubscription(NEW_RINGING, {
    async onData({ data }) {
      if (data.data.newRinging.callId) {
        let message = `Incoming call from ${data?.data?.newRinging?.callerDetails?.firstName} ${data?.data?.newRinging?.callerDetails?.lastName}`;
        await notifyUser(message);
      }
    },
    shouldResubscribe: true,
    skip:
      !user ||
      userLoading ||
      !connectionAuthenticated ||
      refreshTokenMutationIsLoading,
  });

  return <>{children}</>;
}
