import { useContext } from "react";
import { AppClientContext } from "./AppClientContext";

export const useAppClient = () => {
  const context = useContext(AppClientContext);
  if (!context) {
    throw Error("You forgot to wrap in context ( AppClientContext )");
  }
  return context;
};
