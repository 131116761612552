import { useMemo, useState, useEffect } from "react";
import {
  AuthenticationContext,
  IAuthenticationContext,
} from "./AuthenticationContext";
import { getTokenExpiryDate } from "../AgentChatModule/helpers/tokenFunctions";
import { isExpired } from "react-jwt";
import { useMutation, useQuery, useApolloClient } from "@apollo/client";
import { REFRESH_TOKEN, GET_USER } from "../AgentChatModule/helpers/graphql";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import { isInTeams } from "../AgentChatModule/helpers/tokenFunctions";
import { FluentProvider } from "@fluentui/react-components";
import { customLightTheme, customDarkTheme } from "../theme";

export const AuthenticationProvider = ({ children }: { children: any }) => {
  const apolloClient = useApolloClient();

  const { themeString } = useTeamsUserCredential({
    initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL!,
    clientId: process.env.REACT_APP_CLIENT_ID!,
  });

  const inTeams = isInTeams();

  const initialUseDarkMode: any = localStorage.getItem("useDarkMode");
  const initialUseDarkModeParsed = JSON.parse(initialUseDarkMode);
  const [useDarkMode, setUseDarkMode] = useState<boolean | any>(
    initialUseDarkModeParsed
  );
  const initialToken: any = localStorage.getItem("token");
  const initalRefreshToken: any = localStorage.getItem("refreshToken");

  const [token, setToken] = useState<string | undefined>(initialToken);
  const [refreshToken, setRefreshToken] = useState<string | undefined>(
    initalRefreshToken
  );

  const [connectionAuthenticated, setConnectionAuthenticated] =
    useState<boolean>(false);
  const {
    data: user,
    loading: userLoading,
    error: userError,
  } = useQuery(GET_USER, {
    fetchPolicy: "cache-and-network",
    onError: (error) => {},
    onCompleted: (data) => {
      console.log(data);
    },
    skip: !connectionAuthenticated,
  });

  const [refreshTokenMutation, { loading: refreshTokenMutationIsLoading }] =
    useMutation(REFRESH_TOKEN);

  const logOut = () => {
    // Reset Mutations on logout
    // resetAiAssistData(); // THIS IS A BREAKING CHANGE IN THE DRAWERS
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    setRefreshToken(undefined);
    setToken(undefined);
    setConnectionAuthenticated(false);
    localStorage.clear();
    sessionStorage.clear();
    document.cookie.split(";").forEach(function (c) {
      document.cookie = c.trim().startsWith("msal")
        ? c.trim() + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;"
        : c;
    });
  };
  function socketConnected() {
    setConnectionAuthenticated(false);
    let refresh_token: string | null = localStorage.getItem("refreshToken");

    const shouldLogin =
      !refresh_token || (refresh_token && isExpired(refresh_token));

    if (shouldLogin) {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
    } else {
      refreshTokenMutation({
        variables: {
          refreshToken: refresh_token,
        },
        onCompleted: (data: any) => {
          localStorage.setItem("token", data.refreshToken.accessToken);
          localStorage.setItem("refreshToken", data.refreshToken.refreshToken);
          setConnectionAuthenticated(true);
        },
        onError: (error: any) => {
          logOut();
        },
      });
    }
  }

  function handleStorageChange(event: any) {
    if (event.key === "refreshToken") {
      const updatedValue = event.newValue;
      const oldValue = event.oldValue;
      if (
        (oldValue && !updatedValue) ||
        (oldValue && updatedValue && isExpired(updatedValue))
      ) {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        logOut();
      }
    }
  }

  useEffect(() => {
    window.addEventListener("socket-connected", socketConnected);
    window.addEventListener("storage", handleStorageChange);
  }, []);

  useEffect(() => {
    let refresh_token: string | null = localStorage.getItem("refreshToken");
    const expiryDate = getTokenExpiryDate(refresh_token);
    const date = new Date(expiryDate * 1000);
    const dateNow = new Date();
    const diff = date.getTime() - dateNow.getTime();
    const timeoutTime = diff - 30000;

    setTimeout(() => {
      logOut();
    }, timeoutTime);
  }, []);

  useEffect(() => {
    const tck = window.location.href.includes("refreshToken=")
      ? window.location.href.split("refreshToken=").pop()
      : null;
    if (tck) {
      setTimeout(() => {
        localStorage.setItem("refreshToken", tck);
        setToken(tck);
        window.location.replace(window.location.origin);
      }, 10);
    }
  }, []);

  const value: IAuthenticationContext = useMemo(
    () => ({
      token,
      setToken,
      refreshToken,
      setRefreshToken,
      connectionAuthenticated,
      setConnectionAuthenticated,
      logOut,
      user: user?.user,
      userLoading,
      userError,
      useDarkMode,
      setUseDarkMode,
      refreshTokenMutationIsLoading,
    }),
    [
      token,
      refreshToken,
      connectionAuthenticated,
      userLoading,
      user,
      userError,
      useDarkMode,
      refreshTokenMutationIsLoading,
    ]
  );

  return (
    <AuthenticationContext.Provider value={value}>
      <FluentProvider
        theme={
          inTeams
            ? themeString === "dark" || themeString === "contrast"
              ? customDarkTheme
              : customLightTheme
            : useDarkMode
            ? customDarkTheme
            : customLightTheme
        }
      >
        {children}
      </FluentProvider>
    </AuthenticationContext.Provider>
  );
};
