import { Stack } from "@fluentui/react";
import { tokens } from "@fluentui/react-theme";
import { customTokens } from "../theme";

interface IStackWrapper {
  height?: string | number;
  minHeight?: string | number;
  maxHeight?: string | number;
  opacity?: string | number;
  boxShadow?: boolean;
  children: any;
  display?: string;
}

export function StackWrapper({
  height,
  minHeight,
  maxHeight,
  opacity = 1,
  boxShadow = true,
  children,
  display = "flex",
}: IStackWrapper) {
  return (
    <Stack
      styles={{
        root: {
          color: tokens.colorNeutralForeground1,
          // opacity: opacity,
          height: height,
          minHeight: minHeight,
          width: "auto",
          maxHeight: maxHeight ? maxHeight : "95vh",
          display: display,
          padding: "6px",
          borderRadius: "10px",
          margin: "0.5em",
          background: `${customTokens.stackWrapperbackgroundColor} ${opacity}`,
          // backgroundColor: `rgba(${tokens.colorNeutralBackground1}, ${opacity})`,
          // backgroundColor: tokens.colorNeutralBackground1,
          border: boxShadow ? ".5px solid #0000001a" : "",
          boxShadow: boxShadow
            ? "-4px 4px 8px rgba(0, 0, 0, 0.05), 4px 4px 8px rgba(0, 0, 0, 0.05)"
            : "",
        },
      }}
    >
      {children}
    </Stack>
  );
}
