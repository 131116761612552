import {
  Drawer,
  DrawerHeader,
  DrawerHeaderTitle,
  DrawerBody,
} from "@fluentui/react-drawer";
import { Button, Divider } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { useAppClient } from "../services/appClient";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { SetStateAction, Dispatch } from "react";
import { ERightDrawerState } from "../services/appClient/AppClientContext";
import { DrawerContact } from "../AcrPane/DrawerComponents/DrawerContact";
import { TicketDrawer } from "../AcrPane/DrawerComponents/TicketDrawer";
import { AiAssistDrawer } from "../AcrPane/DrawerComponents/AiAssistDrawer";
import { customTokens } from "../../theme";

interface IOverlayClientDrawer {
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
}

export function OverlayClientDrawer({ setIsDrawerOpen }: IOverlayClientDrawer) {
  const { width } = useWindowDimensions();
  const { setRightDrawerState, rightDrawerState } = useAppClient();
  return (
    <Drawer
      modalType="non-modal"
      type={width > 1240 ? "inline" : "overlay"}
      size="medium"
      position="right"
      open={rightDrawerState != ERightDrawerState.CLOSED}
      className={width < 1240 ? "drawerStyles" : ""}
      style={{
        height: width > 1240 ? "95vh" : "100vh",
        width: width > 1240 ? "21vw" : "40vw",
        background:
          width > 1240
            ? customTokens.drawerBackground
            : customTokens.drawerBackground,
      }}
    >
      <DrawerHeader
        style={{
          marginTop: "-20px",
          paddingBottom: "-20px",
          marginBottom: "-10px",
          marginLeft: "-6px",
          borderBottom: customTokens.drawerRightTextColor,
        }}
      >
        <DrawerHeaderTitle
          action={
            <Button
              appearance="subtle"
              aria-label="Close"
              data-testId="overlay-drawer-close-button"
              icon={<Dismiss24Regular />}
              onClick={() => {
                setRightDrawerState(ERightDrawerState.CLOSED);
                setIsDrawerOpen(false);
              }}
            />
          }
        >
          {(rightDrawerState == ERightDrawerState.CALLER && "User Details") ||
            (rightDrawerState == ERightDrawerState.ASSIST && "AI Assist") ||
            (rightDrawerState == ERightDrawerState.TICKETS && "Tickets")}
        </DrawerHeaderTitle>
        <Divider style={{ marginTop: "-5px" }} />
      </DrawerHeader>

      <DrawerBody
        style={{
          background: customTokens.drawerBackground,
          padding: 10,
          margin: 0,
          overflowX: "hidden",
        }}
      >
        {rightDrawerState == ERightDrawerState.CALLER ? (
          <DrawerContact />
        ) : null}
        {rightDrawerState == ERightDrawerState.TICKETS ? (
          <TicketDrawer />
        ) : null}
        {rightDrawerState == ERightDrawerState.ASSIST ? (
          <AiAssistDrawer setIsDrawerOpen={setIsDrawerOpen} />
        ) : null}
      </DrawerBody>
    </Drawer>
  );
}
