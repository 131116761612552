import { Stack } from "@fluentui/react";
import { PropagateLoader } from "react-spinners";
import { Text } from "@fluentui/react-components";

export function LoadingUser() {
  return (
    <Stack
      style={{ width: "100vw", height: "100vh" }}
      verticalAlign="center"
      horizontalAlign="center"
    >
      <Stack.Item>
        <PropagateLoader color="#36d7b7" size={18} />
      </Stack.Item>
      <Stack.Item>
        <br />
        <br />
        <Text style={{ marginTop: "20px" }}>
          Preparing your data, hold on a while.
        </Text>
      </Stack.Item>
    </Stack>
  );
}
