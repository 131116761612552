import { StackWrapper } from "../../../GeneralComponents/StackWrapper";
import { SideBarMenu } from "./SideBarMenu";
import { MainDisplay } from "./MainDisplay";
import { Stack } from "@fluentui/react";
import { useState } from "react";

export interface IKbaArticle {
  id: string;
  content: string;
  forDeletion: boolean;
  forReview: boolean;
  instances: any[];
  number: string;
  questions: string;
  shortDescription: string;
  tags: string[];
}

interface IKbaDisplay {
  kbaArticles: IKbaArticle[];
}

export function KbaDisplay({ kbaArticles }: IKbaDisplay) {
  const [activeArticle, setActiveArticle] = useState<IKbaArticle>(
    kbaArticles[0]
  );

  return (
    <Stack horizontal verticalAlign="center">
      <Stack.Item style={{ width: "auto" }} grow={0}>
        <StackWrapper opacity={0} height={"85vh"}>
          <SideBarMenu
            kbaArticles={kbaArticles}
            setActiveArticle={setActiveArticle}
            activeArticle={activeArticle}
          />
        </StackWrapper>
      </Stack.Item>
      <Stack.Item grow={2} style={{ width: "auto" }}>
        <MainDisplay kbaArticle={activeArticle} setArticle={setActiveArticle} />
      </Stack.Item>
    </Stack>
  );
}
