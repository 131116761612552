import { Stack, Image } from "@fluentui/react";
import { Text, tokens } from "@fluentui/react-components";
import { animated, useSpring } from "@react-spring/web";
import { messageUserStyles } from "./TemplateStyles";
import { useAppClient } from "../../services/appClient";

export interface ChatMessage {
  botMessage: boolean;
  created: string;
  id: string;
  received: boolean;
  type: string;
  value: string;
}

export interface I {
  index: number;
  item: ChatMessage;
  conversation_id?: string;
}

export function ChatMessageUser({ index, item, conversation_id }: I) {
  const { fontSizeValue } = useAppClient();

  const timestampSize: any = fontSizeValue > 200 ? fontSizeValue - 100 : 100;

  const springs = useSpring({
    from: { y: 5 },
    to: { y: 0 },
  });

  const messageStyles = messageUserStyles();

  return (
    <animated.div style={{ ...springs }}>
      <Stack horizontal horizontalAlign="end" className={messageStyles.root}>
        <Stack
          horizontal
          horizontalAlign="end"
          className={messageStyles.rootStack}
        >
          <Stack>
            <Stack horizontal horizontalAlign="end">
              {item.created ? (
                <Text
                  size={timestampSize}
                  className={messageStyles.timeStampText}
                >
                  {!item.created.includes(".")
                    ? item.created.replace("T", " ")
                    : item.created
                        .replace("T", " ")
                        .substring(0, item.created.indexOf("."))}
                </Text>
              ) : null}
            </Stack>
            {item.value && (
              <Stack style={{ marginTop: "-0.5em" }}>
                <Text className={messageStyles.textStack} size={fontSizeValue}>
                  <pre className={messageStyles.txtPre}>{item.value}</pre>
                </Text>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </animated.div>
  );
}
