import { gql } from "@apollo/client";

// GET User ( ID and Client ID )

export const GET_CONVERSATION = gql`
  query Conversation($id: String!) {
    user {
      id
      conversations(id: $id) {
        textBoxData @client
        callEngagement {
          id
          call {
            id
            callId
            callerPin
            callStartDate
            gate
            callerPhoneNumber
            transcripts {
              id
            }
          }
        }
        id
        messages {
          id
          botMessage
          value
          created
          type
          kbArticleInstances {
            id
            kbarticle {
              id
              number
            }
          }
        }
      }
    }
  }
`;

export const GET_USER = gql`
  query User {
    user {
      id
      username
      client {
        id
        name
        clientSettings {
          openTicketUrl
          kbaUrl
        }
        templates {
          sysId
          name
        }
      }
    }
  }
`;

// Create New conversation

export const CREATE_CONVERSATION = gql`
  mutation CreateConversation($client: String, $user: String) {
    createConversation(client: $client, user: $user) {
      conversation {
        id
        name
        evaluation
        started
        feDeleted
      }
    }
  }
`;

export const SEND_TEAMS_ACTIVITY_NOTIFICATION = gql`
  mutation SendTeamsActivityFeedNotification(
    $userId: String!
    $message: String!
  ) {
    sendTeamsActivityFeedNotification(userId: $userId, message: $message) {
      ok
    }
  }
`;

export const SET_UPVOTE_CACHE = gql`
  mutation UserSessionUpvoteCache($enabled: Boolean!) {
    userSessionUpvoteCache(enabled: $enabled)
  }
`;

export const SET_DOWNVOTE_CACHE = gql`
  mutation UserSessionDownvoteCache($enabled: Boolean!) {
    userSessionDownvoteCache(enabled: $enabled)
  }
`;

// TO FIX

// export const GET_CATEGORIES = gql`
//   query Categories($client: String!) {
//     categories(client: $client) {
//       id
//       enabled
//       template
//       templateSysId
//     }
//   }
// `;

export const CALL_INTENT = gql`
  query CallIntent($callId: String!) {
    callIntent(callId: $callId) {
      configurationItem
      issue
    }
  }
`;

export const GET_CATEGORIES = gql`
  query Categories {
    user {
      client {
        categories {
          id
          enabled
          template
          templateSysId
        }
      }
    }
  }
`;

export const GET_MESSAGES = gql`
  query Messages($conversationId: String!) {
    user {
      id
      conversations(id: $conversationId) {
        id
        messages {
          id
          type
          value
          botMessage
          exchange {
            id
            evaluation
          }
          value
          created
          kbArticleInstances {
            id
            kbarticle {
              number
            }
          }
        }
      }
    }
  }
`;

export const GET_CONVERSATIONS = gql`
  query Conversations($feDeleted: Boolean) {
    user {
      id
      conversations(feDeleted: $feDeleted) {
        id
        name
        evaluation
        started
        feDeleted
        callEngagement {
          call {
            callId
            transcripts {
              id
            }
          }
        }
      }
    }
  }
`;

// Update message as Recieved

export const UPDATE_MESSAGE = gql`
  mutation UpdateMessage($id: Int!) {
    updateMessage(id: $id) {
      message {
        id
      }
    }
  }
`;

// Working On
export const AI_REPLY = gql`
  mutation AiReply($conversationId: String!, $message: String!) {
    aiReply(conversationId: $conversationId, message: $message) {
      id
      type
      value
      botMessage
      exchange {
        id
        evaluation
      }
      conversation {
        id
      }
      created
      kbArticleInstances {
        id
        kbarticle {
          id
          number
        }
      }
    }
  }
`;

export const UPDATE_KBARTICLE_INSTANCE = gql`
  mutation UpdateKbArticleInstance(
    $id: Int!
    $invalidSource: Boolean
    $validSource: Boolean
  ) {
    updateKbArticleInstance(
      id: $id
      invalidSource: $invalidSource
      validSource: $validSource
    ) {
      kbarticleinstance {
        id
      }
    }
  }
`;

export const UPDATE_KBARTICLE = gql`
  mutation UpdateKbArticle(
    $id: Int!
    $forDeletion: Boolean
    $forReview: Boolean
  ) {
    updateKbArticle(id: $id, forDeletion: $forDeletion, forReview: $forReview) {
      kbarticle {
        id
      }
    }
  }
`;

export const UPDATE_CONVERSATION = gql`
  mutation UpdateConversation(
    $id: String!
    $evaluation: Int
    $name: String
    $feDeleted: Boolean
  ) {
    updateConversation(
      id: $id
      evaluation: $evaluation
      name: $name
      feDeleted: $feDeleted
    ) {
      conversation {
        id
        name
        evaluation
        started
        feDeleted
      }
    }
  }
`;

export const UPDATE_EXCHANGE = gql`
  mutation UpdateExchange($id: String!, $evaluation: Int!) {
    updateExchange(id: $id, evaluation: $evaluation) {
      exchange {
        id
        evaluation
      }
    }
  }
`;

export const NEW_MESSAGE = gql`
  subscription NewMessage {
    newMessage {
      id
      type
      value
      botMessage
      created
      conversation {
        id
      }
      exchange {
        id
        evaluation
      }
    }
  }
`;

export const NEW_TOKEN = gql`
  subscription newToken {
    newToken {
      token
      conversationId
    }
  }
`;

export const TIME_SUBSCRIPTION = gql`
  subscription {
    timeOfDay
  }
`;

export const LOGIN_DATABASE = gql`
  mutation Login($password: String!, $username: String!) {
    login(password: $password, username: $username) {
      accessToken
      refreshToken
    }
  }
`;

export const AZURE_LOGIN = gql`
  mutation AzureLogin($credential: String!, $username: String!) {
    azureLogin(credential: $credential, username: $username) {
      accessToken
      refreshToken
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`;

export const AI_ASSIST = gql`
  mutation AiAssist($conversationId: String!) {
    aiAssist(conversationId: $conversationId) {
      conversationTranscript
      id
      created
      earlyIntent
      predictedTemplates {
        id
        template
        templateSysId
        weight
      }
      intent
      summary
      conversationTranscript
      template {
        id
        template
        templateSysId
      }
      kbaExtract {
        template
      }
    }
  }
`;

export const CREATE_AI_WORK_NOTE_TEXT = gql`
  mutation CreateAiWorkNoteText($callEngagementId: String!) {
    createAiWorkNoteText(callEngagementId: $callEngagementId)
  }
`;

export const CREATE_TICKET = gql`
  mutation CreateTicket(
    $callerId: String!
    $conversationId: String!
    $template: String!
    $workNote: String!
    $transcriptId: String!
  ) {
    createTicket(
      callerId: $callerId
      conversationId: $conversationId
      template: $template
      workNote: $workNote
      transcriptId: $transcriptId
    ) {
      id
      value
      exchange {
        id
        evaluation
      }
      ticket {
        assignmentGroup
        sysCreatedOn
        sysUpdatedOn
        number
        state
        shortDescription
        description
        sysId
        sysClassName
        openTicketUrl
        commentsAndWorkNotes
      }
    }
  }
`;

export const UPDATE_TICKET = gql`
  mutation UpdateTicket(
    $conversationId: String!
    $number: String!
    $state: String
    $closeCode: String
    $closeNotes: String
    $sysId: String!
    $workNotes: String!
    $onHoldReason: String
  ) {
    updateTicket(
      conversationId: $conversationId
      number: $number
      state: $state
      closeCode: $closeCode
      closeNotes: $closeNotes
      sysId: $sysId
      workNotes: $workNotes
      onHoldReason: $onHoldReason
    ) {
      id
      value
      botMessage
      created
      exchange {
        id
        evaluation
      }
      type
    }
  }
`;
export const NEW_CALL = gql`
  subscription NewCall {
    newCall {
      callId
      callStartDate
      conversation
      user
      gate
      callerPhoneNumber
      servicePhoneNumber
      callerPin
      customerName
      eventType
    }
  }
`;

export const NEW_RINGING = gql`
  subscription NewRinging {
    newRinging {
      callId
      callerDetails {
        firstName
        lastName
        sysId
      }
    }
  }
`;

export const LATEST_TICKETS = gql`
  query LatestTickets($userId: String!, $callId: String!) {
    latestTickets(userId: $userId, callId: $callId) {
      assignmentGroup
      sysCreatedOn
      sysUpdatedOn
      number
      state
      shortDescription
      description
      sysId
      sysClassName
      openTicketUrl
      commentsAndWorkNotes
    }
  }
`;

export const IDENTIFY_CALLER = gql`
  query IdentifyCaller($callId: String!) {
    identifyCaller(callId: $callId) {
      sysId
      firstName
      lastName
      userName
      email
      attributes {
        key
        value
      }
    }
  }
`;

export const GET_AGENT_STATISTICS = gql`
  query AgentStatistics {
    agentStatistics {
      aht
      numberOfCalls
      recognizedUserRatio
      ticketsCreatedByAgent
      ticketsUpdatedByAgent
    }
  }
`;

export const USER_SESSION_QUESTION_MATCHING = gql`
  mutation UserSessionQuestionMatching($enabled: Boolean!) {
    userSessionQuestionMatching(enabled: $enabled)
  }
`;
