import { makeStyles, shorthands, mergeClasses } from "@griffel/react";
import { tokens } from "@fluentui/react-components";
import { customTokens } from "../../../theme";
// Cannot recieve a ACR card from BE ?

export const DrawerStyles = makeStyles({
  // TicketValue
  textArea: {
    marginTop: "5px",
    height: "150px",
    ...shorthands.borderRadius("4px"),
    color: customTokens.drawerRightTextColor,
    // borderBottom: `1px solid blue`,
    width: "100%",
    backgroundColor: customTokens.drawerInsideColor,
  },
  textAreaTicket: {
    width: "100%",
    boxSizing: "border-box",
    ...shorthands.padding("10px"),
    minHeight: "150px",
    resize: "vertical",
    color: customTokens.drawerRightTextColor,
    backgroundColor: customTokens.drawerInsideColor,
    ...shorthands.borderRadius("4px"),
  },
  textAreaWrapper: {
    position: "relative",
    marginTop: "5px",
    width: "80%",
  },
  textAreaButton: {
    position: "absolute",
    bottom: "5px",
    right: "10px",
  },
  dropdownStyles: {
    width: "100%",
    maxWidth: "100%",
    ...shorthands.borderRadius("8px"),
    boxShadow: "0px 3px 15px rgba(0,0,0,0.2)",
    ...shorthands.border("none"),
  },
});
