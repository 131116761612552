import { makeStyles, shorthands, mergeClasses } from "@griffel/react";

export const drawerSidebarRootStyles = makeStyles({
  rootStack: { width: "80%", height: "100%" },
  //   rootStack: { width: "100%" },
  separatorItem: { width: "100%" },
  conversationsItem: { width: "70%" },
  conversationsStack: {
    height: "65vh",
    // alignSelf: "stretch",
    overflowY: "scroll",
    overflowX: "hidden",
    marginTop: "10px",
    // width: "80%",
    // marginBottom: "10px",
  },
  addButtonStack: {
    justifyContent: "flex-end",
    marginTop: "auto",
    alignSelf: "flex-end",
    marginBottom: "2vh",
    marginRight: "15px",
  },
  tabList: {
    marginLeft: "auto",
    marginRight: "auto",
  },
});
// const useSideBarClasses = makeStyles({});

export const sidebarRootStyles = makeStyles({
  rootStack: { width: "100%", height: "100%" },
  //   rootStack: { width: "100%" },
  separatorItem: { width: "100%" },
  conversationsItem: { width: "100%" },
  conversationsStack: {
    // height: "55vh",
    // alignSelf: "stretch",
    overflowY: "scroll",
    overflowX: "hidden",
    marginTop: "10px",
    marginBottom: "10px",
  },
  addButtonStack: {
    justifyContent: "flex-end",
    marginTop: "auto",
    alignSelf: "flex-end",
    marginBottom: "2vh",
    marginRight: "15px",
  },
  tabList: {
    marginLeft: "auto",
    marginRight: "auto",
  },
});

export const drawerSidebarConversationItemStyles = makeStyles({
  rootStack: {
    width: "80px",
    // ...shorthands.padding("-20"),
    marginTop: "6px",
    // alignItems: "center",
    // marginBottom: "-1px",
    // ...shorthands.border("solid", "#ccc", "1px"),
  },
});
export const sidebarConversationItemStyles = makeStyles({
  rootStack: {
    // ...shorthands.border("0px !important"),
    width: "auto",
    marginTop: "6px",
    marginRight: "12px",
    alignItems: "left",
    marginBottom: "-1px",
    ...shorthands.border("solid", "#ccc", "1px"),
    fontSize: "12px",
  },
  buttonEditing: {
    minWidth: "12%",
    maxWidth: "12%",
    boxSizing: "border-box",
    ...shorthands.border(0),
    ...shorthands.padding("20px"),
  },
  smallButton: {
    minWidth: "12%",
    maxWidth: "12%",
    boxSizing: "border-box",
    ...shorthands.border("0"),
    ...shorthands.padding("6px"),
    paddingRight: "10px",
  },
  loadingDoubleButton: {
    minWidth: "24%",
    maxWidth: "24%",
    boxSizing: "border-box",
    ...shorthands.border("0"),
    ...shorthands.padding("6px"),
    paddingRight: "20px",
  },
});

export const statisticsStyles = makeStyles({
  statStackItem: {
    ...shorthands.borderRadius("5px"),
    maxWidth: "100px",
    minWidth: "100px",
    height: "84px",
    backgroundColor: "#0078D4",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "5px",
    marginLeft: "5px",
  },
  statGroupItem: {
    marginTop: "10px",
    color: "white",
  },
  statStackItemDescription: {
    fontFamily: "Segoe UI",
    textAlign: "center",
    ...shorthands.margin("1"),
    lineHeight: "1",
    // fontWeight: 600,
    fontSize: "13px",
  },
  statStatItemValue: {
    color: "white",
    marginTop: "5",
    fontSize: "24px",
    fontWeight: 600,
  },
  statsStack: {
    marginTop: "15",
  },
});

export const headerStyles = makeStyles({
  menuStackItem: {
    alignSelf: "flex-start",
    // marginLeft: "45px",
  },
  menuStack: {
    marginTop: "30%",
    marginRight: "5px",
    alignSelf: "flex-end",
  },
  promptOveride: {
    width: "205%",
    marginLeft: "auto",
    marginRight: "auto",
  },
});

export const draweStyles = makeStyles({
  root: { height: "100vh" },
});

export const customAvatarStyles = makeStyles({
  rootStack: {
    marginTop: "10px",
    marginLeft: "5px",
    marginBottom: "10px",
  },
});
