// import { KbaFile } from "./KbaDisplay";
import { KbaFile } from "../constants";
import { Stack } from "@fluentui/react";
import {
  Text,
  Card,
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Switch,
  Field,
  Avatar,
} from "@fluentui/react-components";

import { Tag } from "@fluentui/react-tags";

import {
  TagPicker,
  TagPickerList,
  TagPickerInput,
  TagPickerControl,
  TagPickerProps,
  TagPickerOption,
  TagPickerGroup,
} from "@fluentui/react-tag-picker-preview";

import {
  CheckmarkCircle20Regular,
  QuestionCircle20Regular,
  ContentView20Regular,
} from "@fluentui/react-icons";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { customTokens } from "../../../theme";
import { useMutation } from "@apollo/client";
// import { UPDATE_KB_File } from "../../network/graphql";
import { SetStateAction, useState, Dispatch, useEffect } from "react"; // Do we need it ?
import { tags } from "../constants";

export function MainDisplay({
  kbaFile,
  setActiveFile,
}: {
  kbaFile: KbaFile;
  setActiveFile: Dispatch<SetStateAction<KbaFile>>;
}) {
  //   const [updateKbFile] = useMutation(UPDATE_KB_File);

  useEffect(() => {
    setSelectedOptions([...kbaFile.tags]);
  }, [kbaFile]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([
    ...kbaFile.tags,
  ]);
  const onOptionSelect: TagPickerProps["onOptionSelect"] = (e, data) => {
    console.log(data);
    let file = { ...kbaFile };
    file.tags = data.selectedOptions;
    setActiveFile(file);
    setSelectedOptions(data.selectedOptions);
  };
  const tagPickerOptions = tags.filter(
    (tag: string) => !selectedOptions.includes(tag)
  );

  return (
    <Stack
      style={{
        overflowY: "scroll",
        overflowX: "hidden",
        maxHeight: "85vh",
        minHeight: "85vh",
      }}
    >
      <Stack
        horizontal
        horizontalAlign="space-between"
        style={{ marginRight: 15 }}
      >
        <Stack.Item style={{ display: "flex", justifyContent: "center" }}>
          <Card
            size="small"
            style={{
              backgroundColor: customTokens.messageBotAndMenuBackground,
            }}
          >
            <Text size={300} style={{ fontWeight: "bold" }}>
              {kbaFile?.name}
            </Text>
            <Text size={200}>{kbaFile?.shortDescription}</Text>
          </Card>
        </Stack.Item>
        {/* <Stack horizontalAlign="center"> */}
        <Stack.Item>
          <Stack horizontal style={{ marginTop: "4px", marginBottom: "4px" }}>
            <Field label="Edit Tags" style={{ maxWidth: 400 }}>
              <TagPicker
                onOptionSelect={onOptionSelect}
                selectedOptions={selectedOptions}
              >
                <TagPickerControl style={{ background: "transparent" }}>
                  <TagPickerGroup>
                    {selectedOptions.map((option) => (
                      <Tag
                        key={option}
                        shape="circular"
                        media={<Avatar name={option} color="colorful" />}
                        value={option}
                      >
                        {option}
                      </Tag>
                    ))}
                  </TagPickerGroup>
                  <TagPickerInput />
                </TagPickerControl>
                <TagPickerList>
                  {tags
                    .filter((option) => !selectedOptions.includes(option))
                    .map((option) => (
                      <TagPickerOption
                        media={<Avatar name={option} color="colorful" />}
                        value={option}
                        key={option}
                      >
                        {option}
                      </TagPickerOption>
                    ))}
                </TagPickerList>
              </TagPicker>
            </Field>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Field>
            <Switch
              label="Enabled"
              checked={kbaFile?.forDeletion}
              onChange={() => {
                let art = { ...kbaFile };
                art.forDeletion = !art.forDeletion;
                setActiveFile(art);
              }}
            />
          </Field>
          <Field>
            <Switch
              label="For Review"
              checked={kbaFile?.forReview}
              onChange={() => {
                let art = { ...kbaFile };
                art.forReview = !art.forReview;
                setActiveFile(art);
              }}
            />
          </Field>
        </Stack.Item>

        {/* </Stack> */}

        {/* <Stack.Item>
          <Card
            style={{
              backgroundColor: customTokens.messageBotAndMenuBackground,
            }}
          >
            <Text style={{ display: "flex", justifyContent: "space-between" }}>
              Enabled:
              <CheckmarkCircle20Regular
                style={{
                  marginLeft: "20px",
                  color: kbaFile?.forDeletion ? "green" : "red",
                }}
              />
            </Text>
            <Text style={{ display: "flex", justifyContent: "space-between" }}>
              For Review:
              <CheckmarkCircle20Regular
                style={{
                  marginLeft: "20px",
                  color: kbaFile?.forReview ? "green" : "red",
                }}
              />
            </Text>
          </Card>
        </Stack.Item> */}
      </Stack>
      <Accordion multiple collapsible defaultOpenItems={"questions"}>
        <AccordionItem value="questions">
          <AccordionHeader>
            <QuestionCircle20Regular
              style={{ marginRight: "5px", fontWeight: "bold" }}
            />{" "}
            <Text size={400} style={{ fontWeight: "bold" }}>
              KB File Questions
            </Text>
          </AccordionHeader>
          <AccordionPanel>
            <Stack.Item>
              <Stack style={{ marginLeft: "auto", marginRight: "auto" }}>
                <ol>
                  {kbaFile?.questions.map((q: any) => (
                    <li>{q}</li>
                  ))}
                </ol>
                {/* </Markdown> */}
              </Stack>
            </Stack.Item>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem value="content">
          <AccordionHeader>
            {" "}
            <ContentView20Regular style={{ marginRight: "5px" }} />
            <Text size={400} style={{ fontWeight: "bold" }}>
              KB File Content
            </Text>
          </AccordionHeader>
          <AccordionPanel>
            <Stack.Item>
              <Markdown remarkPlugins={[remarkGfm]}>
                {kbaFile?.content}
              </Markdown>
            </Stack.Item>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Stack>
  );
}
