import { Button, Text } from "@fluentui/react-components";
import {
  TriangleUp20Regular,
  TriangleDown20Regular,
  Edit20Regular,
  Delete20Regular,
} from "@fluentui/react-icons";
import { tokens } from "@fluentui/react-theme";
import {
  UPDATE_KBARTICLE_INSTANCE,
  UPDATE_KBARTICLE,
} from "../../helpers/graphql";
import { useMutation } from "@apollo/client";
import { useAuthenticationContext } from "../../../AuthenticationService/useAuthentication";

interface K {
  kb: any;
  kbArticleInstanceId: string;
  kbArticleId: number;
}

export default function KBAChip({ kb, kbArticleInstanceId, kbArticleId }: K) {
  const [updateKbArticleInstance] = useMutation(UPDATE_KBARTICLE_INSTANCE);
  const [updateKbArticle] = useMutation(UPDATE_KBARTICLE);

  const { user } = useAuthenticationContext();
  const url = user?.client?.clientSettings
    ? user.client.clientSettings.kbaUrl
    : null;

  const style = {
    borderRadius: 10,
    height: 30,
  };

  return (
    <div
      style={{
        backgroundColor: tokens.colorNeutralBackground3,
        display: "flex",
        alignItems: "center",
        padding: "4px",
        paddingLeft: "14px",
        borderRadius: "12px",
        margin: "3px",
      }}
    >
      <a
        target="_blank"
        href={url + kb}
        style={{
          textDecoration: "none",
          marginRight: "8px",
          color: tokens.colorNeutralBackgroundInverted,
        }}
      >
        <Text>{kb}</Text>
      </a>
      <Button
        appearance="subtle"
        data-testId="kba-chip-vote-up-button"
        icon={<TriangleUp20Regular />}
        style={style}
        onClick={() => {
          updateKbArticleInstance({
            variables: {
              id: Number(kbArticleInstanceId),
              invalidSource: false,
              validSource: true,
            },
          });
        }}
      />

      <Button
        appearance="subtle"
        data-testId="kba-chip-vote-down-button"
        icon={<TriangleDown20Regular />}
        style={style}
        onClick={() => {
          updateKbArticleInstance({
            variables: {
              id: Number(kbArticleInstanceId),
              invalidSource: true,
              validSource: false,
            },
          });
        }}
      />
      <Button
        appearance="subtle"
        data-testId="kba-chip-edit-button"
        icon={<Edit20Regular />}
        style={style}
        onClick={() => {
          updateKbArticle({
            variables: {
              id: Number(kbArticleId),
              forReview: true,
              forDeletion: false,
            },
          });
        }}
      />
      <Button
        appearance="subtle"
        data-testId="kba-chip-delete-button"
        icon={<Delete20Regular />}
        style={style}
        onClick={() => {
          updateKbArticle({
            variables: {
              id: Number(kbArticleId),
              forReview: false,
              forDeletion: true,
            },
          });
        }}
      />
    </div>
  );
}
