import { IKbaArticle } from "./KbaDisplay";
import { Stack } from "@fluentui/react";
import {
  Input,
  Field,
  CompoundButton,
  Button,
  Switch,
  Menu,
  MenuTrigger,
  Tooltip,
  Avatar,
  MenuButton,
  MenuPopover,
  MenuList,
  MenuItem as FMenuItem,
} from "@fluentui/react-components";
import { Book20Regular, Settings20Regular } from "@fluentui/react-icons";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { customTokens } from "../../../theme";
import { tags } from "../constants";
import { UploadModal } from "../UploadModal";
import { Tag } from "@fluentui/react-tags";

function MenuItem({
  kbaArticle,
  setActiveArticle,
  activeArticle,
}: {
  kbaArticle: IKbaArticle;
  setActiveArticle: Dispatch<SetStateAction<IKbaArticle>>;
  activeArticle: IKbaArticle;
}) {
  const [randomTags, setRandomTags] = useState<string[]>(
    tags.sort(() => 0.5 - Math.random()).slice(0, 3)
  );

  useEffect(() => {
    if (kbaArticle.id === activeArticle.id) {
      setRandomTags(activeArticle.tags);
    }
  }, [activeArticle, setActiveArticle]);

  useEffect(() => {
    let art = { ...kbaArticle };
    art.tags = randomTags;
    if (kbaArticle.id === activeArticle.id) setActiveArticle(art);
  }, []);

  return (
    <Stack style={{ padding: "1px" }}>
      <CompoundButton
        icon={<Book20Regular />}
        appearance={
          activeArticle.id === kbaArticle.id ? "primary" : "transparent"
        }
        style={{
          padding: "1px",
          textAlign: "left",
          alignContent: "flex-start",
          display: "flex",
          justifyContent: "flex-start",
        }}
        size="small"
        secondaryContent={
          <>
            {kbaArticle?.shortDescription?.length >= 25
              ? kbaArticle?.shortDescription.substr(0, 25) + " ..."
              : kbaArticle?.shortDescription}
            <Stack horizontal style={{ paddingTop: "5px" }}>
              {randomTags?.slice(0, 3).map((tag) => (
                <Tag
                  shape="circular"
                  size="small"
                  style={{ margin: "2px" }}
                  color="colorful"
                  media={<Avatar name={tag} color="colorful" />}
                >
                  {tag}
                </Tag>
              ))}
            </Stack>
          </>
        }
        onClick={() => {
          let art = { ...kbaArticle };
          art.tags = randomTags;
          setActiveArticle(art);
        }}
      >
        {kbaArticle.number}
        <br />
      </CompoundButton>
    </Stack>
  );
}

export function SideBarMenu({
  kbaArticles: kbaArticlesOff,
  setActiveArticle,
  activeArticle,
}: {
  kbaArticles: IKbaArticle[];
  setActiveArticle: Dispatch<SetStateAction<IKbaArticle>>;
  activeArticle: IKbaArticle;
}) {
  const [kbaArticles, setKbaArticles] = useState<IKbaArticle[]>(kbaArticlesOff);
  const pageSize = 20;
  const f20 = kbaArticles.slice(0, pageSize);
  // const totalPageCount = Math.ceil(kbaArticles.length / pageSize);
  const [totalPageCount, setTotalPageCount] = useState<number>(
    Math.ceil(kbaArticles.length / pageSize)
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [currentItems, setCurrentItems] = useState(f20);
  const [forDeletionFilter, setForDeletionFilter] = useState(false);
  const [forReviewFilter, setForReviewFilter] = useState(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [allFilter, setAllFilter] = useState<boolean>(true);

  useEffect(() => {
    const articles = kbaArticlesOff.filter(
      (a: IKbaArticle) =>
        a.number.toLowerCase().includes(inputValue.toLowerCase()) ||
        a?.shortDescription?.toLowerCase()?.includes(inputValue.toLowerCase())
    );

    let i = articles.filter(
      (a: IKbaArticle) =>
        a.forDeletion == forDeletionFilter && a.forReview == forReviewFilter
    );

    if (allFilter) i = articles;

    setKbaArticles(i);
    const startPoint = currentPage * pageSize - pageSize;
    const newOnes = i.slice(startPoint, startPoint + pageSize);
    setTotalPageCount(Math.ceil(i.length / pageSize));
    setCurrentItems(newOnes);
  }, [forDeletionFilter, forReviewFilter, allFilter]);

  useEffect(() => {
    const startPoint = currentPage * pageSize - pageSize;
    const newOnes = kbaArticles.slice(startPoint, startPoint + pageSize);
    setCurrentItems(newOnes);
  }, [currentPage]);

  const items = currentItems.map((i: IKbaArticle) => {
    return (
      <MenuItem
        kbaArticle={i}
        key={i.id}
        setActiveArticle={setActiveArticle}
        activeArticle={activeArticle}
      />
    );
  });

  return (
    <Stack style={{ height: "100%" }}>
      <Stack.Item>
        <Stack
          horizontal
          horizontalAlign="space-between"
          style={{ marginBottom: "15px" }}
        >
          <Stack.Item>
            <Field style={{ width: "90%" }}>
              <Input
                placeholder="Filter"
                width={"90%"}
                style={{ background: customTokens.drawerInsideColor }}
                onChange={(e, d) => {
                  setInputValue(d.value);
                  const articles = kbaArticlesOff.filter(
                    (a: IKbaArticle) =>
                      a.number.toLowerCase().includes(d.value.toLowerCase()) ||
                      a?.shortDescription
                        ?.toLowerCase()
                        ?.includes(d.value.toLowerCase())
                  );

                  let i = articles.filter(
                    (a: IKbaArticle) =>
                      a.forDeletion == forDeletionFilter &&
                      a.forReview == forReviewFilter
                  );

                  if (allFilter) i = articles;

                  setKbaArticles(i);
                  const startPoint = currentPage * pageSize - pageSize;
                  const newOnes = i.slice(startPoint, startPoint + pageSize);
                  setTotalPageCount(Math.ceil(i.length / pageSize));
                  setCurrentItems(newOnes);
                }}
              />
            </Field>
          </Stack.Item>
          <Stack.Item>
            <UploadModal />
          </Stack.Item>
          <Stack.Item>
            <Menu>
              <MenuTrigger disableButtonEnhancement>
                <Tooltip
                  content="With calendar icon and no contents"
                  relationship="label"
                >
                  <MenuButton
                    appearance="transparent"
                    icon={<Settings20Regular />}
                  />
                </Tooltip>
              </MenuTrigger>
              <MenuPopover>
                <MenuList>
                  <FMenuItem>
                    <Switch
                      checked={allFilter}
                      defaultChecked={allFilter}
                      label="All"
                      onChange={() => {
                        setAllFilter(!allFilter);
                        setForDeletionFilter(false);
                        setForReviewFilter(false);
                      }}
                    />
                  </FMenuItem>
                  <FMenuItem>
                    <Switch
                      checked={forDeletionFilter}
                      label="Enabled"
                      onChange={() => {
                        setForDeletionFilter(!forDeletionFilter);
                        setAllFilter(false);
                      }}
                    />
                  </FMenuItem>
                  <FMenuItem>
                    <Switch
                      checked={forReviewFilter}
                      label="For Review"
                      onChange={() => {
                        setForReviewFilter(!forReviewFilter);
                        setAllFilter(false);
                      }}
                    />
                  </FMenuItem>
                </MenuList>
              </MenuPopover>
            </Menu>
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item
        align="stretch"
        style={{ overflowY: "scroll", overflowX: "hidden" }}
      >
        {items}
      </Stack.Item>
      <Stack.Item>
        <Stack horizontal>
          <Button
            appearance="transparent"
            disabled={currentPage <= 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            Previous
          </Button>
          <Button appearance="transparent">{currentPage}</Button>
          <Button
            appearance="transparent"
            disabled={currentPage >= totalPageCount}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            Next
          </Button>
        </Stack>
      </Stack.Item>
    </Stack>
  );
}
