import ChatView from "./ChatViewMain";
import Messages, { MessageTypes } from "./Messages";
import {
  chatViewStyles,
  chatFooterStyles,
  chatMessagesStyles,
} from "./ChatViewStyles";
import Footer from "./ChatFooter";
import {
  ChatMessageBot,
  ChatMessageDebug,
  ChatMessageUser,
  ChatMessageIntent,
  ChatMessageValidationForm,
  ChatMessageLoading,
} from "./MessageTemplates/index";
import type {
  KBAMeta,
  ChatMessageMeta,
  Conversation,
  Exchange,
  ChatMessage,
  KbArticle,
  KbArticleInstance,
  I,
} from "./MessageTemplates/interfaces";

export {
  Messages,
  MessageTypes,
  chatViewStyles,
  chatFooterStyles,
  chatMessagesStyles,
  Footer,
  ChatMessageBot,
  ChatMessageDebug,
  ChatMessageUser,
  ChatMessageIntent,
  ChatMessageValidationForm,
  ChatMessageLoading,
  KBAMeta,
  ChatMessageMeta,
  Conversation,
  Exchange,
  ChatMessage,
  KbArticle,
  KbArticleInstance,
  I,
};

export default ChatView;
