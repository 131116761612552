import { useContext } from "react";
import { AuthenticationContext } from "./AuthenticationContext";

export const useAuthenticationContext = () => {
  const context = useContext(AuthenticationContext);
  if (!context) {
    throw Error("You forgot to wrap in context ( AppClientContext )");
  }
  return context;
};
