import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Stack } from "@fluentui/react";
import { useAppClient } from "../services/appClient";
import {
  Divider as Separator,
  Text,
  Button as DefaultButton,
} from "@fluentui/react-components";

import { Person20Regular } from "@fluentui/react-icons";
import { ClipLoader } from "react-spinners";
import { useKeyPress } from "../hooks/useKeyPress";
import { ERightDrawerState } from "../services/appClient/AppClientContext";
import { AiStars } from "../../assets/aiStars";
import { customTokens } from "../../theme";

interface IContactInfoPane {
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
}

export default function ContactInfoPane({ setIsDrawerOpen }: IContactInfoPane) {
  const [contentType, setContentType] = useState("");
  const {
    selectedConversationLoading,
    selectedConversationError,
    aiAssistData,
    callerData,
    updateAiAssistData,
    cacheAiAssistData,
    aiAssistLoading,
    setRightDrawerState,
    selectedConversationId,
  } = useAppClient();

  const onKeyPress = () => {
    // if (!aiAssistData) {
    //   updateAiAssistData();
    // }
    if (aiAssistData) {
      setRightDrawerState(ERightDrawerState.ASSIST);
      setIsDrawerOpen(true);
    }
  };

  useEffect(() => {
    setContentType("");
  }, []);

  useKeyPress(["a"], onKeyPress);

  if (selectedConversationLoading)
    return (
      <Stack horizontalAlign="center" horizontal>
        <ClipLoader color="#36d7b7" size={30} />
      </Stack>
    );
  if (selectedConversationError) return <div>Error</div>;

  return (
    <>
      <Stack
        verticalAlign="center"
        style={{ height: "48px", zIndex: 2 }}
        data-testid="info-pane"
      >
        <Stack horizontal style={{}}>
          <Stack
            horizontal
            verticalAlign="center"
            style={{
              border: "none",
            }}
          >
            <Stack styles={{ root: { width: "2rem", marginLeft: "1rem" } }}>
              <Person20Regular />
            </Stack>
            <Stack horizontalAlign="start">
              <Text size={300} style={{ fontWeight: "500" }}>
                {callerData?.identifyCaller?.firstName}{" "}
                {callerData?.identifyCaller?.lastName}
              </Text>
            </Stack>
          </Stack>
          <Stack style={{ marginLeft: "auto", marginRight: "1rem" }} horizontal>
            <DefaultButton
              onClick={() => {
                setRightDrawerState(ERightDrawerState.CALLER);
                setIsDrawerOpen(true);
              }}
              style={{
                marginLeft: "1vw",
                border: `1px solid ${customTokens.infoPaneButtonColor}`,
                color: customTokens.infoPaneButtonColor,
                height: "32px",
                background: "transparent",
              }}
            >
              <Stack horizontalAlign="start">
                <Text size={200} style={{ fontWeight: "500" }}>
                  User details
                </Text>
              </Stack>
            </DefaultButton>
            <DefaultButton
              onClick={() => {
                setRightDrawerState(ERightDrawerState.TICKETS);
                setIsDrawerOpen(true);
              }}
              style={{
                marginLeft: "15px",
                border: `1px solid ${customTokens.infoPaneButtonColor}`,
                color: customTokens.infoPaneButtonColor,
                height: "32px",
                background: "transparent",
              }}
            >
              <Stack horizontalAlign="start">
                <Text size={200} style={{ fontWeight: "500" }}>
                  Tickets
                </Text>
              </Stack>
            </DefaultButton>
            <DefaultButton
              // disabled={true}
              style={{
                marginLeft: "15px",
                border: `1px solid ${customTokens.infoPaneButtonColor}`,
                color: customTokens.infoPaneButtonColor,
                height: "32px",
                background: "transparent",
              }}
              // appearance="primary"
              onClick={() => {
                if (!cacheAiAssistData && !aiAssistLoading) {
                  updateAiAssistData();
                  setRightDrawerState(ERightDrawerState.ASSIST);
                  setIsDrawerOpen(true);
                }
                if (cacheAiAssistData) {
                  setRightDrawerState(ERightDrawerState.ASSIST);
                  setIsDrawerOpen(true);
                }
              }}
            >
              <Stack horizontal>
                <Stack.Item style={{ marginRight: "4px" }}>
                  <AiStars color={customTokens.infoPaneButtonColor} />
                </Stack.Item>
                <Stack.Item style={{ marginTop: "2px" }}>
                  <Text size={200} style={{ fontWeight: "500" }}>
                    AI Assist
                  </Text>
                </Stack.Item>
              </Stack>
            </DefaultButton>
          </Stack>
        </Stack>
      </Stack>
      {contentType !== "" ? (
        <>
          <Separator
            style={{
              width: "100%",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          />
        </>
      ) : null}
    </>
  );
}
