import { StackWrapper } from "../../../GeneralComponents/StackWrapper";
import { SideBarMenuFiles } from "./SideBarMenuFiles";
import { MainDisplay } from "./MainDisplay";
import { Stack } from "@fluentui/react";
import { useState, useEffect } from "react";
import { KbaFile } from "../constants";

interface IKbaDisplay {
  kbaFiles: KbaFile[];
}

export function KbaFileDisplay({ kbaFiles }: IKbaDisplay) {
  const [allFiles, setAllFiles] = useState<KbaFile[]>(kbaFiles);
  const [activeFile, setActiveFile] = useState<KbaFile>(kbaFiles[0]);

  return (
    <Stack horizontal verticalAlign="center">
      <Stack.Item style={{ width: "auto" }} grow={0}>
        <StackWrapper opacity={0} height={"85vh"}>
          <SideBarMenuFiles
            kbaFiles={allFiles}
            setActiveFile={setActiveFile}
            activeFile={activeFile}
          />
        </StackWrapper>
      </Stack.Item>
      <Stack.Item grow={2} style={{ width: "auto" }}>
        <MainDisplay kbaFile={activeFile} setActiveFile={setActiveFile} />
      </Stack.Item>
    </Stack>
  );
}
