// import * as React from "react";
import { Dispatch, SetStateAction, useState, useEffect } from "react";
import {
  LineHorizontal320Regular,
  Add48Regular,
  Call20Regular,
  Comment16Regular,
} from "@fluentui/react-icons";
import {
  Button,
  Avatar,
  Button as DefaultButton,
  Divider,
  tokens,
} from "@fluentui/react-components";
import { Drawer, DrawerBody } from "@fluentui/react-drawer";
import chipIcon from "../../assets/chipIcon.svg";
import { useAppClient } from "../services/appClient";
import { Conversation } from "../../__generated__/graphql";
import { Stack, Persona, PersonaSize } from "@fluentui/react";
import {
  drawerSidebarConversationItemStyles,
  drawerSidebarRootStyles,
} from "./SideBarStyles";
import { useMutation } from "@apollo/client";
import { UPDATE_CONVERSATION, CREATE_CONVERSATION } from "../helpers/graphql";
import { EConversationType } from "../services/appClient";
import { customTokens } from "../../theme";
import { useAuthenticationContext } from "../../AuthenticationService/useAuthentication";

function ConversationItem({
  id,
  name,
  voted,
  createConversation,
  vote,
  setIsDrawerOpen,
  item,
}: {
  id: string | null | undefined;
  name: string;
  voted: boolean;
  createConversation: any;
  vote: number | null | undefined;
  setIsDrawerOpen: any;
  item: any;
}) {
  const { selectedConversationId, setSelectedConversationId, resetDrawer } =
    useAppClient();

  const isCall = item.callEngagement?.call ? true : false;

  const [editing, setEditing] = useState(true);
  const styles = drawerSidebarConversationItemStyles();

  return (
    <div data-testid={id}>
      <Stack
        className={styles.rootStack}
        key={id}
        horizontal
        horizontalAlign="center"
      >
        {!editing ? (
          <></>
        ) : (
          // <Stack horizontal>
          <DefaultButton
            appearance={"subtle"}
            size="small"
            style={{
              // margin: -10,
              width: "20px",
              background:
                selectedConversationId === id
                  ? customTokens.selectedConversationBackground
                  : "transparent",
            }}
            onClick={() => {
              if (id && id != selectedConversationId) {
                resetDrawer();
                setIsDrawerOpen(false);
                setSelectedConversationId(id);
              }
            }}
          >
            {isCall ? (
              <Stack
                verticalAlign="center"
                horizontalAlign="center"
                style={{
                  height: "35px",
                  width: "35px",
                  backgroundColor: customTokens.drawerSidebarIcons,
                  borderRadius: "50%",
                }}
              >
                <Call20Regular />
              </Stack>
            ) : (
              <Stack
                verticalAlign="center"
                horizontalAlign="center"
                style={{
                  height: "35px",
                  width: "35px",
                  backgroundColor: customTokens.drawerSidebarIcons,
                  borderRadius: "50%",
                  // display: inline-block;
                }}
              >
                <Comment16Regular style={{}} />
              </Stack>
            )}
          </DefaultButton>
          // </Stack>
        )}
      </Stack>
    </div>
  );
}

function SidebarButton({
  setIsOpen,
  isOpen,
}: {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
}) {
  return (
    <Button
      data-testid="drawer-sidebar-button"
      style={{ marginTop: 5, marginLeft: "auto", marginRight: "auto" }}
      onClick={() => setIsOpen(!isOpen)}
      appearance="transparent"
      icon={<LineHorizontal320Regular />}
    />
  );
}

interface IDrawerSideBar {
  children: any;
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
}

export function DrawerSidebar({ children, setIsDrawerOpen }: IDrawerSideBar) {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useAuthenticationContext();
  const {
    conversationList,
    // user,
    resetDrawer,
    setSelectedConversationId,
    messages,
    conversationType,
    setIsSidebarCollapsed,
    isSidebarCollapsed,
  } = useAppClient();

  const styles = drawerSidebarRootStyles();

  const [createConversation] = useMutation(CREATE_CONVERSATION, {
    variables: {
      client: user?.client?.id,
      user: user?.id,
    },
  });

  const openConversationsInitial = conversationList
    ? conversationList
        .slice()
        .reverse()
        .map((c: Conversation) => {
          if (c) {
            return (
              <ConversationItem
                id={c.id}
                name={c.name ? c.name : "Untitled"}
                voted={c.evaluation == 0 ? false : true}
                key={c.id}
                vote={c.evaluation}
                createConversation={createConversation}
                setIsDrawerOpen={setIsDrawerOpen}
                item={c}
              />
            );
          }
        })
    : [];

  return (
    <>
      <Stack
        style={{ height: "95vh", marginTop: "2vh" }}
        verticalAlign="center"
        horizontalAlign="center"
      >
        {/* Here we can Decide to collapse or to open a drawer */}
        <Stack.Item
          className="ms-hiddenXlUp"
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          <SidebarButton setIsOpen={setIsOpen} isOpen={isOpen} />
        </Stack.Item>
        <Stack.Item
          className="ms-hiddenLgDown"
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          <Button
            data-testid="drawer-sidebar-button-collapse"
            style={{
              marginTop: 5,
              marginLeft: "auto",
              marginRight: "auto",
            }}
            onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
            appearance="transparent"
            icon={<LineHorizontal320Regular />}
          />
        </Stack.Item>
        <Stack
          horizontal
          horizontalAlign="center"
          style={{ paddingLeft: "20px", paddingBottom: "5px" }}
        >
          <Persona
            size={PersonaSize.size40}
            imageUrl={chipIcon}
            style={{ padding: "10px" }}
          />
        </Stack>
        <Stack.Item>
          <Divider style={{ background: "#E0E0E0" }} />
        </Stack.Item>

        {/* Conversations */}
        <Stack
          style={{ marginTop: "10px" }}
          className={styles.conversationsStack}
        >
          <Stack.Item>{openConversationsInitial}</Stack.Item>
        </Stack>

        <Stack.Item
          style={{
            justifyContent: "flex-end",
            marginTop: "auto",
            alignSelf: "flex-end",
            marginBottom: "6vh",
            marginRight: "18px",
          }}
        >
          <Button
            size="large"
            appearance="primary"
            style={{ background: customTokens.vibrantBlue }}
            shape="circular"
            data-testId="drawer-sidebar-create-conversation"
            icon={<Add48Regular style={{ margin: 0 }} />}
            onClick={() => {
              if (
                messages.length > 0 ||
                conversationType == EConversationType.CALL
              ) {
                // need to figure out a better way of doing that
                createConversation({
                  onCompleted: (result) => {
                    setSelectedConversationId(
                      result.createConversation.conversation.id
                    );
                    setIsDrawerOpen(false);
                    resetDrawer();
                  },
                });
              }
            }}
          />
        </Stack.Item>
      </Stack>

      <Drawer open={isOpen} onOpenChange={(_, { open }) => setIsOpen(open)}>
        <DrawerBody data-testid="drawer-sidebar-body">{children}</DrawerBody>
      </Drawer>
    </>
  );
}
