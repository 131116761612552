import { Button } from "@fluentui/react-components";
import { useState } from "react";
import { useMutation, gql } from "@apollo/client";

interface FileFormat {
  name: string;
  format: string;
  chunk: string; // Base64 encoded chunk
  chunkIndex: number; // Order of the chunk
  totalChunks: number;
}

export function FileUpload() {
  const [files, setFiles] = useState<File[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState<{
    [key: string]: number;
  }>({});

  const CHUNK_SIZE = 1024 * 1024; // 1MB chunk size

  const MUTATION = gql`
    mutation ($fileChunk: FileFormat!) {
      uploadFileMutation(fileChunk: $fileChunk) {
        success
      }
    }
  `;

  const [mutate] = useMutation(MUTATION);

  // Convert a file slice (chunk) to base64
  const fileChunkToBase64 = (
    file: File,
    start: number,
    end: number
  ): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const blob = file.slice(start, end);
      reader.readAsDataURL(blob);
      reader.onload = () => resolve((reader.result as string).split(",")[1]);
      reader.onerror = reject;
    });
  };

  // Handle file selection and prepare for chunked upload
  const handleMultipleChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFiles = Array.from(event.target.files || []);
    setFiles(selectedFiles);

    for (const file of selectedFiles) {
      await uploadFileInChunks(file);
    }
  };

  // Upload each file in chunks using mutation
  const uploadFileInChunks = async (file: File) => {
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);

    for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
      const start = chunkIndex * CHUNK_SIZE;
      const end = Math.min(file.size, start + CHUNK_SIZE);
      const base64Chunk = await fileChunkToBase64(file, start, end);

      const fileChunk: FileFormat = {
        name: file.name,
        format: file.type,
        chunk: base64Chunk,
        chunkIndex: chunkIndex,
        totalChunks: totalChunks,
      };

      await mutate({ variables: { fileChunk } });

      const progress = Math.round(((chunkIndex + 1) / totalChunks) * 100);
      setUploadProgress((prev) => ({ ...prev, [file.name]: progress }));
    }

    // Mark the file as fully uploaded
    setUploadedFiles((prev) => [...prev, file]);
  };

  return (
    <>
      <h2>File Upload</h2>
      <form>
        <label htmlFor="file">
          File
          <input
            type="file"
            multiple
            required
            onChange={handleMultipleChange}
          />
        </label>
        <Button type="button">Submit</Button>
      </form>
      {uploadedFiles.length > 0 && (
        <div>
          <h3>Uploaded Files:</h3>
          <ul>
            {uploadedFiles.map((file, index) => (
              <li key={index}>{file.name}</li>
            ))}
          </ul>
        </div>
      )}
      {files.map((file) => (
        <div key={file.name}>
          <p>
            {file.name}: {uploadProgress[file.name] || 0}% uploaded
          </p>
        </div>
      ))}
    </>
  );
}
