import { Stack, StackItem } from "@fluentui/react";
import { chatViewStyles, Footer, Messages, MessageTypes } from "./index";
import { useSubscription } from "@apollo/client";
import { NEW_MESSAGE, NEW_TOKEN } from "../helpers/graphql";
import { useAppClient, EConversationType } from "../services/appClient";
import { ClipLoader } from "react-spinners";
import { useEffect, useState } from "react";

export default function ChatView() {
  const styles = chatViewStyles();

  const { messagesLoading, conversationType, messages } = useAppClient();

  const [textareaWorking, setTextAreaWorking] = useState<boolean>(false);
  const [ld, setLd] = useState<boolean>(messagesLoading);

  useEffect(() => {
    setTimeout(() => {
      setLd(messagesLoading);
    }, 5);
  }, [messages, messagesLoading]);

  if (ld) {
    return (
      <Stack
        verticalAlign="center"
        horizontalAlign="center"
        horizontal
        style={{ height: "95vh" }}
      >
        <ClipLoader color="#36d7b7" size={30} />
      </Stack>
    );
  }

  return (
    <Stack className={styles.root} verticalAlign="end">
      <StackItem>
        <Stack className={styles.rootStack}>
          <Messages
            height={
              conversationType == EConversationType.CALL
                ? "calc(95vh - 20px - 110px)"
                : "88vh"
            }
          />
        </Stack>
      </StackItem>
      <StackItem>
        <Footer setTextAreaWorking={setTextAreaWorking} />
      </StackItem>
    </Stack>
  );
}
