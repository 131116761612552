import { Stack } from "@fluentui/react";
import {
  SidebarConversationItem,
  sidebarRootStyles,
  SideBarStats,
  SideBarHeader,
} from "./index";
import {
  Button,
  Divider,
  Tab,
  TabList,
  Text,
} from "@fluentui/react-components";
import type {
  SelectTabData,
  SelectTabEvent,
  TabValue,
} from "@fluentui/react-components";
import { Dispatch, SetStateAction, useState } from "react";
import { Add48Regular, Delete20Regular } from "@fluentui/react-icons";
import { useMutation } from "@apollo/client";
import {
  CREATE_CONVERSATION,
  UPDATE_CONVERSATION,
  GET_CONVERSATIONS,
} from "../helpers/graphql";
import { ClipLoader } from "react-spinners";
import { useAppClient, EConversationType } from "../services/appClient";
import { useKeyPress } from "../hooks/useKeyPress";
import { Conversation } from "../../__generated__/graphql";
import { customTokens } from "../../theme";
import { useSpring, animated } from "@react-spring/web";
import { useAuthenticationContext } from "../../AuthenticationService/useAuthentication";

enum TabOptionsEnum {
  conversations = "conversations",
  stats = "stats",
}

interface ISideBar {
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
}

export default function SideBar({ setIsDrawerOpen }: ISideBar) {
  const onKeyPress = () => {
    if (messages.length > 0) {
      createConversation({
        onCompleted: (result) => {
          setSelectedConversationId(result.createConversation.conversation.id);
        },
      });
    }
  };

  useKeyPress(["n"], onKeyPress);

  const [selectedValue, setSelectedValue] = useState<TabValue>(
    TabOptionsEnum.conversations
  );
  const [deleteMode, setDeleteMode] = useState<boolean>(false);
  const [toDeleteList, setToDeleteList] = useState<string[]>([]);

  const { user } = useAuthenticationContext();
  const {
    setSelectedConversationId,
    conversationList,
    conversationListError,
    messages,
    messagesLoading,
    // user,
    conversationType,
    resetDrawer,
  } = useAppClient();

  const springs = useSpring({
    delay: 50,
    from: { y: 1000 },
    to: { y: 0 },
  });

  const springs2 = useSpring({
    delay: 50,
    from: { y: 1000 },
    to: { y: 0 },
  });

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
  };

  const [createConversation, { loading: creatingConversation }] = useMutation(
    CREATE_CONVERSATION,
    {
      variables: {
        client: user?.client?.id,
        user: user?.id,
      },
    }
  );

  const [updateConversation] = useMutation(UPDATE_CONVERSATION);

  function removeConversation(id: string | null | undefined) {
    if (id) {
      updateConversation({
        variables: {
          id: id,
          feDeleted: true,
        },
        refetchQueries: [GET_CONVERSATIONS],
      });
    }
  }

  const styles = sidebarRootStyles();

  if (conversationListError)
    return (
      <Stack
        verticalAlign="center"
        horizontalAlign="center"
        horizontal
        style={{ height: "20vh" }}
      >
        <ClipLoader color="#36d7b7" size={30} />
      </Stack>
    );

  const openConversationsInitial = conversationList
    ? conversationList
        .slice()
        .reverse()
        .map((c: Conversation | any) => {
          if (c) {
            return (
              <animated.div style={{ ...springs }}>
                <SidebarConversationItem
                  id={c.id}
                  name={c.name ? c.name : "Untitled"}
                  voted={c.evaluation == 0 ? false : true}
                  key={c.id}
                  vote={c.evaluation}
                  createConversation={createConversation}
                  setIsDrawerOpen={setIsDrawerOpen}
                  item={c}
                  deleteMode={deleteMode}
                  setDeleteMode={setDeleteMode}
                  toDeleteList={toDeleteList}
                  setToDeleteList={setToDeleteList}
                />
              </animated.div>
            );
          }
        })
    : [];

  return (
    <>
      <Stack
        className={styles.rootStack}
        data-testid="conversation-statistics-sidebar"
      >
        <animated.div style={{ ...springs2 }}>
          <Stack>
            <Stack.Item>
              <SideBarHeader />
            </Stack.Item>

            <Stack.Item className={styles.separatorItem}>
              <Divider />
            </Stack.Item>
            <Stack.Item className={styles.tabList}>
              <TabList selectedValue={selectedValue} onTabSelect={onTabSelect}>
                <Tab id="conversations" value="conversations">
                  <Text size={300}>Conversations</Text>
                </Tab>
                <Tab id="stats" value="stats">
                  <Text size={300}>Statistics</Text>
                </Tab>
              </TabList>
            </Stack.Item>
          </Stack>
        </animated.div>

        {selectedValue === TabOptionsEnum.conversations && (
          <Stack
            className={styles.conversationsStack}
            data-testid={`${TabOptionsEnum.conversations}-stack`}
          >
            <Stack.Item align="stretch" className={styles.conversationsItem}>
              {openConversationsInitial}
            </Stack.Item>
          </Stack>
        )}

        {selectedValue === TabOptionsEnum.stats && (
          <Stack.Item data-testid={`${TabOptionsEnum.stats}-stack`}>
            <SideBarStats />
          </Stack.Item>
        )}

        {selectedValue === TabOptionsEnum.conversations && (
          <Stack.Item className={styles.addButtonStack}>
            {!deleteMode && (
              <>
                <Button
                  size="large"
                  disabled={creatingConversation || messagesLoading}
                  data-testid="new-conversation-button"
                  appearance="primary"
                  style={{ background: customTokens.vibrantBlue }}
                  shape="circular"
                  icon={
                    !creatingConversation && !messagesLoading ? (
                      <Add48Regular style={{ margin: 0 }} />
                    ) : (
                      <ClipLoader color="white" size={17} />
                    )
                  }
                  onClick={() => {
                    if (
                      messages.length > 0 ||
                      conversationType == EConversationType.CALL
                    ) {
                      // need to figure out a better way of doing that
                      createConversation({
                        onCompleted: (result) => {
                          setSelectedConversationId(
                            result.createConversation.conversation.id
                          );
                          setIsDrawerOpen(false);
                          resetDrawer();
                        },
                      });
                    }
                  }}
                />
              </>
            )}
            {deleteMode && (
              <>
                <Button
                  size="large"
                  disabled={creatingConversation || messagesLoading}
                  data-testid="new-conversation-button"
                  appearance="secondary"
                  style={{
                    background: "#F56565",
                    border: "1px solid #F56565",
                    color: "white",
                  }}
                  shape="circular"
                  icon={
                    !creatingConversation && !messagesLoading ? (
                      <Delete20Regular style={{ margin: 0 }} />
                    ) : (
                      <ClipLoader color="white" size={17} />
                    )
                  }
                  onClick={() => {
                    for (const item of toDeleteList) {
                      removeConversation(item);
                    }
                    setDeleteMode(false);
                  }}
                />
              </>
            )}
          </Stack.Item>
        )}
      </Stack>
    </>
  );
}
