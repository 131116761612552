import { Stack } from "@fluentui/react";
import SideBar, { DrawerSidebar } from "./Sidebar";
import ChatView from "./ChatView";
import { StackWrapper } from "../GeneralComponents/StackWrapper";
import { useEffect, useState } from "react";
import { LoadingUser } from "../GeneralComponents/LoadingUserComponent";
import ErrorBoundary from "../GeneralComponents/ErrorBoundary";
import { useAppClient, EConversationType } from "./services/appClient";
import ContactInfoPane from "./AcrPane/InfoPane";
import useWindowDimensions from "./hooks/useWindowDimensions";
import { OverlayClientDrawer } from "./HelperComponents/OverlayClientDrawer";
import { customTokens } from "../theme";
// import DatabaseLogin from "./DatabaseLogin";
import { ERightDrawerState } from "./services/appClient/AppClientContext";
import { animated, useSpring, useResize } from "@react-spring/web";
import useMeasure from "react-use-measure";
import { useAuthenticationContext } from "../AuthenticationService/useAuthentication";

function Client() {
  const {
    conversationType,
    rightDrawerState,
    isSidebarCollapsed,
    conversationListLoading,
  } = useAppClient();

  const { user, userLoading } = useAuthenticationContext();

  const [ref, { width }] = useMeasure();

  const props = useSpring({ width: !isSidebarCollapsed ? width : 70 });
  const props2 = useSpring({ width: 70 });

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const { width: wdth } = useWindowDimensions();

  useEffect(() => {
    if (rightDrawerState === ERightDrawerState.CLOSED) {
      setIsDrawerOpen(false);
    }
  }, [rightDrawerState]);
  // if (userError) return token ? <LoadingUser /> : <ErrorBoundaryComponent />;
  // if (!connectionAuthenticated && !refreshToken) return <DatabaseLogin />;

  return (
    <ErrorBoundary>
      {!user || userLoading ? (
        <LoadingUser />
      ) : (
        <Stack
          horizontal
          verticalAlign="center"
          horizontalAlign="space-between"
          styles={{
            root: {
              overflow: "hidden",
              background: customTokens.mainAppBackground,
              height: "100vh",
              width: "100vw",
            },
          }}
        >
          {/* Sidebar */}
          <div ref={ref}>
            <Stack
              className="sidebar ms-hiddenLgDown"
              styles={{
                root: !isSidebarCollapsed && {
                  width: "25vw",
                  minWidth: "22vw",
                },
              }}
            >
              {!isSidebarCollapsed ? (
                <Stack>
                  <animated.div style={{ ...props }}>
                    <StackWrapper height="95vh" opacity={0}>
                      <SideBar setIsDrawerOpen={setIsDrawerOpen} />
                    </StackWrapper>
                  </animated.div>
                </Stack>
              ) : (
                <StackWrapper opacity={0} height="95vh">
                  <animated.div style={{ ...props }}>
                    <DrawerSidebar setIsDrawerOpen={setIsDrawerOpen}>
                      <SideBar setIsDrawerOpen={setIsDrawerOpen} />
                    </DrawerSidebar>
                  </animated.div>
                </StackWrapper>
              )}
            </Stack>
          </div>

          <Stack className="ms-hiddenXlUp">
            <StackWrapper opacity={0} height="95vh">
              <animated.div style={{ ...props2 }}>
                <DrawerSidebar setIsDrawerOpen={setIsDrawerOpen}>
                  <SideBar setIsDrawerOpen={setIsDrawerOpen} />
                </DrawerSidebar>
              </animated.div>
            </StackWrapper>
          </Stack>

          {/* ChatView */}

          <Stack
            styles={{
              root: {
                width: isDrawerOpen && wdth > 1240 ? "auto" : "90vw",
                minWidth: !isSidebarCollapsed ? "52vw" : "69vw",
              },
            }}
          >
            {conversationType == EConversationType.CALL && (
              <Stack.Item grow style={{ zIndex: 2 }}>
                <StackWrapper opacity={0}>
                  <ContactInfoPane setIsDrawerOpen={setIsDrawerOpen} />
                </StackWrapper>
              </Stack.Item>
            )}
            <Stack.Item>
              <StackWrapper
                boxShadow={false}
                opacity={1}
                height={
                  conversationType == EConversationType.CALL
                    ? "calc(95vh - 20px - 58px)"
                    : "95vh"
                }
              >
                <ChatView />
              </StackWrapper>
            </Stack.Item>
          </Stack>
          <Stack>
            <Stack.Item
              style={{
                display: isDrawerOpen ? "flex" : "none",
              }}
            >
              <StackWrapper display={wdth > 1240 ? "flex" : "none"} opacity={0}>
                <OverlayClientDrawer setIsDrawerOpen={setIsDrawerOpen} />
              </StackWrapper>
            </Stack.Item>
          </Stack>
        </Stack>
      )}
    </ErrorBoundary>
  );
}

export default Client;
