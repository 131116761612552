import React, { useEffect, useState } from "react";
import {
  Image,
  Stack,
  PrimaryButton,
  mergeStyles,
  Persona,
  PersonaSize,
  // Separator,
  // Dropdown,
  IDropdownOption,
} from "@fluentui/react";
import { Spinner } from "@fluentui/react-components";
import ChipIcon from "../../../assets/chipIcon.svg";

import {
  Textarea,
  Text,
  Button,
  tokens,
  Dropdown,
  Divider,
  Option,
} from "@fluentui/react-components";
import { Edit20Regular, Dismiss20Regular } from "@fluentui/react-icons";
import { intentBotStyles } from "./TemplateStyles";

interface IMessageIntent {
  key: any;
  index: any;
  item: any;
  conversationId: any;
}

export function ChatMessageIntent({
  key,
  index,
  item,
  conversationId,
}: IMessageIntent) {
  const styles = intentBotStyles();

  const options: IDropdownOption[] = [
    { key: "laptop_replacement", text: "Laptop Replacement" },
    { key: "von_issue", text: "Issue with VPN" },
    { key: "outlook_issue", text: "Outlook Issue" },
  ];

  const [workNotesEditable, setWorkNotesEditable] = useState(false);
  const [stateSummary, setStateSummary] = useState(item.summary);

  return (
    <Stack
      horizontal
      tokens={{ childrenGap: "3" }}
      key={index}
      className={styles.root}
    >
      <Persona size={PersonaSize.size32} imageUrl={ChipIcon} />
      <Stack.Item className={styles.rootItem}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          tokens={{ childrenGap: "1" }}
          className={styles.rootItemStack}
        >
          <Stack styles={{ root: { width: "100%" } }}>
            {/* <DebugInfo
          exchange_id={item.exchangeId}
          conversation_id={item.conversationId}
        /> */}
            <Stack horizontal horizontalAlign="end">
              {item.timestamp ? <Text size={100}>{item.timestamp}</Text> : null}
            </Stack>
          </Stack>
        </Stack>
        <Stack
          horizontalAlign="space-between"
          tokens={{ childrenGap: "1" }}
          styles={{
            root: {
              margin: "4px",
              marginRight: "10px",
              marginLeft: "10px",
              lineHeight: "1.5",
            },
          }}
        >
          <Stack horizontal tokens={{ childrenGap: 4 }}>
            <Text size={400} style={{ fontWeight: "900" }}>
              Email:
            </Text>

            <Text size={400} style={{ fontWeight: "500" }}>
              {item.email}
            </Text>
          </Stack>
          <Divider style={{ padding: "12px" }} />
          <Stack horizontal tokens={{ childrenGap: 4 }}>
            <Text size={400} style={{ fontWeight: "900" }}>
              Intent:
            </Text>

            <Text size={400} style={{ fontWeight: "500" }}>
              {item.intent}
            </Text>
          </Stack>
          <Divider style={{ padding: "12px" }} />
          <Stack
            horizontalAlign="start"
            verticalAlign="center"
            horizontal
            tokens={{ childrenGap: 4 }}
          >
            <Stack.Item style={{ width: "20%", textAlign: "left" }}>
              <Text size={400} style={{ fontWeight: "900" }}>
                Work notes:{" "}
              </Text>
            </Stack.Item>
            <Stack.Item style={{ width: "70%" }}>
              {!workNotesEditable ? (
                <Text
                  size={400}
                  style={{ fontWeight: "500", textAlign: "left" }}
                >
                  {stateSummary}
                </Text>
              ) : (
                <Stack style={{ width: "100%" }}>
                  <Textarea
                    size="medium"
                    resize="both"
                    value={stateSummary}
                    style={{ width: "100%", height: "auto" }}
                    data-testId="chat-intent-textbox"
                    onChange={(e) => setStateSummary(e.target.value)}
                  />
                </Stack>
              )}
            </Stack.Item>
            <Stack.Item
              style={{
                width: "10%",
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "15px",
              }}
            >
              <Button
                style={{ marginLeft: "auto" }}
                data-testId="chat-intent-button"
                appearance="primary"
                icon={
                  !workNotesEditable ? <Edit20Regular /> : <Dismiss20Regular />
                }
                onClick={() => setWorkNotesEditable(!workNotesEditable)}
              />
            </Stack.Item>
          </Stack>
          {item.earlyIntent ? (
            <Stack horizontal tokens={{ childrenGap: 4 }}>
              <Divider style={{ padding: "12px" }} />
              <Text size={400} style={{ fontWeight: "900" }}>
                Early Intent:
              </Text>

              <Text size={400} style={{ fontWeight: "500" }}>
                {/* {early_Intent} */}
              </Text>
            </Stack>
          ) : null}
          <Divider style={{ padding: "12px" }} />
          <Stack horizontal tokens={{ childrenGap: 4 }}>
            <Text size={400} style={{ fontWeight: "900", marginTop: "5px" }}>
              ServiceNow Template:
            </Text>

            <Stack.Item
              style={{
                marginLeft: "auto",
                marginRight: "15px",
              }}
            >
              <Dropdown
                style={{ width: "400px" }}
                placeholder="Choose an option"
                defaultValue={options[0].text}
                defaultSelectedOptions={[options[0].text]}
              >
                {options.map((option: IDropdownOption) => {
                  return (
                    <Option key={option.key} text={option.text}>
                      {" "}
                      {option.text}{" "}
                    </Option>
                  );
                })}
              </Dropdown>
            </Stack.Item>
          </Stack>
          <Divider style={{ padding: "12px" }} />
          {/* {intent && <PrimaryButton onClick={CreateTicket}>{showSpinner?<Spinner/>:"Create "+intent+" ticket"}</PrimaryButton>}   */}
          {item.intent && (
            <Stack horizontal horizontalAlign="space-between">
              <Stack.Item style={{ marginLeft: "auto" }}>
                <Button
                  appearance="primary"
                  style={{
                    width: "10em",
                    marginRight: "1em",
                  }}
                  // onClick={CreateTicket}
                >
                  Create Ticket
                </Button>
                {/* <Button
                  appearance="primary"
                  style={{ width: "10em" }}
                  // onClick={sendAssistanceRequest}
                >
                  Assistance
                </Button> */}
              </Stack.Item>
            </Stack>
          )}
        </Stack>
      </Stack.Item>
    </Stack>
  );
}
