import { gql } from "@apollo/client";

export const GET_CLIENT_KBA_AND_VOTED_RESPONSES = gql`
  query ClientKbaAndVotedResponses {
    user {
      id
      client {
        id
        votedResponses {
          id
          botAction
          response {
            id
            value
          }
          upvoted
          created
          disabled
        }
        kbArticles {
          id
          number
          forReview
          forDeletion
          instances {
            id
          }
          shortDescription
          content
          questions
        }
      }
    }
  }
`;

export const UPDATE_VOTED_RESPONSE = gql`
  mutation UpdateVotedResponse($id: String!, $disabled: Boolean!) {
    updateVotedResponse(id: $id, disabled: $disabled) {
      votedresponse {
        id
        disabled
      }
    }
  }
`;

export const UPDATE_KB_ARTICLE = gql`
  mutation UpdateKbArticle(
    $id: String!
    $forReview: Boolean!
    $forDeletion: Boolean!
  ) {
    updateKbArticle(id: $id, forReview: $forReview, forDeletion: $forDeletion) {
      kbarticle {
        id
        forReview
        forDeletion
      }
    }
  }
`;

export const UPLOAD_FILE_MUTATION = gql`
  mutation UploadFileMutation($file: Upload!) {
    uploadFileMutation(file: $file) {
      success
    }
  }
`;
