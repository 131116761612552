import { Stack, mergeStyles } from "@fluentui/react";
import { Text, Divider } from "@fluentui/react-components";
import { useAppClient } from "../../services/appClient";
import { customTokens } from "../../../theme";

export function DrawerContact() {
  const mainComponentClass = mergeStyles({
    maxWidth: "100%",
    width: "100%",
    height: "100%",
    padding: 10,
    overflowX: "hidden",
  });

  const { callerData, selectedConversationData } = useAppClient();

  const pcDetails: any = {
    Hostname: "LPOL123456",
    "IP Address": "123.456.7.8",
    "MAC Address": "00-B0-D0-63-C2-26",
    "Disk Space": "23% Free",
    "CPU Usage": "78%",
    "Wi-Fi Signal": "93%",
    "RAM Usage": "60%",
    Antivirus: "On",
  };

  return (
    <Stack tokens={{ childrenGap: 0 }} className={mainComponentClass}>
      <Stack horizontal horizontalAlign="start">
        <Stack tokens={{ childrenGap: 2 }}>
          <Stack
            styles={{ root: { paddingTop: "3px", width: "120%" } }}
            tokens={{ childrenGap: 5 }}
          >
            <Stack.Item>
              <Stack horizontal horizontalAlign="start">
                <Text
                  size={200}
                  style={{
                    textAlign: "left",
                    width: "7.5vw",
                    color: customTokens.drawerLeftTextColor,
                    fontWeight: 600,
                  }}
                >
                  Full Name:
                </Text>
                <Text
                  size={200}
                  style={{ color: customTokens.drawerRightTextColor }}
                >
                  {callerData?.identifyCaller?.firstName}{" "}
                  {callerData?.identifyCaller?.lastName}
                </Text>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <Stack horizontal horizontalAlign="start">
                <Text
                  size={200}
                  style={{
                    textAlign: "left",
                    width: "7.5vw",
                    color: customTokens.drawerLeftTextColor,
                    fontWeight: 600,
                  }}
                >
                  Phone Number:
                </Text>
                <Text
                  size={200}
                  style={{ color: customTokens.drawerRightTextColor }}
                >
                  {" "}
                  {
                    selectedConversationData?.callEngagement?.call
                      ?.callerPhoneNumber
                  }
                </Text>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <Stack horizontal horizontalAlign="start">
                <Text
                  size={200}
                  style={{
                    textAlign: "left",
                    width: "7.5vw",
                    color: customTokens.drawerLeftTextColor,
                    fontWeight: 600,
                  }}
                >
                  Gate:
                </Text>
                <Text style={{ textAlign: "left" }} size={200}>
                  {selectedConversationData?.callEngagement?.call?.gate}
                </Text>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <Stack horizontal horizontalAlign="start">
                <Text
                  size={200}
                  style={{
                    textAlign: "left",
                    width: "7.5vw",
                    color: customTokens.drawerRightTextColor,
                    fontWeight: 600,
                  }}
                >
                  PIN:{" "}
                </Text>
                <Text
                  size={200}
                  style={{
                    textAlign: "left",
                    color: customTokens.drawerLeftTextColor,
                  }}
                >
                  {selectedConversationData?.callEngagement?.call?.callerPin}
                </Text>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <Stack horizontal horizontalAlign="start">
                <Text
                  size={200}
                  style={{
                    textAlign: "left",
                    width: "7.5vw",
                    color: customTokens.drawerRightTextColor,
                    fontWeight: 600,
                  }}
                >
                  Start Date
                </Text>
                <Text
                  size={200}
                  style={{
                    textAlign: "left",
                    color: customTokens.drawerLeftTextColor,
                  }}
                >
                  {selectedConversationData?.callEngagement?.call?.callStartDate.replace(
                    "T",
                    " "
                  )}
                </Text>
              </Stack>
            </Stack.Item>
            {callerData?.attributes &&
              Object.entries(callerData?.attributes).map(
                ([key, value]: any) => (
                  <Stack key={key}>
                    <Stack.Item>
                      <Stack horizontal key={key} horizontalAlign="start">
                        <Text
                          style={{
                            textAlign: "left",
                            width: "7.5vw",
                            color: customTokens.drawerLeftTextColor,
                            fontWeight: 600,
                          }}
                        >
                          {key}:
                        </Text>
                        <Text
                          size={200}
                          style={{
                            textAlign: "left",
                            color: customTokens.drawerRightTextColor,
                          }}
                        >
                          {value}
                        </Text>
                      </Stack>
                    </Stack.Item>
                  </Stack>
                )
              )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
