import React, { SetStateAction, useEffect, useState, Dispatch } from "react";
import { Stack, IDropdownOption } from "@fluentui/react";
import {
  Button,
  Text,
  Divider,
  Option,
  Combobox,
} from "@fluentui/react-components";
import {
  Edit20Regular,
  Dismiss20Regular,
  Checkmark20Regular,
} from "@fluentui/react-icons";
import { useMutation } from "@apollo/client";
import { useAppClient } from "../../services/appClient";
import { useAuthenticationContext } from "../../../AuthenticationService/useAuthentication";
import { ERightDrawerState } from "../../services/appClient/AppClientContext";
import {
  CREATE_TICKET,
  LATEST_TICKETS,
  GET_CONVERSATION,
} from "../../helpers/graphql";
import { ClipLoader } from "react-spinners";
import { DrawerStyles } from "./DrawerComponentStyles";
import { Message, MessageType } from "../../../__generated__/graphql";
import { customTokens } from "../../../theme";
import { AIAssistTemplates as opt } from "../../helpers/constants";
import { aiAssistPerConversation } from "../../..";

interface IAiAssistDrawer {
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
}

export function AiAssistDrawer({ setIsDrawerOpen }: IAiAssistDrawer) {
  const {
    selectedConversationId,
    cacheAiAssistData: aiAssistData,
    callerData,
    setCurrentConversationMessages,
    currentConversationMessages,
    updateAiAssistData,
    setIsScrolling,
    renderDrawer,
    aiAssistLoading,
    setConversationsProccessed,
  } = useAppClient();

  const { user } = useAuthenticationContext();

  const styles = DrawerStyles();

  const [isCreateTicketLoading, setIsCreateTicketLoading] =
    useState<boolean>(false);
  const [workNotesEditable, setWorkNotesEditable] = useState(false);
  const [stateSummary, setStateSummary] = useState<string | undefined>(
    aiAssistData?.summary ? aiAssistData?.summary : undefined
  );
  const [stateSummaryNotModified, setStateSummaryNotModified] =
    useState<string>();
  const [options, setOptions] = useState<IDropdownOption[]>([]);
  const [isAiAssistLoading, setIsAiAssistLoading] = useState<boolean>(false);
  const [showingAll, setShowingAll] = useState<boolean>(false);

  const [selectedTemplateSysId, setSelectedTemplateSysId] = useState<string>(
    opt[0].sysId
  );

  const [createTicket] = useMutation(CREATE_TICKET, {
    refetchQueries: [GET_CONVERSATION, LATEST_TICKETS],
    variables: {
      callerId: callerData?.identifyCaller?.sysId,
      conversationId: selectedConversationId,
      template: selectedTemplateSysId,
      workNote: stateSummary,
      transcriptId: aiAssistData?.id,
    },
    onCompleted: async (result) => {
      const newMessage: Message = {
        botMessage: true,
        created: `${new Date().toISOString().slice(0, 19).replace("T", " ")}`,
        id: result.createTicket.id,
        received: true,
        type: MessageType.Message,
        value: result.createTicket.value,
        exchange: result.createTicket.exchange,
      };

      setCurrentConversationMessages([
        ...currentConversationMessages,
        ...[newMessage],
      ]);

      setIsCreateTicketLoading(false);
      setIsScrolling(true);
      setIsDrawerOpen(true);

      renderDrawer(ERightDrawerState.TICKETS);
    },
    onError: (error) => {
      setIsCreateTicketLoading(false);
    },
  });

  function moveObjectToFront(arr: any[], searchString: any) {
    const matches = arr.filter((obj) =>
      Object.values(obj).some(
        (value) => typeof value === "string" && value.includes(searchString)
      )
    );
    const rest = arr.filter((obj) => !matches.includes(obj));
    return [...matches, ...rest];
  }

  useEffect(() => {
    console.log(aiAssistData);
    let replaced: any[] = user.client.templates;

    // If kbaExtract is present, reorder templates with moveObjectToFront
    if (aiAssistData && aiAssistData?.kbaExtract?.template) {
      replaced = moveObjectToFront(replaced, aiAssistData.kbaExtract.template);
      console.log("Reordered templates with kbaExtract:", replaced);
    }

    // Create options based on the reordered template list
    const qoptions: IDropdownOption[] = replaced
      .slice(0, 5)
      .map((option: any) => {
        return {
          key: option.sysId ? option.sysId : option.templateSysId,
          text: option.name ? option.name : option.template,
          ...option,
        };
      });
    setOptions(qoptions);

    // Set selectedTemplateSysId to the first option’s sysId, ensuring a valid initial selection
    if (qoptions[0]) {
      setSelectedTemplateSysId(qoptions[0].key as string);
    }
  }, [aiAssistData]);

  const showMoreLessCategories = () => {
    const replaced = user.client.templates;

    // Apply `moveObjectToFront` if `kbaExtract.template` is present
    const reorderedTemplates = aiAssistData?.kbaExtract?.template
      ? moveObjectToFront(replaced, aiAssistData.kbaExtract.template)
      : replaced;

    // Slice based on `showingAll` state to control the number of displayed options
    const templatesToShow = showingAll
      ? reorderedTemplates.slice(0, 5)
      : reorderedTemplates;
    const qoptions: IDropdownOption[] = templatesToShow.map((option: any) => ({
      key: option.sysId ? option.sysId : option.templateSysId,
      text: option.name ? option.name : option.template,
      ...option,
    }));

    // Update options and toggle showingAll state
    setOptions(qoptions);
    setShowingAll(!showingAll);
  };

  useEffect(() => {
    setStateSummary(aiAssistData?.summary ? aiAssistData?.summary : undefined);
    writeAiAssistFieldToQuery(
      aiAssistData?.summary ? aiAssistData?.summary : ""
    );
  }, [aiAssistData?.summary]);

  const writeAiAssistFieldToQuery = (ms: string) => {
    let convos = aiAssistPerConversation();
    const conversation = convos.filter(
      (c: any) => c.conversationId == selectedConversationId
    )[0];

    if (conversation) {
      const index = convos.indexOf(conversation);
      let tmpData = { ...aiAssistData };
      tmpData.summary = ms;
      convos[index].message = ms;
      convos[index] = {
        ...convos[index],
        ...tmpData,
      };
      aiAssistPerConversation(convos);
    } else {
      let tmpData = { ...aiAssistData };
      tmpData.summary = ms;
      const newMessage = {
        conversationId: selectedConversationId,
        message: ms,
        ...tmpData,
      };
      aiAssistPerConversation([...aiAssistPerConversation(), newMessage]);
    }
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    writeAiAssistFieldToQuery(e.target.value);
    setStateSummary(e.target.value);
  };

  return (
    <>
      {aiAssistLoading ? (
        <Stack
          verticalAlign="center"
          horizontalAlign="center"
          horizontal
          style={{ height: "80vh" }}
        >
          <ClipLoader color="#36d7b7" size={30} />
        </Stack>
      ) : (
        <Stack
          horizontalAlign="space-between"
          tokens={{ childrenGap: "1" }}
          styles={{
            root: {
              lineHeight: "1.5",
              overflowX: "hidden",
              marginTop: "20px",
            },
          }}
        >
          <Stack tokens={{ childrenGap: 4 }}>
            <Stack.Item>
              <Stack horizontalAlign="start">
                <Text
                  size={200}
                  style={{
                    textAlign: "left",
                    width: "8vw",
                    color: customTokens.drawerLeftTextColor,
                  }}
                >
                  Email:
                </Text>
                <Text
                  size={200}
                  style={{ color: customTokens.drawerRightTextColor }}
                >
                  {callerData?.identifyCaller?.email}
                </Text>
              </Stack>
            </Stack.Item>
            <Divider style={{ padding: "6px" }} />

            <Stack.Item
              style={{
                padding: 5,
              }}
            >
              <Text
                size={200}
                style={{
                  textAlign: "left",
                  width: "8vw",
                  color: customTokens.drawerLeftTextColor,
                }}
              >
                ServiceNow Template
              </Text>
            </Stack.Item>

            <Stack.Item>
              {options[0] && (
                <Stack>
                  <Combobox
                    value={
                      options.find((opt) => opt.key === selectedTemplateSysId)
                        ?.text || ""
                    }
                    onChange={(e: any) => {
                      const searchValue = e.target.value.toLowerCase();
                      const filteredOptions = user.client.templates
                        .filter((template: any) =>
                          template.name.toLowerCase().includes(searchValue)
                        )
                        .map((option: any) => ({
                          key: option.sysId || option.templateSysId,
                          text: option.name || option.template,
                          ...option,
                        }));

                      setOptions(filteredOptions);
                    }}
                    onOptionSelect={(e, d) => {
                      const selectedOption = options.find(
                        (opt) => opt.text === d.optionText
                      );
                      if (selectedOption) {
                        setSelectedTemplateSysId(selectedOption.key as string);
                      }
                    }}
                    placeholder="Choose an option"
                  >
                    <Stack style={{ maxHeight: "60vh", overflowX: "hidden" }}>
                      {options.map((option: IDropdownOption) => (
                        <Option key={option.key} text={option.text}>
                          <Text size={200}>{option.text}</Text>
                        </Option>
                      ))}
                      <Text
                        style={{
                          marginLeft: "auto",
                          marginRight: "auto",
                          cursor: "pointer",
                          color: customTokens.vibrantBlue,
                        }}
                        onClick={showMoreLessCategories}
                      >
                        {showingAll ? "Show less ..." : "Show more ..."}
                      </Text>
                    </Stack>
                  </Combobox>
                </Stack>
              )}
            </Stack.Item>
            <Divider style={{ padding: "6px" }} />
            <Stack.Item>
              <Stack horizontalAlign="start">
                <Text
                  size={200}
                  style={{
                    textAlign: "left",
                    width: "8vw",
                    color: customTokens.drawerLeftTextColor,
                  }}
                >
                  Intent:
                </Text>
                <Text
                  size={200}
                  style={{ color: customTokens.drawerLeftTextColor }}
                >
                  {aiAssistData?.intent}
                </Text>
              </Stack>
            </Stack.Item>
          </Stack>

          <Divider style={{ padding: "6px" }} />

          <Stack tokens={{ childrenGap: 4 }}>
            <Stack.Item>
              <Stack horizontal>
                <Text
                  size={200}
                  style={{
                    textAlign: "left",
                    width: "8vw",
                    color: customTokens.drawerLeftTextColor,
                  }}
                >
                  Description:
                </Text>
              </Stack>
            </Stack.Item>

            {!workNotesEditable ? (
              <Stack.Item>
                <Text size={200}>{stateSummary}</Text>
              </Stack.Item>
            ) : (
              <Stack style={{ width: "100%" }}>
                <textarea
                  style={{ resize: "vertical" }}
                  placeholder="Work Notes"
                  className={styles.textArea}
                  value={stateSummary}
                  onChange={(e: any) => handleTextAreaChange(e)}
                ></textarea>
              </Stack>
            )}
            <Stack horizontal horizontalAlign="end">
              {workNotesEditable && (
                <Button
                  icon={<Dismiss20Regular />}
                  style={{
                    marginRight: "5px",
                    border: `1px solid ${customTokens.vibrantBlue}`,
                    color: customTokens.vibrantBlue,
                    background: "transparent",
                  }}
                  onClick={() => {
                    setStateSummary(stateSummaryNotModified);
                    writeAiAssistFieldToQuery(
                      stateSummaryNotModified ? stateSummaryNotModified : ""
                    );
                    setWorkNotesEditable(!workNotesEditable);
                  }}
                />
              )}
              <Button
                // style={{ marginLeft: "auto" }}
                appearance="primary"
                data-testid="ai-assist-edit-button"
                icon={
                  !workNotesEditable ? (
                    <Edit20Regular />
                  ) : (
                    <Checkmark20Regular />
                  )
                }
                onClick={() => {
                  if (!workNotesEditable) {
                    setStateSummaryNotModified(stateSummary);
                    writeAiAssistFieldToQuery(stateSummary ? stateSummary : "");
                  }
                  setWorkNotesEditable(!workNotesEditable);
                }}
              />
            </Stack>
          </Stack>
          <Divider style={{ padding: "6px" }} />

          <div style={{ padding: "12px" }}></div>
          {aiAssistData?.intent && (
            <Stack horizontal horizontalAlign="space-between">
              <Stack.Item style={{ marginLeft: "auto" }}></Stack.Item>
              <Stack.Item style={{ marginLeft: "auto" }}>
                <Button
                  name="Create Ticket"
                  disabled={isCreateTicketLoading}
                  style={{
                    marginRight: "5px",
                  }}
                  appearance="primary"
                  onClick={() => {
                    setIsCreateTicketLoading(true);
                    console.log(selectedTemplateSysId);
                    createTicket();
                  }}
                >
                  {!isCreateTicketLoading ? (
                    "Create Ticket"
                  ) : (
                    <Stack horizontalAlign="center" horizontal>
                      <ClipLoader color="white" size={20} />
                    </Stack>
                  )}
                </Button>
                <Button
                  disabled={isAiAssistLoading}
                  name="Refresh"
                  style={{
                    border: `1px solid ${customTokens.vibrantBlue}`,
                    color: customTokens.vibrantBlue,
                    background: "transparent",
                  }}
                  onClick={() => {
                    setIsAiAssistLoading(true);
                    updateAiAssistData({
                      onCompleted: ({ aiAssist }) => {
                        setIsAiAssistLoading(false);
                        setConversationsProccessed([]);
                        setStateSummary(aiAssist.summary);
                        writeAiAssistFieldToQuery(aiAssist.summary);
                      },
                      onError: (error) => {
                        setIsAiAssistLoading(false);
                        setConversationsProccessed([]);
                      },
                    });
                  }}
                >
                  Refresh
                </Button>
              </Stack.Item>
            </Stack>
          )}
        </Stack>
      )}
    </>
  );
}
