import { useState, useEffect } from "react";
import { Stack } from "@fluentui/react";
import { MoreVertical24Regular } from "@fluentui/react-icons";
import {
  Menu,
  Button,
  MenuTrigger,
  MenuList,
  MenuItemCheckbox,
  MenuPopover,
  MenuProps,
  MenuDivider,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  Field,
  Textarea,
  Slider,
  SliderProps,
  Label,
  MenuItem,
} from "@fluentui/react-components";
import { headerStyles, CustomAvatar } from "./index";
import { isInTeams } from "../helpers/tokenFunctions";
import {
  PrivateComponent,
  ROLES,
} from "../../GeneralComponents/PrivateRouteComponent";
import { useAppClient } from "../services/appClient";
import { customTokens } from "../../theme";
import {
  SET_UPVOTE_CACHE,
  SET_DOWNVOTE_CACHE,
  USER_SESSION_QUESTION_MATCHING,
} from "../helpers/graphql";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useAuthenticationContext } from "../../AuthenticationService/useAuthentication";

export function SideBarHeader() {
  const styles = headerStyles();
  const inTeams = isInTeams();

  const [isUpvote, setIsUpvote] = useState(true);
  const [isDownvote, setIsDownvote] = useState(true);
  const [isUserSessionQuestionMatching, setIsUserSessionQuestionMatching] =
    useState(true);

  const navigate = useNavigate();
  const { logOut, useDarkMode, setUseDarkMode, user } =
    useAuthenticationContext();

  const {
    debugMode,
    setDebugMode,
    validationMode,
    setValidationMode,
    setFontSizeValue,
    fontSizeValue,
  } = useAppClient();

  enum CheckedItemsOptions {
    debugMode = "debugMode",
    validation = "validation",
    darkmode = "darkmode",
    sound = "sound",
    upvote = "upvote",
    downvote = "downvote",
    userSessionQuestionMatching = "usersessionqustionmatching",
  }

  const [setUpvoteCache] = useMutation(SET_UPVOTE_CACHE);
  const [setDownvoteCache] = useMutation(SET_DOWNVOTE_CACHE);
  const [setUserSessionQuestionMatching] = useMutation(
    USER_SESSION_QUESTION_MATCHING
  );
  const [checkedControls, setCheckedControls] = useState({ edit: [] });
  const onControlsChange: MenuProps["onCheckedValueChange"] = (
    e,
    { name, checkedItems }
  ) => {
    switch (name) {
      case CheckedItemsOptions.debugMode:
        setDebugMode(!debugMode);
        break;
      case CheckedItemsOptions.validation:
        setValidationMode(!validationMode);
        break;
      case CheckedItemsOptions.upvote:
        setUpvoteCache({ variables: { enabled: !isUpvote } });
        setIsUpvote(!isUpvote);
        break;
      case CheckedItemsOptions.downvote:
        setDownvoteCache({ variables: { enabled: !isDownvote } });
        setIsDownvote(!isDownvote);
        break;
      case CheckedItemsOptions.userSessionQuestionMatching:
        setUserSessionQuestionMatching({
          variables: { enabled: !isUserSessionQuestionMatching },
        });
        setIsUserSessionQuestionMatching(!isUserSessionQuestionMatching);
        break;
      case CheckedItemsOptions.darkmode:
        setUseDarkMode(!useDarkMode);
        localStorage.setItem("useDarkMode", `${!useDarkMode}`);
    }
    setCheckedControls((s: any) => {
      return s ? { ...s, [name]: checkedItems } : { [name]: checkedItems };
    });
  };

  useEffect(() => {
    const items: any[] = [
      debugMode ? CheckedItemsOptions.debugMode : null,
      validationMode ? CheckedItemsOptions.validation : null,
      useDarkMode ? CheckedItemsOptions.darkmode : null,
      isUpvote ? CheckedItemsOptions.upvote : null,
      isDownvote ? CheckedItemsOptions.downvote : null,
      isUserSessionQuestionMatching
        ? CheckedItemsOptions.userSessionQuestionMatching
        : null,
    ].filter((el: any) => el != null);
    for (const item of items) {
      setCheckedControls((s: any) => {
        return s ? { ...s, [item]: item } : { [item]: item };
      });
    }
  }, []);

  const onSliderChange: SliderProps["onChange"] = (_, data) => {
    const value: any = Number(Math.round(data.value / 100) * 100);
    setFontSizeValue(value);
    localStorage.setItem("font-size-value", JSON.stringify(value));
  };

  return (
    <Stack
      horizontal
      verticalAlign="space-between"
      horizontalAlign="space-between"
    >
      <Stack.Item>
        <CustomAvatar />
      </Stack.Item>
      <Stack.Item className={styles.menuStackItem}>
        <Stack
          horizontal
          verticalAlign="space-between"
          horizontalAlign="space-between"
          tokens={{ childrenGap: 5 }}
          className={styles.menuStack}
        >
          <Stack.Item>
            <Menu
              data-testid="menu-items"
              checkedValues={checkedControls}
              onCheckedValueChange={onControlsChange}
            >
              <MenuTrigger disableButtonEnhancement>
                <Button
                  icon={<MoreVertical24Regular />}
                  appearance="transparent"
                  style={{ marginTop: "0vh", marginRight: "14px" }}
                  data-testId="menu-trigger"
                />
              </MenuTrigger>
              <MenuPopover
                style={{ background: customTokens.messageBotAndMenuBackground }}
              >
                <MenuList>
                  <MenuItem
                    disabled
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      background: customTokens.messageBotAndMenuBackground,
                      cursor: "default",
                    }}
                  >
                    <Stack style={{ marginLeft: "10px" }}>
                      {user?.client?.name} <br />
                      {user?.username}
                    </Stack>
                  </MenuItem>
                  <MenuDivider />
                  <PrivateComponent roles={[ROLES.admin]}>
                    <MenuItemCheckbox
                      style={{
                        background: customTokens.messageBotAndMenuBackground,
                      }}
                      name="debugMode"
                      value="debugMode"
                      data-testid="menu-debug-mode"
                    >
                      Debug Mode
                    </MenuItemCheckbox>
                  </PrivateComponent>
                  <PrivateComponent roles={[ROLES.admin]}>
                    <MenuItemCheckbox
                      style={{
                        background: customTokens.messageBotAndMenuBackground,
                      }}
                      name="validation"
                      value="validation"
                      data-testid="menu-validation"
                    >
                      Validation
                    </MenuItemCheckbox>
                  </PrivateComponent>
                  <PrivateComponent roles={[ROLES.admin]}>
                    <MenuItemCheckbox
                      style={{
                        background: customTokens.messageBotAndMenuBackground,
                      }}
                      name="upvote"
                      value="upvote"
                      data-testid="menu-upvote"
                    >
                      Up Vote Cache
                    </MenuItemCheckbox>
                  </PrivateComponent>
                  <PrivateComponent roles={[ROLES.admin]}>
                    <MenuItemCheckbox
                      style={{
                        background: customTokens.messageBotAndMenuBackground,
                      }}
                      name="downvote"
                      value="downvote"
                      data-testid="menu-downvote"
                    >
                      Down Vote Cache
                    </MenuItemCheckbox>
                  </PrivateComponent>
                  <PrivateComponent roles={[ROLES.admin]}>
                    <MenuItemCheckbox
                      style={{
                        background: customTokens.messageBotAndMenuBackground,
                      }}
                      name="usersessionqustionmatching"
                      value="usersessionqustionmatching"
                      data-testid="menu-usersessionqustionmatching"
                    >
                      Question Matching
                    </MenuItemCheckbox>
                  </PrivateComponent>
                  <PrivateComponent roles={[ROLES.admin]}>
                    <Dialog
                    //   open={open}
                    //   onOpenChange={(event, data) => setOpen(data.open)}
                    >
                      <DialogTrigger>
                        <MenuItemCheckbox
                          value="prompt"
                          name="prompt"
                          checkmark={<></>}
                          data-testid="prompt-open"
                          style={{
                            background:
                              customTokens.messageBotAndMenuBackground,
                          }}
                        >
                          Prompt
                        </MenuItemCheckbox>
                      </DialogTrigger>
                      <DialogSurface>
                        <DialogBody>
                          <DialogTitle>Prompt Override</DialogTitle>
                          <Field size="large" className={styles.promptOveride}>
                            <Textarea
                              data-testid="prompt-text-area"
                              size="large"
                              placeholder="Put a Prompt Override here."
                              //   value={promptOverride}
                            />
                          </Field>
                          <DialogActions>
                            <DialogTrigger disableButtonEnhancement>
                              <Button
                                data-testid="clear-prompt-button"
                                appearance="secondary"
                                onClick={() => {
                                  //   setPromptOverride("");
                                  //   setOpen(false);
                                }}
                              >
                                Clear
                              </Button>
                            </DialogTrigger>
                            <Button
                              data-testid="close-prmopt-button"
                              appearance="primary"
                              //   onClick={() => setOpen(false)}
                            >
                              Close
                            </Button>
                          </DialogActions>
                        </DialogBody>
                      </DialogSurface>
                    </Dialog>
                    <MenuDivider />
                  </PrivateComponent>
                  {!inTeams && (
                    <MenuItemCheckbox
                      style={{
                        background: customTokens.messageBotAndMenuBackground,
                      }}
                      name="darkmode"
                      value="darkmode"
                      data-testid="menu-dark-mode"
                    >
                      Dark Mode
                    </MenuItemCheckbox>
                  )}

                  {/* <MenuDivider /> */}
                  {inTeams && (
                    <MenuItemCheckbox
                      // appearance="subtle"
                      onClick={() => {
                        window.open(
                          `${
                            window.location.href
                          }?refreshToken=${localStorage.getItem(
                            "refreshToken"
                          )}`,
                          "chat"
                        );
                      }}
                      name="browser"
                      value="browser"
                      checkmark={<></>}
                      style={{
                        background: customTokens.messageBotAndMenuBackground,
                      }}
                      data-testid="menu-browser"
                    >
                      Open in browser
                    </MenuItemCheckbox>
                  )}

                  <PrivateComponent roles={[ROLES.admin]}>
                    <MenuItemCheckbox
                      // appearance="subtle"
                      onClick={() => {
                        navigate("/kbaManagement");
                      }}
                      name="kbaModule"
                      value="kbaModule"
                      checkmark={<></>}
                      style={{
                        background: customTokens.messageBotAndMenuBackground,
                      }}
                      data-testid="menu-Km"
                    >
                      KMC Module
                    </MenuItemCheckbox>
                  </PrivateComponent>

                  <MenuItemCheckbox
                    // appearance="subtle"
                    onClick={() => logOut()}
                    name="logout"
                    value="logout"
                    checkmark={<></>}
                    style={{
                      background: customTokens.messageBotAndMenuBackground,
                    }}
                    data-testid="menu-logout"
                  >
                    Log out
                  </MenuItemCheckbox>
                  <MenuDivider />

                  <Label htmlFor={"font-size-slider"} size="small">
                    Bubble size
                  </Label>
                  <Slider
                    size="small"
                    id="font-size-slider"
                    min={100}
                    max={800}
                    step={100}
                    value={fontSizeValue}
                    onChange={onSliderChange}
                  />
                </MenuList>
              </MenuPopover>
            </Menu>
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
}
