export type AIAssistTemplate = {
  key: number;
  text: string;
  sysId: string;
};

export const AIAssistTemplates: AIAssistTemplate[] = [
  {
    key: 443,
    text: "Outlook issue - [Outlook_Team CAPGEMINI]",
    sysId: "cc3c0e221b52f11047fe65b6624bcb9b",
  },
  {
    key: 447,
    text: "Citrix issue - [Citrix_Team CAPGEMINI]",
    sysId: "369479ae1bdeb11047fe65b6624bcbb1",
  },
  {
    key: 444,
    text: "ACR issue - [ADC Poland]",
    sysId: "9707b9221b12f11047fe65b6624bcbaa",
  },
  {
    key: 445,
    text: "BuddyBOT issue - [ADC Poland]",
    sysId: "268c02621b52f11047fe65b6624bcb56",
  },
  {
    key: 446,
    text: "JustClick! issue - [ADC Poland]",
    sysId: "029b46221b52f11047fe65b6624bcbbb",
  },
];

export type StatusOption = {
  displayValue: string;
  value: string;
};

export const statuses: StatusOption[] = [
  {
    displayValue: "In Progress",
    value: "2",
  },
  {
    displayValue: "On Hold",
    value: "3",
  },
  {
    displayValue: "Resolved",
    value: "6",
  },
];

export type ResolutionCode = string;

export const resolutionCodes: ResolutionCode[] = [
  "Solved (Work Around)",
  "Solved (Permanently)",
  "Solved Remotely (Work Around)",
  "Solved Remotely (Permanently)",
  "Not Solved (Not Reproducible)",
  "Not Solved (Too Costly)",
  "Closed/Resolved by Caller",
];

export type OnHoldReason = {
  displayValue: string;
  value: string;
};

export const onHoldreasons: OnHoldReason[] = [
  { displayValue: "Awaiting Caller", value: "1" },
  { displayValue: "Awaiting Change", value: "5" },
  { displayValue: "Awaiting Evidence", value: "2" },
  { displayValue: "Awaiting Problem", value: "3" },
  { displayValue: "Awaiting Vendor", value: "4" },
];
