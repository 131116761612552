import { createContext, Dispatch, SetStateAction } from "react";
import { ApolloError } from "@apollo/client";

export interface IAuthenticationContext {
  token: string | undefined | null;
  setToken: Dispatch<SetStateAction<string | undefined>>;
  refreshToken: string | undefined | null;
  setRefreshToken: Dispatch<SetStateAction<string | undefined>>;
  connectionAuthenticated: boolean;
  setConnectionAuthenticated: Dispatch<SetStateAction<boolean>>;
  logOut: () => void;
  user: any;
  userLoading: boolean | undefined;
  userError: ApolloError | undefined;
  setUseDarkMode: Dispatch<SetStateAction<boolean>>;
  useDarkMode: boolean | any;
  refreshTokenMutationIsLoading: boolean;
}

export const AuthenticationContext =
  createContext<IAuthenticationContext | null>(null);
