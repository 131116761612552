import { makeStyles, shorthands, mergeClasses } from "@griffel/react";
import { tokens } from "@fluentui/react-components";
import { customTokens } from "../../../theme";

export const acrCardTicketStyles = makeStyles({
  root: {
    ...shorthands.border("1px", "solid", "#ccc"),
    ...shorthands.borderRadius("8"),
    ...shorthands.padding("8"),
    ...shorthands.margin("8px", "0"),
  },
});

export const loadingMessageStyles = makeStyles({
  rootStack: {
    width: "100%",
    marginBottom: "5px",
    maxWidth: "80vw",
    minWidth: "100px",
  },
  rootStackItem: {
    boxShadow: "0px 3px 15px rgba(0,0,0,0.2)",
    // backgroundColor: tokens.colorNeutralBackground1Selected,
    backgroundColor: customTokens.messageBotAndMenuBackground,
    marginBottom: "5px",
    minWidth: "100px",
    maxWidth: "60vw",
    marginY: "1",
    ...shorthands.padding("3"),
    ...shorthands.borderRadius("10px"),
    marginLeft: "3",
    lineHeight: "1.5",
    letterSpacing: "tighter",
    whiteSpace: "pre-wrap",
  },
  rootStackItemStack: {
    ...shorthands.margin("4px"),
    marginRight: "10px",
    marginLeft: "10px",
    lineHeight: "2",
  },
  bubbleStack: {
    textAlign: "left",
  },
  bubbleText: {
    lineHeight: "2",
    ...shorthands.margin("0"),
    marginRight: "auto",
    marginLeft: "auto",
    ...shorthands.padding("3"),
    marginTop: "3",
    marginBottom: "5",
  },
});

export const messageAcrStyles = makeStyles({
  mainComponentClass: {
    maxWidth: "600",
    ...shorthands.margin("auto"),
    ...shorthands.padding("20"),
    ...shorthands.border("1px", "solid", "#ccc"),
    ...shorthands.borderRadius("8"),
  },
  names: {
    display: "flex",
    justifyContent: "center",
  },
  textBold: {
    fontWeight: "bold",
  },
  key: {
    width: "300px",
  },
});

export const intentBotStyles = makeStyles({
  root: {
    width: "100%",
    marginBottom: "5px",
    maxWidth: "80vw",
    minWidth: "100px",
  },

  rootItem: {
    backgroundColor: tokens.colorNeutralForegroundInverted,
    ...shorthands.border("1px"),
    minWidth: "100px",
    maxWidth: "60vw",
    marginY: "1",
    ...shorthands.padding("3"),
    ...shorthands.borderRadius("10px"),
    marginLeft: "3",
    lineHeight: "1.5",
    letterSpacing: "tighter",
    whiteSpace: "pre-wrap",
    boxShadow: "0px 3px 15px rgba(0,0,0,0.2)",
    marginBottom: "5px",
  },

  rootItemStack: {
    ...shorthands.margin("4px"),
    marginRight: "10px",
    marginLeft: "10px",
    lineHeight: "2",
  },
});

export const messageBotStyles = makeStyles({
  root: {
    width: "100%",
    marginBottom: "5px",
    maxWidth: "80vw",
    minWidth: "100px",
  },
  rootStackItem: {
    // backgroundColor: tokens.colorNeutralBackground1Selected,
    backgroundColor: customTokens.messageBotAndMenuBackground,
    boxShadow: "0px 3px 15px rgba(0,0,0,0.2)",
    marginBottom: "5px",
    // border: styles.botBubble.border,
    minWidth: "100px",
    maxWidth: "60vw",
    marginY: "1",
    ...shorthands.padding("3"),
    ...shorthands.borderRadius("10px"),
    marginLeft: "3",
    lineHeight: "1.5",
    letterSpacing: "tighter",
    whiteSpace: "pre-wrap",
  },
  stackItemStack: {
    // ...shorthands.margin("4px"),
    marginRight: "10px",
    marginLeft: "10px",
    marginTop: "4px",
    // lineHeight: "2",
  },
  textStack: {
    textAlign: "left",
    marginTop: "-0.0em",
  },
  text: {
    // ...shorthands.padding(""),
    marginTop: "-5px",
    lineHeight: "1.6",
    marginRight: ".5rem",
    marginLeft: ".5rem",
    // whiteSpace: "pre",
  },
  pre: {
    whiteSpace: "pre-wrap",
    fontFamily: "Segoe UI",
    // marginTop: "-10",
  },
  scoreStackRoot: {
    ...shorthands.margin("0.5rem"),
  },
  thumb: {
    cursor: "pointer",
  },
});

export const messageUserStyles = makeStyles({
  root: {
    width: "100%",
    marginBottom: "5px",
    maxWidth: "80vw",
    minWidth: "100px",
  },
  rootStack: {
    backgroundColor: customTokens.messageUserBackgroundColor,
    color: tokens.colorStrokeFocus2,
    boxShadow: "0px 3px 15px rgba(0,0,0,0.2)",
    marginBottom: "5px",
    // background:
    // "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(11,129,186,1) 0%, rgba(2,83,168,1) 100%)",
    minWidth: "100px",
    maxWidth: "60vw",
    width: "fit-content",
    ...shorthands.padding("3"),
    marginY: "1",
    ...shorthands.borderRadius("10px"),
    lineHeight: "1.5",
    letterSpacing: "tighter",
    whiteSpace: "pre-wrap",
    alignSelf: "right",
  },
  timeStampText: {
    paddingLeft: "0.7em",
    paddingRight: "0.7em",
    paddingTop: "0.7em",
    paddingBottom: "-0.5em",
  },
  textStack: {
    alignContent: "left",
    lineHeight: "2",
    ...shorthands.margin("0"),
    textAlign: "left",
    marginRight: "1rem",
    marginLeft: "0.5rem",
    marginTop: "8px",
  },
  txtPre: {
    whiteSpace: "pre-wrap",
    fontFamily: "Segoe UI",
    marginTop: "1",
    // fontSize: "0.8rem",
  },
  diliveryMark: {
    marginTop: "280%",
  },
});
