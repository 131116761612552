import { IVotedResponse } from "./VotedResponsesDisplay";
import { Stack } from "@fluentui/react";
import {
  Button,
  Card,
  Text,
  Tooltip,
  Field,
  Avatar,
} from "@fluentui/react-components";
import { CheckmarkCircle20Regular } from "@fluentui/react-icons";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useMutation } from "@apollo/client";
import { UPDATE_VOTED_RESPONSE } from "../../network/graphql";
import { Dispatch, SetStateAction } from "react";
import { customTokens } from "../../../theme";
import { tags } from "../constants";
import { Tag } from "@fluentui/react-tags";
import { useState, useEffect } from "react";

import {
  TagPicker,
  TagPickerList,
  TagPickerInput,
  TagPickerControl,
  TagPickerProps,
  TagPickerOption,
  TagPickerGroup,
} from "@fluentui/react-tag-picker-preview";

export function RightPanelDisplay({
  votedResponse,
  setActiveVotedResponse,
}: {
  votedResponse: IVotedResponse;
  setActiveVotedResponse: Dispatch<SetStateAction<IVotedResponse>>;
}) {
  const [updateVotedResponse] = useMutation(UPDATE_VOTED_RESPONSE, {
    variables: {
      id: votedResponse.id,
      disabled: !votedResponse.disabled,
    },
    onCompleted: (data) => {},
    onError: (error) => {},
  });

  useEffect(() => {
    setSelectedOptions(votedResponse.tags ? [...votedResponse.tags] : []);
  }, [votedResponse]);

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const onOptionSelect: TagPickerProps["onOptionSelect"] = (e, data) => {
    console.log(data);
    let file = { ...votedResponse };
    file.tags = data.selectedOptions;
    setActiveVotedResponse(file);
    setSelectedOptions(data.selectedOptions);
  };

  return (
    <Stack
      style={{
        overflowY: "scroll",
        overflowX: "hidden",
        maxHeight: "88vh",
        minHeight: "88vh",
        marginTop: "20px",
      }}
    >
      <Stack.Item>
        <Stack
          horizontal
          horizontalAlign="space-between"
          style={{ marginRight: 15 }}
        >
          <Stack.Item style={{ display: "flex", justifyContent: "center" }}>
            <Card
              size="small"
              style={{
                backgroundColor: customTokens.messageBotAndMenuBackground,
                maxWidth: "30vw",
              }}
            >
              <Text size={300} style={{ fontWeight: "bold" }}>
                {votedResponse.botAction}
              </Text>
              <Text size={200}>
                {!votedResponse.created.includes(".")
                  ? votedResponse.created.replace("T", " ")
                  : votedResponse.created
                      .replace("T", " ")
                      .substring(0, votedResponse.created.indexOf("."))}
              </Text>
            </Card>
          </Stack.Item>
          <Stack.Item>
            <Stack horizontal style={{ marginTop: "4px", marginBottom: "4px" }}>
              <Field label="Edit Tags" style={{ maxWidth: 400 }}>
                <TagPicker
                  onOptionSelect={onOptionSelect}
                  selectedOptions={selectedOptions}
                >
                  <TagPickerControl style={{ background: "transparent" }}>
                    <TagPickerGroup>
                      {selectedOptions.map((option) => (
                        <Tag
                          key={option}
                          shape="circular"
                          media={<Avatar name={option} color="colorful" />}
                          value={option}
                        >
                          {option}
                        </Tag>
                      ))}
                    </TagPickerGroup>
                    <TagPickerInput />
                  </TagPickerControl>
                  <TagPickerList>
                    {tags
                      .filter((option) => !selectedOptions.includes(option))
                      .map((option) => (
                        <TagPickerOption
                          media={<Avatar name={option} color="colorful" />}
                          value={option}
                          key={option}
                        >
                          {option}
                        </TagPickerOption>
                      ))}
                  </TagPickerList>
                </TagPicker>
              </Field>
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <Button
              onClick={() => {
                updateVotedResponse();
                let vt = { ...votedResponse };
                vt.disabled = !votedResponse.disabled;
                setActiveVotedResponse(vt);
              }}
              appearance="outline"
              style={{ marginTop: "20px" }}
            >
              {votedResponse.disabled ? "Enable" : "Disable"}
            </Button>
          </Stack.Item>
          <Stack.Item>
            <Tooltip content={"Tootip Contents goes here"} relationship="label">
              <Card
                style={{
                  backgroundColor: customTokens.messageBotAndMenuBackground,
                }}
              >
                <Text style={{ display: "flex", justifyContent: "center" }}>
                  Disabled:
                  <CheckmarkCircle20Regular
                    style={{
                      marginLeft: "20px",
                      color: votedResponse?.disabled ? "green" : "red",
                    }}
                  />
                </Text>
                <Text
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  Up Voted:
                  <CheckmarkCircle20Regular
                    style={{
                      marginLeft: "20px",
                      color: votedResponse?.upvoted ? "green" : "red",
                    }}
                  />
                </Text>
              </Card>
            </Tooltip>
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <h3>Response</h3> <br />
        <Markdown remarkPlugins={[remarkGfm]}>
          {votedResponse.response.value}
        </Markdown>
      </Stack.Item>
    </Stack>
  );
}
