import { Dispatch, SetStateAction, createContext } from "react";
import {
  ServiceNowTicket,
  ServiceNowUser,
  Conversation,
  User,
  Message,
  Transcript,
} from "../../../__generated__/graphql";
import {
  ApolloError,
  LazyQueryHookExecOptions,
  MutationHookOptions,
  MutationResult,
  OperationVariables,
  QueryResult,
} from "@apollo/client";
import { MessageTypes } from "../../ChatView";

export enum ELoginState {
  ERROR = -1, // Login error state, shouldn't be used, but let's see
  LOGIN_PAGE = 0, // The application shows the login page
  LOGGING_IN = 1, // In any login flow the application is verifying credentials
  LOADING_USER = 2, // credentials verified, user data and conversation data is pulled
  INITIATE_SUBSCRIPTIONS = 3, // Once queries finish, start subscriptions
  READY = 4, // User logged in, not further login actions
  LOGGING_OUT = 5, // Triggers logout
}

export enum ERightDrawerState {
  CLOSED = 0,
  CALLER = 1,
  TICKETS = 2,
  ASSIST = 3,
}

export enum EConversationType {
  CHAT = 1,
  CALL = 2,
}

interface IAppClientContextState {
  loginState: ELoginState;
  conversationList: Conversation[];
  conversationListLoading: boolean;
  conversationListError: ApolloError | undefined;
  selectedConversationId: string;
  selectedConversationData: any | undefined;
  selectedConversationLoading: boolean;
  selectedConversationError: ApolloError | undefined;
  messages: Message[];
  messagesLoading: boolean;
  messagesError: ApolloError | undefined;
  conversationsProccessed: string[];
  debugMode: boolean;
  validationMode: boolean;
  isScrolling: boolean;
  conversationType: EConversationType | null;
  renderDrawer: any;
  callerData: any;
  ticketData: ServiceNowTicket[] | null;
  ticketLoading: boolean;
  ticketError: ApolloError | undefined;
  aiAssistData: Transcript | null;
  conversationTokens: string;
  setConversationTokens: any;
  rightDrawerState: ERightDrawerState;
  aiAssistLoading: boolean;
  aiAssistError: ApolloError | undefined;
  resetDrawer: any;
  currentConversationMessages: Message[];
  chatLoading: boolean;
  loadingBubbleDisplayMessage: string | null;
  fontSizeValue: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
  isSidebarCollapsed: boolean;
  callIntentLoading: boolean;
  cacheAiAssistData: any;
}
interface IAppClientContextActions {
  setCallIntentLoading: Dispatch<SetStateAction<boolean>>;
  setLoginState: Dispatch<SetStateAction<ELoginState>>;
  updateConversationList: any;
  refetchSelectedConversation: any;
  addConversation: Dispatch<SetStateAction<Conversation[]>>;
  setSelectedConversationId: Dispatch<SetStateAction<string>>;
  updateMessages: any;
  setConversationsProccessed: Dispatch<SetStateAction<string[]>>;
  setDebugMode: Dispatch<SetStateAction<boolean>>;
  setValidationMode: Dispatch<SetStateAction<boolean>>;
  identifyCaller: any;
  setIsScrolling: Dispatch<SetStateAction<boolean>>;
  setConversationType: Dispatch<SetStateAction<EConversationType | null>>;
  setRightDrawerState: Dispatch<SetStateAction<ERightDrawerState>>;
  refetchIdentifyCaller: any;
  updateAiAssistData: (
    options?: Partial<MutationHookOptions> | undefined
  ) => Promise<MutationResult<any> | OperationVariables>;
  setCurrentConversationMessages: Dispatch<SetStateAction<Message[]>>;
  setChatLoading: Dispatch<SetStateAction<boolean>>;
  getSelectedConversationsTranscriptId: () => string;
  displayLoadingBubbleMessage: (type: MessageTypes) => void;
  setFontSizeValue: Dispatch<
    SetStateAction<100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900>
  >;
  setIsSidebarCollapsed: Dispatch<SetStateAction<boolean>>;
  getLatestTickets: any;
  setCacheAiAssistData: Dispatch<SetStateAction<any>>;
}

export type IAppClientContext = IAppClientContextActions &
  IAppClientContextState;

export const AppClientContext = createContext<IAppClientContext | undefined>(
  undefined
);
