import { getTokenRole } from "../AgentChatModule/helpers/tokenFunctions";
import ErrorBoundary from "./ErrorBoundary";

export enum ROLES {
  user = "USER",
  admin = "ADMIN",
}

interface PrivateRouteComponent {
  children: any;
  roles: ROLES[];
}

export const PrivateRoute = ({ children, roles }: PrivateRouteComponent) => {
  const userTokenRole = getTokenRole();
  const userHasRequiredRole = roles.includes(userTokenRole) ? true : false;
  if (!userHasRequiredRole) {
    return <ErrorBoundary />;
  }
  return children;
};

export const PrivateComponent = ({
  children,
  roles,
}: PrivateRouteComponent) => {
  const userTokenRole = getTokenRole();
  const userHasRequiredRole = roles.includes(userTokenRole) ? true : false;
  if (!userHasRequiredRole) {
    return null;
  }
  return children;
};
