import { Stack } from "@fluentui/react";
import {
  Input as TextField,
  ProgressBar,
  Textarea,
  Button,
} from "@fluentui/react-components";
import { Send16Filled, Camera20Regular } from "@fluentui/react-icons";
import { chatFooterStyles } from "./index";
import { SetStateAction, useEffect, useRef, useState } from "react";
import { useMutation, useApolloClient } from "@apollo/client";
import { ulid } from "ulid";
import {
  AI_REPLY,
  UPDATE_CONVERSATION,
  GET_MESSAGES,
  GET_CONVERSATIONS,
} from "../helpers/graphql";
import { useAppClient, EConversationType } from "../services/appClient";
import { Message, MessageType } from "../../__generated__/graphql";
import { createWorker } from "tesseract.js";
import autosize from "autosize";
import { Dispatch } from "react";
import { textBoxesPerConversation } from "../..";
import { useAuthenticationContext } from "../../AuthenticationService/useAuthentication";

export default function Footer({
  setTextAreaWorking,
}: {
  setTextAreaWorking: Dispatch<SetStateAction<boolean>>;
}) {
  const styles = chatFooterStyles();

  const client = useApolloClient();

  function writeTextBoxToQuery(ms: string) {
    let convos = textBoxesPerConversation();

    const conversation = convos.filter(
      (c: any) => c.conversationId == selectedConversationId
    )[0];

    if (conversation) {
      const index = convos.indexOf(conversation);
      convos[index].message = ms;
      convos[index].textAreaActive = textAreaActive;
      textBoxesPerConversation(convos);
    } else {
      const newMessage = {
        conversationId: selectedConversationId,
        message: ms,
        textAreaActive: textAreaActive,
      };

      textBoxesPerConversation([...textBoxesPerConversation(), newMessage]);
    }
  }

  const { user } = useAuthenticationContext();
  const {
    selectedConversationId,
    setConversationsProccessed,
    conversationsProccessed,
    currentConversationMessages,
    setCurrentConversationMessages,
    setChatLoading,
    updateConversationList,
    updateMessages,
    messages,
    conversationType,
    setConversationTokens,
    setIsScrolling,
    displayLoadingBubbleMessage,
  } = useAppClient();

  const [message, setMessage] = useState<string>("");
  const [ocrProgress, setOcrProgress] = useState<number>(0);
  const [ocrRunning, setOcrRunning] = useState<boolean>(false);
  const textAreaRef = useRef(null);
  const [textAreaActive, setTextAreaActive] = useState<boolean>(false);

  useEffect(() => {
    if (selectedConversationId) {
      const conversation = textBoxesPerConversation().filter(
        (c: any) => c.conversationId == selectedConversationId
      );

      if (conversation.length) {
        setMessage(conversation[0].message);
        setTextAreaActive(conversation[0].textAreaActive);
      } else {
        setMessage("");
      }
    }
  }, [selectedConversationId]);

  const [updateConversation] = useMutation(UPDATE_CONVERSATION, {
    refetchQueries: [GET_MESSAGES, GET_CONVERSATIONS],
    variables: {
      id: selectedConversationId,
      name: message.slice(0, 25),
    },
    onCompleted: () => {
      updateConversationList();
    },
  });

  const [aiReply] = useMutation(AI_REPLY, {
    variables: {
      conversationId: selectedConversationId,
      message: message,
    },

    onCompleted: async ({ aiReply }) => {
      let processed = conversationsProccessed.filter(
        (s: string) => s !== aiReply.conversation.id
      );
      displayLoadingBubbleMessage(aiReply.type);
      if (selectedConversationId == aiReply.conversation.id) {
        setConversationTokens("");
      }

      setConversationsProccessed(processed);
      await updateMessages({
        conversationId: selectedConversationId,
      });
      updateConversationList({
        variables: { userId: user.id, feDeleted: false },
        fetchPolicy: "cache-and-network",
      });
    },
    onError: (error) => {
      setConversationsProccessed([]);
      setConversationTokens("");
    },
  });

  async function handleSend() {
    if (!message) {
      return;
    }

    writeTextBoxToQuery("");
    setConversationTokens("");

    if (messages.length <= 0 && conversationType != EConversationType.CALL) {
      updateConversation();
    }

    const newMessage: Message = {
      id: ulid(),
      conversation: {
        id: selectedConversationId,
      },
      botMessage: false,
      created: `${new Date().toISOString().slice(0, 19).replace("T", " ")}`,
      received: false,
      type: MessageType.Message,
      value: message,
      kbArticleInstances: [],
      exchange: {
        evaluation: 0,
        id: ulid(),
      },
    };

    setTimeout(async () => {
      await client.refetchQueries({
        include: [GET_MESSAGES],
      });
    }, 1000);

    setCurrentConversationMessages([
      ...currentConversationMessages,
      ...[newMessage],
    ]);

    setChatLoading(true);
    setIsScrolling(true);

    aiReply();

    setConversationsProccessed([
      ...conversationsProccessed,
      selectedConversationId,
    ]);
    setMessage("");
  }

  const hiddenFileInput: any = useRef(null);
  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const ocrData = async (fileUploaded: any) => {
    setOcrRunning(true);
    const worker = await createWorker("eng", 1, {
      logger: (m) => {
        if (m.status === "recognizing text") {
          setOcrProgress(m.progress * 100);
        }
      },
    });
    const ret = await worker.recognize(fileUploaded);
    setMessage(ret.data.text);
    await worker.terminate();
    setOcrRunning(false);
    setOcrProgress(0);
  };

  const handleChange = async (event: any) => {
    const fileUploaded = await event.target.files[0];
    await ocrData(fileUploaded);
    event.target.value = null;
  };

  useEffect(() => {
    window.addEventListener("paste", async (e: any) => {
      if (e.clipboardData.files.length >= 1) {
        const fileUploaded = await e.clipboardData.files[0];
        await ocrData(fileUploaded);
      }
    });
  }, []);

  function moveCaretAtEnd(e: any) {
    var temp_value = e.target.value;
    e.target.value = "";
    e.target.value = temp_value;
  }

  useEffect(() => {
    if (message.length <= 0) {
      setTextAreaActive(false);
    }
    if (message.length >= 75 || textAreaActive) {
      setTextAreaWorking(true);
      if (textAreaRef && textAreaRef.current) {
        autosize(textAreaRef.current);
      }
    } else {
      setTextAreaWorking(false);
    }
  }, [message]);

  return (
    <Stack
      horizontal
      horizontalAlign="center"
      verticalAlign="center"
      className={styles.rootStack}
    >
      <Stack verticalAlign="end" className={styles.justifyStack}>
        {ocrRunning && (
          <ProgressBar
            shape="rounded"
            max={100}
            value={ocrProgress}
            style={{ marginBottom: "1px", width: "96%", marginLeft: "1.2%" }}
          />
        )}

        {message.length <= 75 && !textAreaActive ? (
          <TextField
            autoComplete="off"
            input={{
              autoComplete: "off",
            }}
            data-testId="chat-footer"
            autoFocus
            contentAfter={
              <Stack style={{ marginRight: "-15px" }} horizontal>
                <Camera20Regular
                  style={{
                    color: "#0070AD",
                    cursor: "pointer",
                    marginRight: "10px",
                  }}
                  onClick={handleClick}
                />
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleChange}
                  ref={hiddenFileInput}
                  className={styles.noDisplay}
                  title="Upload Image"
                />
                <Send16Filled
                  data-testId="chat-footer-submit-button"
                  onClick={handleSend}
                  style={{
                    color: "#0070AD",
                    cursor: "pointer",
                    marginTop: "1.5px",
                  }}
                />
              </Stack>
            }
            id="chat-footer"
            placeholder="Type here"
            className={styles.textField}
            onKeyUp={(e: any) => {
              if (e.key === "Enter" && e.shiftKey) {
                setTextAreaActive(true);
              }
              if (e.key === "Enter" && !e.shiftKey) {
                handleSend();
              }
            }}
            value={message}
            onChange={(_: any, newValue: any) => {
              setMessage(newValue.value);
              writeTextBoxToQuery(newValue.value);
            }}
          ></TextField>
        ) : (
          <>
            <Stack style={{ height: "32px" }}></Stack>
            <Stack verticalAlign="end" horizontalAlign="center">
              <Stack
                horizontal
                horizontalAlign="end"
                verticalAlign="end"
                style={{ position: "fixed" }}
              >
                <Textarea
                  className={styles.textField}
                  resize="vertical"
                  as="textarea"
                  ref={textAreaRef}
                  id="chat-footer-textarea"
                  autoFocus
                  textarea={{
                    style: { maxHeight: "450px" },
                  }}
                  placeholder="Type here"
                  value={message}
                  onChange={(e: any) => {
                    setMessage(e.target.value);
                    writeTextBoxToQuery(e.target.value);
                  }}
                  onKeyUp={(e: any) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      handleSend();
                      setTextAreaActive(false);
                    }
                  }}
                  onFocus={(e) => moveCaretAtEnd(e)}
                ></Textarea>
                <Button
                  size="small"
                  appearance="transparent"
                  onClick={handleSend}
                  icon={
                    <Send16Filled
                      style={{
                        color: "#0070AD",
                        cursor: "pointer",
                        marginTop: "1.5px",
                      }}
                    />
                  }
                  style={{
                    position: "absolute",
                    marginBottom: "3.2px",
                    marginRight: "14px",
                    // marginLeft: "34vw",
                  }}
                />
                <Button
                  size="small"
                  appearance="transparent"
                  onClick={handleClick}
                  icon={
                    <Camera20Regular
                      style={{
                        color: "#0070AD",
                        cursor: "pointer",
                      }}
                    />
                  }
                  style={{
                    position: "absolute",
                    marginBottom: "3px",
                    marginRight: "40px",
                  }}
                />
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleChange}
                  ref={hiddenFileInput}
                  className={styles.noDisplay}
                  title="Upload Image"
                />
              </Stack>
            </Stack>
          </>
          // </Stack>
        )}
      </Stack>
    </Stack>
  );
}
