export const tags: string[] = [
  "Support",
  "Tickets",
  "Alerts",
  "Incidents",
  "Assets",
  "Backup",
  "Config",
  "Patching",
  "Queue",
  "Network",
  "Login",
  "Resolve",
  "Access",
  "Monitor",
  "Deploy",
  "Server",
  "Status",
  "Outage",
  "Restore",
  "Setup",
  "Update",
  "Request",
  "Downtime",
  "Reports",
  "SLA",
  "Log",
  "Tasks",
  "Escalate",
  "Endpoint",
];

export interface KbaFile {
  id: string;
  name: string;
  shortDescription: string;
  format: string;
  tags: string[];
  content: string;
  questions: string[];
  forReview: boolean;
  forDeletion: boolean;
}

export const kbaFiles: KbaFile[] = [
  {
    id: "1",
    name: "Citrix Troubleshooting.txt",
    format: "txt",
    tags: ["Support", "Tickets", "Alerts"],
    content:
      "To troubleshoot Citrix issues, first check your internet connection and ensure it’s stable. Next, restart Citrix Workspace or the Citrix Receiver, and verify it's up-to-date. Clear the cache and temporary files in Citrix, and if needed, reinstall the application. If issues persist, contact your IT support team, as the server may be experiencing issues or additional permissions may be needed.",
    questions: [
      "Why am I getting a 'Cannot Connect to Server' error in Citrix?",
      "How can I fix Citrix not launching applications?",
      "What should I do if Citrix freezes on the loading screen?",
      "How can I resolve Citrix Workspace issues on Windows?",
      "Why does Citrix keep disconnecting or timing out?",
    ],
    forReview: false,
    forDeletion: false,
    shortDescription: "Citrix troubleshooting steps",
  },
  {
    id: "2",
    name: "Outlook Issue.pdf",
    format: "pdf",
    tags: ["Incidents", "Assets", "Backup"],
    content:
      "To troubleshoot Outlook issues, start by restarting the app and checking for any available updates. If Outlook won't open, try running it in safe mode by holding down the 'Ctrl' key as you start it. Clear your cache, remove unnecessary add-ins, and make sure your internet connection is stable. If password prompts keep appearing, check your account settings and re-enter your credentials. For persistent issues, consider creating a new profile in Outlook or reinstalling the application. If these steps don’t help, reach out to your IT team for further assistance.",
    questions: [
      "Why isn't my Outlook loading or opening?",
      "How can I fix Outlook when it keeps asking for my password?",
      "What should I do if Outlook emails are stuck in the outbox?",
      "How can I resolve issues with Outlook not syncing my emails?",
      "Why is Outlook crashing or freezing frequently?",
    ],
    forReview: false,
    forDeletion: false,
    shortDescription: "Outlook troubleshooting steps",
  },
  {
    id: "3",
    name: "Windows Troubleshooting.docx",
    format: "docx",
    tags: ["Config", "Patching", "Queue"],
    content:
      "To troubleshoot Windows issues, start by restarting your computer and ensuring it's updated with the latest Windows updates. For performance issues, check for unnecessary background processes in Task Manager and run a disk cleanup. If Windows isn’t starting, try booting in Safe Mode and running diagnostics. For Wi-Fi or blue screen errors, update your drivers in Device Manager and consider running the Windows Troubleshooter for specific issues. For persistent issues, a System Restore or reinstalling Windows may be necessary. Contact support if problems continue.",
    questions: [
      "Why is my Windows computer running so slowly?",
      "How can I fix Windows if it's not starting up?",
      "What should I do if Windows keeps freezing or crashing?",
      "How do I resolve issues with Windows not connecting to Wi-Fi?",
      "Why am I getting a blue screen error on Windows?",
    ],
    forReview: false,
    forDeletion: false,
    shortDescription: "windows troubleshooting steps",
  },
];

export const tickets: KbaFile[] = [
  {
    id: "4",
    name: "VPN Connectivity Issues",
    format: "txt",
    tags: ["Network", "Remote Access", "Security"],
    content:
      "If the VPN is not connecting, start by ensuring you have an active internet connection. Restart the VPN client and check for any pending updates. Verify your VPN credentials are correct and check firewall settings to ensure they’re not blocking the VPN. If the issue persists, try reinstalling the VPN client or contact IT support.",
    questions: [
      "Why is my VPN not connecting?",
      "How can I fix VPN disconnections?",
      "What should I do if VPN keeps timing out?",
      "How do I troubleshoot slow VPN speeds?",
      "Why can't I access company resources over VPN?",
    ],
    forReview: false,
    forDeletion: false,
    shortDescription: "Troubleshooting VPN connectivity issues",
  },
  {
    id: "5",
    name: "Printer Not Responding",
    format: "pdf",
    tags: ["Hardware", "Printing", "Support"],
    content:
      "To troubleshoot printer issues, first check if the printer is online and connected to the network. Restart the printer and ensure there is enough paper and ink. Check the print queue for any stalled jobs. If the issue persists, reinstall the printer driver or try a different USB port.",
    questions: [
      "Why isn't my printer responding?",
      "How do I clear stalled print jobs?",
      "Why is my printer showing 'offline'?",
      "What to do if the printer won't connect to Wi-Fi?",
      "How can I fix print quality issues?",
    ],
    forReview: false,
    forDeletion: false,
    shortDescription: "Troubleshooting printer issues",
  },
  {
    id: "6",
    name: "Slow Internet Connection",
    format: "docx",
    tags: ["Network", "Performance", "Connectivity"],
    content:
      "If experiencing slow internet, first restart your modem and router. Disconnect other devices from the network to reduce bandwidth usage. Run a speed test and compare it to your expected speed. If the issue persists, contact your ISP or consider upgrading your internet plan.",
    questions: [
      "Why is my internet so slow?",
      "How can I improve internet speed?",
      "What should I do if my Wi-Fi keeps dropping?",
      "Why can't I connect to certain websites?",
      "How do I resolve intermittent connectivity issues?",
    ],
    forReview: false,
    forDeletion: false,
    shortDescription: "Internet speed troubleshooting steps",
  },
  {
    id: "7",
    name: "File Sharing Permissions",
    format: "txt",
    tags: ["Permissions", "Files", "Access Control"],
    content:
      "For issues with file sharing permissions, ensure you have correct access rights on the shared folder. Check if the file is open by another user and adjust sharing settings if needed. For network drives, verify the path and your permissions in the system's settings.",
    questions: [
      "Why can't I access a shared file?",
      "How do I change file permissions?",
      "What to do if file sharing is not working?",
      "How can I fix 'Access Denied' errors?",
      "Why can't I share a folder on the network?",
    ],
    forReview: false,
    forDeletion: false,
    shortDescription: "File sharing and permission issues",
  },
  {
    id: "8",
    name: "Email Delivery Failure",
    format: "pdf",
    tags: ["Email", "Exchange", "Connectivity"],
    content:
      "If emails aren’t being delivered, check if the email server is online and if there’s enough storage space in your mailbox. Verify that the recipient’s email address is correct, and ensure no restrictions are set on your account. Check for any server error messages and contact IT if necessary.",
    questions: [
      "Why are my emails not sending?",
      "How to troubleshoot email delivery failures?",
      "What to do if emails are bouncing back?",
      "Why can't I receive emails from certain addresses?",
      "How can I fix delayed email delivery?",
    ],
    forReview: false,
    forDeletion: false,
    shortDescription: "Troubleshooting email delivery issues",
  },
  {
    id: "9",
    name: "Software Installation Failure",
    format: "docx",
    tags: ["Software", "Installation", "Errors"],
    content:
      "If software won’t install, check if the system meets the software's requirements. Run the installer as administrator, disable antivirus temporarily, and ensure there’s sufficient disk space. For persistent issues, review error logs or contact IT for further assistance.",
    questions: [
      "Why can't I install the software?",
      "How do I fix installation errors?",
      "What should I do if setup fails to start?",
      "How can I solve 'Access Denied' during installation?",
      "Why is my antivirus blocking software installation?",
    ],
    forReview: false,
    forDeletion: false,
    shortDescription: "Troubleshooting software installation issues",
  },
  {
    id: "10",
    name: "Network Drive Disconnected",
    format: "txt",
    tags: ["Network", "Drives", "Access"],
    content:
      "To troubleshoot a disconnected network drive, first reconnect to the network. Check if the drive mapping is correct and the server hosting the drive is online. Update any credentials if prompted and restart your system if the issue persists.",
    questions: [
      "Why is my network drive not accessible?",
      "How do I map a network drive?",
      "What should I do if the network drive keeps disconnecting?",
      "How can I fix 'Drive Unavailable' errors?",
      "Why can't I see shared drives on the network?",
    ],
    forReview: false,
    forDeletion: false,
    shortDescription: "Resolving network drive issues",
  },
  {
    id: "11",
    name: "Bluetooth Device Not Pairing",
    format: "pdf",
    tags: ["Hardware", "Bluetooth", "Connectivity"],
    content:
      "If a Bluetooth device won’t pair, make sure Bluetooth is enabled and the device is discoverable. Restart both devices and try again. Check for any updates to your Bluetooth drivers and clear the device from your paired list before reconnecting.",
    questions: [
      "Why won't my Bluetooth connect?",
      "How to fix Bluetooth pairing issues?",
      "What should I do if my Bluetooth device is not discoverable?",
      "Why does my Bluetooth connection keep dropping?",
      "How can I improve Bluetooth connectivity?",
    ],
    forReview: false,
    forDeletion: false,
    shortDescription: "Bluetooth troubleshooting steps",
  },
  {
    id: "12",
    name: "Disk Space Running Low",
    format: "txt",
    tags: ["Storage", "Performance", "Cleanup"],
    content:
      "If disk space is low, start by deleting temporary files, clearing the recycle bin, and uninstalling unused applications. Use disk cleanup utilities to clear cached data, and if necessary, consider upgrading to a larger drive or moving files to cloud storage.",
    questions: [
      "Why is my disk space low?",
      "How can I free up storage space?",
      "What should I delete to recover disk space?",
      "How do I use Disk Cleanup?",
      "Why is my disk usage high with no files?",
    ],
    forReview: false,
    forDeletion: false,
    shortDescription: "Low disk space troubleshooting",
  },
  {
    id: "13",
    name: "Password Reset Not Working",
    format: "docx",
    tags: ["Access", "Security", "Credentials"],
    content:
      "If a password reset is failing, confirm the security questions or email are correctly entered. Ensure your account is not locked and check for any required two-factor authentication. Contact IT support if issues persist.",
    questions: [
      "Why can't I reset my password?",
      "How do I unlock my account?",
      "What should I do if I forgot my security answers?",
      "How can I enable two-factor authentication?",
      "Why am I not receiving password reset emails?",
    ],
    forReview: false,
    forDeletion: false,
    shortDescription: "Password reset troubleshooting steps",
  },
];

export const insights: KbaFile[] = [
  {
    id: "14",
    name: "Best Practices for Data Security",
    format: "pdf",
    tags: ["Security", "Data Protection", "Compliance"],
    content:
      "To enhance data security, enforce strong password policies, regularly update software, and enable two-factor authentication. Encourage employees to follow cybersecurity protocols and avoid suspicious links. Regularly audit access to sensitive data and conduct security training.",
    questions: [
      "What are essential practices for data security?",
      "How does two-factor authentication improve security?",
      "Why is employee training important for data protection?",
      "What are common data security vulnerabilities?",
      "How can businesses prevent data breaches?",
    ],
    forReview: false,
    forDeletion: false,
    shortDescription: "Best practices for securing organizational data",
  },
  {
    id: "15",
    name: "Cloud Computing Trends",
    format: "txt",
    tags: ["Cloud", "Trends", "IT Infrastructure"],
    content:
      "Cloud computing trends include the rise of multi-cloud strategies, serverless architectures, and AI-driven cloud management. Hybrid cloud models are popular for balancing on-premises and cloud workloads, while edge computing reduces latency by processing data closer to users.",
    questions: [
      "What are current trends in cloud computing?",
      "How does a multi-cloud strategy benefit organizations?",
      "What is serverless architecture?",
      "How does edge computing improve performance?",
      "Why are hybrid clouds becoming popular?",
    ],
    forReview: false,
    forDeletion: false,
    shortDescription: "Emerging trends in cloud computing",
  },
  {
    id: "16",
    name: "Improving IT Service Desk Efficiency",
    format: "docx",
    tags: ["Support", "Service Desk", "Efficiency"],
    content:
      "To improve service desk efficiency, prioritize ticket routing based on urgency, implement self-service portals for common issues, and use automation to reduce repetitive tasks. Regularly review ticket trends to address recurring issues proactively.",
    questions: [
      "How can we improve service desk efficiency?",
      "What are benefits of self-service portals?",
      "How does automation help in IT support?",
      "Why is analyzing ticket trends important?",
      "How can ticket routing optimize support?",
    ],
    forReview: false,
    forDeletion: false,
    shortDescription: "Best practices for an efficient IT service desk",
  },
  {
    id: "17",
    name: "Understanding Zero Trust Security",
    format: "pdf",
    tags: ["Security", "Zero Trust", "Network"],
    content:
      "Zero Trust is a security model that assumes no user or system is trusted by default. It involves verifying users, monitoring activity, and controlling access based on identity and device. Zero Trust reduces the risk of breaches by limiting access across the network.",
    questions: [
      "What is Zero Trust security?",
      "How does Zero Trust reduce security risks?",
      "Why is Zero Trust beneficial for businesses?",
      "What are key components of a Zero Trust model?",
      "How is access managed in Zero Trust?",
    ],
    forReview: false,
    forDeletion: false,
    shortDescription: "An overview of the Zero Trust security model",
  },
  {
    id: "18",
    name: "IT Asset Management Best Practices",
    format: "txt",
    tags: ["Asset Management", "Best Practices", "IT Operations"],
    content:
      "Effective IT asset management includes regular inventory audits, tracking software licenses, and setting lifecycle management for hardware. Automated asset tracking tools can streamline processes and reduce risks of unlicensed software.",
    questions: [
      "What is IT asset management?",
      "How can asset management reduce costs?",
      "Why is inventory auditing important?",
      "What are lifecycle management practices?",
      "How can automation assist in asset tracking?",
    ],
    forReview: false,
    forDeletion: false,
    shortDescription: "Optimizing IT asset management practices",
  },
  {
    id: "19",
    name: "Enhancing Cybersecurity Awareness",
    format: "docx",
    tags: ["Cybersecurity", "Training", "Awareness"],
    content:
      "Promoting cybersecurity awareness involves regular training on phishing, secure password practices, and identifying suspicious activity. Regularly updating employees on new threats and conducting simulated phishing attacks can strengthen security habits.",
    questions: [
      "Why is cybersecurity awareness essential?",
      "What are effective ways to train employees?",
      "How can simulated attacks improve readiness?",
      "What are common signs of phishing attempts?",
      "How often should cybersecurity training occur?",
    ],
    forReview: false,
    forDeletion: false,
    shortDescription: "Building cybersecurity awareness among employees",
  },
  {
    id: "20",
    name: "Digital Transformation in IT",
    format: "txt",
    tags: ["Digital Transformation", "Innovation", "IT Strategy"],
    content:
      "Digital transformation involves integrating digital technologies into all areas of business. Embracing automation, AI, and cloud-based solutions can streamline operations, improve efficiency, and drive innovation.",
    questions: [
      "What is digital transformation?",
      "How does digital transformation benefit companies?",
      "What role does AI play in transformation?",
      "Why is cloud technology essential for transformation?",
      "How does digitalization improve customer experience?",
    ],
    forReview: false,
    forDeletion: false,
    shortDescription: "IT's role in digital transformation initiatives",
  },
  {
    id: "21",
    name: "Incident Management Framework",
    format: "pdf",
    tags: ["Incident Management", "ITSM", "Framework"],
    content:
      "An incident management framework involves structured steps for identifying, documenting, and resolving incidents. Key components include classification, prioritization, and escalation to restore service quickly and reduce downtime.",
    questions: [
      "What is an incident management framework?",
      "How does incident management reduce downtime?",
      "What are steps in incident classification?",
      "Why is prioritizing incidents important?",
      "How does escalation work in incident management?",
    ],
    forReview: false,
    forDeletion: false,
    shortDescription: "Framework for managing IT incidents effectively",
  },
  {
    id: "22",
    name: "Managing Remote Work IT Challenges",
    format: "docx",
    tags: ["Remote Work", "Support", "Productivity"],
    content:
      "Common remote work challenges include managing VPN connectivity, securing devices, and supporting collaboration tools. Ensure access to reliable remote support, enforce security protocols, and use tools that promote team connectivity.",
    questions: [
      "What are common IT challenges in remote work?",
      "How to ensure secure remote connections?",
      "What tools improve remote team collaboration?",
      "How does IT support remote workers?",
      "Why is VPN essential for remote work security?",
    ],
    forReview: false,
    forDeletion: false,
    shortDescription: "Overcoming IT challenges in remote work environments",
  },
  {
    id: "23",
    name: "Leveraging Big Data Analytics",
    format: "txt",
    tags: ["Big Data", "Analytics", "Insights"],
    content:
      "Big Data Analytics enables companies to analyze large datasets for insights into customer behavior, operational efficiency, and trends. Key technologies include data warehouses, machine learning algorithms, and real-time processing.",
    questions: [
      "What is Big Data Analytics?",
      "How can big data improve decision-making?",
      "What tools are essential for data analysis?",
      "How does machine learning enhance analytics?",
      "Why is real-time data important?",
    ],
    forReview: false,
    forDeletion: false,
    shortDescription: "Using big data analytics to drive insights",
  },
  {
    id: "24",
    name: "Business Continuity Planning (BCP)",
    format: "pdf",
    tags: ["BCP", "Risk Management", "Disaster Recovery"],
    content:
      "A business continuity plan (BCP) prepares an organization to maintain operations during unexpected events. BCP includes disaster recovery, backup systems, and risk assessment to ensure rapid recovery.",
    questions: [
      "What is business continuity planning?",
      "Why is disaster recovery essential?",
      "How do you assess business risks?",
      "What are key components of BCP?",
      "How often should a BCP be tested?",
    ],
    forReview: false,
    forDeletion: false,
    shortDescription: "Overview of business continuity planning",
  },
  {
    id: "25",
    name: "Mobile Device Management (MDM)",
    format: "docx",
    tags: ["MDM", "Device Management", "Security"],
    content:
      "Mobile Device Management (MDM) allows IT to monitor, manage, and secure mobile devices used within the company. Key features include remote device wiping, app management, and enforcing security policies on devices.",
    questions: [
      "What is Mobile Device Management?",
      "Why is MDM important for businesses?",
      "How does MDM enhance security?",
      "What are best practices for device management?",
      "How can devices be wiped remotely with MDM?",
    ],
    forReview: false,
    forDeletion: false,
    shortDescription: "Best practices for mobile device management",
  },
  {
    id: "26",
    name: "Data Loss Prevention (DLP)",
    format: "txt",
    tags: ["DLP", "Data Security", "Compliance"],
    content:
      "Data Loss Prevention (DLP) strategies are designed to prevent sensitive data from being accessed or leaked. DLP tools monitor data flow and apply rules to prevent unauthorized data sharing or transfers.",
    questions: [
      "What is Data Loss Prevention?",
      "How does DLP protect data?",
      "Why is DLP necessary for compliance?",
      "What are key features of DLP solutions?",
      "How to implement DLP in an organization?",
    ],
    forReview: false,
    forDeletion: false,
    shortDescription: "Strategies for preventing data loss",
  },
  {
    id: "27",
    name: "Artificial Intelligence in IT Operations (AIOps)",
    format: "pdf",
    tags: ["AI", "AIOps", "IT Automation"],
    content:
      "AIOps integrates AI into IT operations for proactive monitoring, anomaly detection, and automated responses. It helps IT teams identify issues faster and reduce manual intervention, improving efficiency.",
    questions: [
      "What is AIOps?",
      "How does AI help in IT operations?",
      "Why is AIOps beneficial for large organizations?",
      "What are use cases of AIOps?",
      "How does AIOps reduce IT workloads?",
    ],
    forReview: false,
    forDeletion: false,
    shortDescription: "Using AI to improve IT operations",
  },
  // {
  //   id: "28",
  //   name: "Building a Secure BYOD Policy",
  //   format: "docx",
  //   tags: ["BYOD", "Policy", "Security"],
  //   content:
  //     "Bring Your Own Device (BYOD) policies require controls to secure employee-owned devices accessing company resources. Enforcing encryption, remote wiping, and security updates are essential for protecting data.",
  //   questions: [
  //     "What is a BYOD policy?",
  //     "Why is security essential in BYOD?",
  //     "How to enforce BYOD compliance?",
  //     "What are risks of BYOD in IT?",
  //     "How does MDM support BYOD policies?",
  //   ],
  //   forReview: false,
  //   forDeletion: false,
  //   shortDescription: "Guidelines for a secure BYOD policy",
  // },
  // {
  //   id: "29",
  //   name: "Data Backup Strategies",
  //   format: "txt",
  //   tags: ["Data Backup", "Recovery", "Storage"],
  //   content:
  //     "Effective data backup strategies involve regular backups, off-site storage, and redundancy. Implement incremental or differential backups to save time, and perform routine tests to ensure data can be recovered.",
  //   questions: [
  //     "Why are data backups essential?",
  //     "What is the difference between full and incremental backups?",
  //     "How to test data backups?",
  //     "What are best practices for backup frequency?",
  //     "How does off-site storage add security?",
  //   ],
  //   forReview: false,
  //   forDeletion: false,
  //   shortDescription: "Best practices for data backup",
  // },
  // {
  //   id: "30",
  //   name: "Identity and Access Management (IAM)",
  //   format: "pdf",
  //   tags: ["IAM", "Security", "Access Control"],
  //   content:
  //     "Identity and Access Management (IAM) systems control user access to resources. IAM uses policies to authenticate users and assign roles, ensuring only authorized users have access to sensitive data.",
  //   questions: [
  //     "What is IAM?",
  //     "How does IAM enhance security?",
  //     "What are IAM best practices?",
  //     "Why is role-based access control important?",
  //     "How to implement IAM policies?",
  //   ],
  //   forReview: false,
  //   forDeletion: false,
  //   shortDescription: "Introduction to Identity and Access Management",
  // },
];
