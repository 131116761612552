import { IKbaArticle } from "./KbaDisplay";
import { Stack } from "@fluentui/react";
import {
  Text,
  Card,
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Switch,
  Avatar,
  Field,
} from "@fluentui/react-components";
import {
  CheckmarkCircle20Regular,
  QuestionCircle20Regular,
  ContentView20Regular,
} from "@fluentui/react-icons";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { customTokens } from "../../../theme";
import { useMutation } from "@apollo/client";
import { UPDATE_KB_ARTICLE } from "../../network/graphql";
import { SetStateAction, useState, Dispatch } from "react"; // Do we need it ?
import { useEffect } from "react";
import { tags } from "../constants";
import { Tag } from "@fluentui/react-tags";

import {
  TagPicker,
  TagPickerList,
  TagPickerInput,
  TagPickerControl,
  TagPickerProps,
  TagPickerOption,
  TagPickerGroup,
} from "@fluentui/react-tag-picker-preview";

export function MainDisplay({
  kbaArticle,
  setArticle,
}: {
  kbaArticle: IKbaArticle;
  setArticle: Dispatch<SetStateAction<IKbaArticle>>;
}) {
  const [updateKbArticle] = useMutation(UPDATE_KB_ARTICLE);

  useEffect(() => {
    setSelectedOptions(kbaArticle.tags ? [...kbaArticle.tags] : []);
  }, [kbaArticle]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const onOptionSelect: TagPickerProps["onOptionSelect"] = (e, data) => {
    console.log(data);
    let file = { ...kbaArticle };
    file.tags = data.selectedOptions;
    setArticle(file);
    setSelectedOptions(data.selectedOptions);
  };
  const tagPickerOptions = tags.filter(
    (tag: string) => !selectedOptions.includes(tag)
  );

  return (
    <Stack
      style={{
        overflowY: "scroll",
        overflowX: "hidden",
        maxHeight: "85vh",
        minHeight: "85vh",
      }}
    >
      <Stack horizontal horizontalAlign="space-between">
        <Stack.Item style={{ display: "flex", justifyContent: "center" }}>
          <Card
            size="small"
            style={{
              backgroundColor: customTokens.messageBotAndMenuBackground,
            }}
          >
            <Text size={300} style={{ fontWeight: "bold" }}>
              {kbaArticle?.number}
            </Text>
            <Text size={200}>{kbaArticle?.shortDescription}</Text>
          </Card>
        </Stack.Item>
        <Stack.Item>
          <Stack style={{ marginTop: "4px", marginBottom: "4px" }}>
            <Field label="Edit Tags" style={{ maxWidth: 400 }}>
              <TagPicker
                onOptionSelect={onOptionSelect}
                selectedOptions={selectedOptions}
              >
                <TagPickerControl style={{ background: "transparent" }}>
                  <TagPickerGroup>
                    {selectedOptions.map((option) => (
                      <Tag
                        key={option}
                        shape="circular"
                        media={<Avatar name={option} color="colorful" />}
                        value={option}
                        // style={{ paddingLeft: "-10px" }}
                        size="small"
                      >
                        {option}
                      </Tag>
                    ))}
                  </TagPickerGroup>
                  <TagPickerInput />
                </TagPickerControl>
                <TagPickerList>
                  {tags
                    .filter((option) => !selectedOptions.includes(option))
                    .map((option) => (
                      <TagPickerOption
                        media={<Avatar name={option} color="colorful" />}
                        value={option}
                        key={option}
                      >
                        {option}
                      </TagPickerOption>
                    ))}
                </TagPickerList>
              </TagPicker>
            </Field>
          </Stack>
        </Stack.Item>
        <Stack.Item style={{ marginRight: 15 }}>
          <Field>
            <Switch
              label="Enabled"
              checked={kbaArticle?.forDeletion}
              onChange={() => {
                let art = { ...kbaArticle };
                art.forDeletion = !art.forDeletion;
                setArticle(art);
                updateKbArticle({
                  variables: {
                    id: kbaArticle?.id,
                    forDeletion: !kbaArticle?.forDeletion,
                    forReview: kbaArticle?.forReview,
                  },
                  onCompleted: (data) => {},
                  onError(error, clientOptions) {
                    console.error(error);
                    console.error(clientOptions);
                  },
                });
              }}
            />
          </Field>
          <Field>
            <Switch
              label="For Review"
              checked={kbaArticle?.forReview}
              onChange={() => {
                let art = { ...kbaArticle };
                art.forReview = !art.forReview;
                setArticle(art);
                updateKbArticle({
                  variables: {
                    id: kbaArticle?.id,
                    forDeletion: kbaArticle?.forDeletion,
                    forReview: !kbaArticle?.forReview,
                  },
                  onCompleted: (data) => {},
                  onError(error, clientOptions) {
                    console.error(error);
                    console.error(clientOptions);
                  },
                });
              }}
            />
          </Field>
        </Stack.Item>

        {/* <Stack.Item>
          <Card
            style={{
              backgroundColor: customTokens.messageBotAndMenuBackground,
            }}
          >
            <Text style={{ display: "flex", justifyContent: "space-between" }}>
              Enabled:
              <CheckmarkCircle20Regular
                style={{
                  marginLeft: "20px",
                  color: kbaArticle?.forDeletion ? "green" : "red",
                }}
              />
            </Text>
            <Text style={{ display: "flex", justifyContent: "space-between" }}>
              For Review:
              <CheckmarkCircle20Regular
                style={{
                  marginLeft: "20px",
                  color: kbaArticle?.forReview ? "green" : "red",
                }}
              />
            </Text>
          </Card>
        </Stack.Item> */}
      </Stack>
      <Accordion multiple collapsible defaultOpenItems={"questions"}>
        <AccordionItem value="questions">
          <AccordionHeader>
            <QuestionCircle20Regular
              style={{ marginRight: "5px", fontWeight: "bold" }}
            />{" "}
            <Text size={400} style={{ fontWeight: "bold" }}>
              KB Article Questions
            </Text>
          </AccordionHeader>
          <AccordionPanel>
            <Stack.Item>
              <Stack style={{ marginLeft: "auto", marginRight: "auto" }}>
                <Markdown remarkPlugins={[remarkGfm]}>
                  {kbaArticle?.questions}
                </Markdown>
              </Stack>
            </Stack.Item>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem value="content">
          <AccordionHeader>
            {" "}
            <ContentView20Regular style={{ marginRight: "5px" }} />
            <Text size={400} style={{ fontWeight: "bold" }}>
              KB Article Content
            </Text>
          </AccordionHeader>
          <AccordionPanel>
            <Stack.Item>
              <Markdown remarkPlugins={[remarkGfm]}>
                {kbaArticle?.content}
              </Markdown>
            </Stack.Item>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Stack>
  );
}
