import { Stack } from "@fluentui/react";
import {
  Input,
  Field,
  CompoundButton,
  Button,
  Switch,
  Menu,
  MenuTrigger,
  Tooltip,
  MenuButton,
  MenuPopover,
  MenuList,
  Avatar,
  MenuItem as FMenuItem,
} from "@fluentui/react-components";
import {
  Book20Regular,
  Settings20Regular,
  ArrowUpload20Regular,
} from "@fluentui/react-icons";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { customTokens } from "../../../theme";
import { tags } from "../constants";
import { KbaFile } from "../constants";
import { UploadModal } from "../UploadModal";
import { Tag } from "@fluentui/react-tags";

function MenuItem({
  kbaFile,
  setActiveFile,
  activeFile,
}: {
  kbaFile: KbaFile;
  setActiveFile: Dispatch<SetStateAction<KbaFile>>;
  activeFile: KbaFile;
}) {
  const [randomTags, setRandomTags] = useState<string[]>([...kbaFile.tags]);

  useEffect(() => {
    let art = { ...kbaFile };
    art.tags = randomTags;
  }, []);

  useEffect(() => {
    if (kbaFile.id === activeFile.id) {
      setRandomTags(activeFile.tags);
    }
  }, [activeFile, setActiveFile]);

  return (
    <Stack style={{ padding: "1px" }}>
      <CompoundButton
        icon={<Book20Regular />}
        // appearance="transparent"
        appearance={activeFile.id === kbaFile.id ? "primary" : "transparent"}
        style={{
          padding: "1px",
          textAlign: "left",
          alignContent: "flex-start",
          display: "flex",
          justifyContent: "flex-start",
        }}
        size="small"
        secondaryContent={
          <>
            {kbaFile?.shortDescription?.length >= 25
              ? kbaFile?.shortDescription.substr(0, 25) + " ..."
              : kbaFile?.shortDescription}
            <Stack horizontal style={{ paddingTop: "5px" }}>
              {randomTags.slice(0, 3).map((tag) => (
                <Tag
                  shape="circular"
                  size="small"
                  style={{ margin: "2px" }}
                  color="colorful"
                  media={<Avatar name={tag} color="colorful" />}
                >
                  {tag}
                </Tag>
              ))}
            </Stack>
          </>
        }
        onClick={() => {
          let art = { ...kbaFile };
          art.tags = randomTags;
          setActiveFile(art);
        }}
      >
        {kbaFile.name}
        <br />
      </CompoundButton>
    </Stack>
  );
}

export function SideBarMenuFiles({
  kbaFiles: kbaFilesOff,
  setActiveFile,
  activeFile,
}: {
  kbaFiles: KbaFile[];
  setActiveFile: Dispatch<SetStateAction<KbaFile>>;
  activeFile: KbaFile;
}) {
  const [kbaFiles, setKbaFiles] = useState<KbaFile[]>(kbaFilesOff);
  const pageSize = 20;
  const f20 = kbaFiles.slice(0, pageSize);
  // const totalPageCount = Math.ceil(kbaFiles.length / pageSize);
  const [totalPageCount, setTotalPageCount] = useState<number>(
    Math.ceil(kbaFiles.length / pageSize)
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [currentItems, setCurrentItems] = useState(f20);
  const [forDeletionFilter, setForDeletionFilter] = useState(false);
  const [forReviewFilter, setForReviewFilter] = useState(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [allFilter, setAllFilter] = useState<boolean>(true);

  useEffect(() => {
    const Files = kbaFilesOff.filter(
      (a: KbaFile) =>
        a.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        a?.shortDescription?.toLowerCase()?.includes(inputValue.toLowerCase())
    );

    let i = Files.filter(
      (a: KbaFile) =>
        a.forDeletion == forDeletionFilter && a.forReview == forReviewFilter
    );

    if (allFilter) i = Files;

    setKbaFiles(i);
    const startPoint = currentPage * pageSize - pageSize;
    const newOnes = i.slice(startPoint, startPoint + pageSize);
    setTotalPageCount(Math.ceil(i.length / pageSize));
    setCurrentItems(newOnes);
  }, [forDeletionFilter, forReviewFilter, allFilter]);

  useEffect(() => {
    const startPoint = currentPage * pageSize - pageSize;
    const newOnes = kbaFiles.slice(startPoint, startPoint + pageSize);
    setCurrentItems(newOnes);
  }, [currentPage]);

  const items = currentItems.map((i: KbaFile) => {
    return (
      <MenuItem
        kbaFile={i}
        key={i.id}
        setActiveFile={setActiveFile}
        activeFile={activeFile}
      />
    );
  });

  return (
    <Stack style={{ height: "100%" }}>
      <Stack.Item>
        <Stack
          horizontal
          horizontalAlign="space-between"
          style={{ marginBottom: "15px" }}
        >
          <Stack.Item>
            <Field style={{ width: "90%" }}>
              <Input
                placeholder="Filter"
                width={"90%"}
                style={{ background: customTokens.drawerInsideColor }}
                onChange={(e, d) => {
                  setInputValue(d.value);
                  const Files = kbaFilesOff.filter(
                    (a: KbaFile) =>
                      a.name.toLowerCase().includes(d.value.toLowerCase()) ||
                      a?.shortDescription
                        ?.toLowerCase()
                        ?.includes(d.value.toLowerCase())
                  );

                  let i = Files.filter(
                    (a: KbaFile) =>
                      a.forDeletion == forDeletionFilter &&
                      a.forReview == forReviewFilter
                  );

                  if (allFilter) i = Files;

                  setKbaFiles(i);
                  const startPoint = currentPage * pageSize - pageSize;
                  const newOnes = i.slice(startPoint, startPoint + pageSize);
                  setTotalPageCount(Math.ceil(i.length / pageSize));
                  setCurrentItems(newOnes);
                }}
              />
            </Field>
          </Stack.Item>
          <Stack.Item>
            <UploadModal />
          </Stack.Item>
          <Stack.Item>
            <Menu>
              <MenuTrigger disableButtonEnhancement>
                <Tooltip
                  content="With calendar icon and no contents"
                  relationship="label"
                >
                  <MenuButton
                    appearance="transparent"
                    icon={<Settings20Regular />}
                  />
                </Tooltip>
              </MenuTrigger>
              <MenuPopover>
                <MenuList>
                  <FMenuItem>
                    <Switch
                      checked={allFilter}
                      defaultChecked={allFilter}
                      label="All"
                      onChange={() => {
                        setAllFilter(!allFilter);
                        setForDeletionFilter(false);
                        setForReviewFilter(false);
                      }}
                    />
                  </FMenuItem>
                  <FMenuItem>
                    <Switch
                      checked={forDeletionFilter}
                      label="Enabled"
                      onChange={() => {
                        setForDeletionFilter(!forDeletionFilter);
                        setAllFilter(false);
                      }}
                    />
                  </FMenuItem>
                  <FMenuItem>
                    <Switch
                      checked={forReviewFilter}
                      label="For Review"
                      onChange={() => {
                        setForReviewFilter(!forReviewFilter);
                        setAllFilter(false);
                      }}
                    />
                  </FMenuItem>
                </MenuList>
              </MenuPopover>
            </Menu>
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item
        align="stretch"
        style={{ overflowY: "scroll", overflowX: "hidden" }}
      >
        {items}
      </Stack.Item>
      <Stack.Item>
        <Stack horizontal>
          <Button
            appearance="transparent"
            disabled={currentPage <= 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            Previous
          </Button>
          <Button appearance="transparent">{currentPage}</Button>
          <Button
            appearance="transparent"
            disabled={currentPage >= totalPageCount}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            Next
          </Button>
        </Stack>
      </Stack.Item>
    </Stack>
  );
}
